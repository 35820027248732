import { LanguageActions } from "../actions/types";

const initialState = {
  lang: "en",
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LanguageActions.LANGUAGE_LOAD: {
      return {
        ...state,
        lang: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default languageReducer;
