import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";

import { formValidations } from "../../utils/formValidations";
import Input from "../Input";
import PhoneInput from "../PhoneInput";
import * as Actions from "../../store/actions";

function EditProfileData() {
  const initialValues = {
    userName: "",
    contactPerson: "",
    companyName: "",
    phone: "971",
    address: "",
    email: "",
    website: "",
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { ...initialValues } });

  const dispatch = useDispatch();
  const { t } = useTranslation("ProjectTranslation");
  const user = useSelector(({ me }) => me.user);

  const onSubmit = (data) => {
    dispatch(Actions.UpdateUserData(data));
  };

  useEffect(() => {
    reset({ ...user });
  }, [reset, user]);

  return (
    <>
      {user?.id && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <Input
                label={t("edit_profile_data.form.userName")}
                placeholder={t("form.username")}
                {...register("userName", {
                  required: formValidations.required(),
                  minLength: formValidations.minLength(3),
                  maxLength: formValidations.maxLength(15),
                  pattern: formValidations.validateLetters(),
                })}
                errors={errors.userName?.message}
                disabled
              />
            </div>

            <div className="col-12 mt-3">
              <Input
                label={t("form.contactPerson")}
                placeholder={t("form.contactPersonPlaceholder")}
                defaultValue=""
                {...register("contactPerson", {
                  required: formValidations.required(),
                  minLength: formValidations.minLength(6),
                  pattern: formValidations.validateLettersWithSpaces(),
                })}
                errors={errors.contactPerson?.message}
              />
            </div>

            <div className="col-12 mt-3">
              <Input
                label={t("form.companyName")}
                placeholder={t("form.companyName")}
                defaultValue=""
                {...register("companyName", {
                  required: formValidations.required(),
                  minLength: formValidations.minLength(3),
                  maxLength: formValidations.maxLength(255),
                  pattern: formValidations.validateLettersAndNumbersAndSpaces(),
                })}
                errors={errors.companyName?.message}
              />
            </div>

            <div className="col-12 mt-3">
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: formValidations.required(),
                  validate: (value) =>
                    isValidPhoneNumber(`+${value}`) ||
                    "Phone number is not valid",
                }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    label={t("form.phoneLabel")}
                    errors={errors.phone?.message}
                  />
                )}
              />
            </div>

            <div className="col-12 mt-3">
              <Input
                label={t("form.address")}
                placeholder={t("form.addressPlaceholder")}
                defaultValue=""
                {...register("address", {
                  required: formValidations.required(),
                  minLength: formValidations.minLength(6),
                  maxLength: formValidations.maxLength(255),
                })}
                errors={errors.address?.message}
              />
            </div>

            <div className="col-12 mt-3">
              <Input
                type="email"
                label={t("edit_profile_data.form.email_address")}
                placeholder={t(
                  "edit_profile_data.form.email_address_placeholder"
                )}
                defaultValue=""
                {...register("email", {
                  required: formValidations.required(),
                  minLength: formValidations.minLength(3),
                  pattern: formValidations.validateEmail(),
                })}
                errors={errors.email?.message}
              />
            </div>

            <div className="col-12 mt-3">
              <Input
                label={t("form.website")}
                placeholder={t("form.websitePlaceholder")}
                defaultValue=""
                {...register("website", {
                  required: formValidations.required(),
                  minLength: formValidations.minLength(3),
                  pattern: formValidations.validateURL(),
                })}
                errors={errors.website?.message}
              />
            </div>

            <div className="col-lg-9 col-12 mt-4 offset-lg-3">
              <button
                type="submit"
                className="btn btn-primary custom-button full-width"
              >
                {t("form.update")}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default EditProfileData;
