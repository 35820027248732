import React from "react";
import { useHistory } from "react-router";

import FaqItem from "../../pages/FAQs/Item";

import "../../styles/Home/FAQs.scss";

function HomeFAQs({ game }) {
  const history = useHistory();

  const data = require("../../staticContent.json");
  const faqs = data.faqs;

  const openFAQPage = () => {
    history.push(`/faq`);
  };

  return (
    <div className="container">
      <h2 className="mb-4 pb-1 section-title">frequently asked questions</h2>

      <div className="faq-list">
        <ul className="faq-list-ul">
          {faqs.map((faq, fIndex) => (
            <FaqItem
              fIndex={fIndex}
              faq={faq}
              key={"faq_list_item_" + fIndex}
            />
          ))}
        </ul>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <button
          type="button"
          className="btn btn-primary custom-button outline mt-3 mt-md-0"
          onClick={openFAQPage}
        >
          View All
        </button>
      </div>
    </div>
  );
}

export default HomeFAQs;
