export const CommonActions = {
  SAVE_GAMES: "[COMMON] SAVE_GAMES",
  SAVE_SELECTED_GAME: "[COMMON] SAVE_SELECTED_GAME",
  SAVE_ON_GOING_TOURNAMENTS_NUMBER: "[COMMON] SAVE_ON_GOING_TOURNAMENTS_NUMBER",
  SAVE_ERROR_MESSAGE: "[COMMON] SAVE_ERROR_MESSAGE",
  SAVE_SUCCESS_MESSAGE: "[COMMON] SAVE_SUCCESS_MESSAGE",
  SAVE_BANNER_IMAGES: "[COMMON] SAVE_BANNER_IMAGES",
  SAVE_PAGE_LOCATION: "[COMMON] SAVE_PAGE_LOCATION",
};

export const CountryActions = {
  COUNTRY_LOAD: "[COUNTRY] LOAD",
  COUNTRY_READY: "[COUNTRY] REDAY",
  COUNTRY_SAVE: "[COUNTRY] SAVE",
  COUNTRY_SELECTED_SAVE: "[COUNTRY] SELECTED_SAVE",
  COUNTRY_CITY_SAVE: "[COUNTRY] CITY_SAVE",
};

export const FAQActions = {
  FAQ_LOAD: "[FAQ] LOAD",
  FAQ_READY: "[FAQ] REDAY",
  FAQ_SAVE: "[FAQ] SAVE",
};

export const LanguageActions = {
  LANGUAGE_LOAD: "[LANGUAGE] LOAD",
};

export const MapActions = {
  MAP_LOAD: "[MAP] LOAD",
  MAP_READY: "[MAP] REDAY",
  MAP_MARKER_DATA_SAVE: "[MAP] MARKER_DATA_SAVE",
};

export const MeActions = {
  USER_DATA_LOAD: "[ME] USER_DATA_LOAD",
  USER_DATA_READY: "[ME] USER_DATA_READY",
  USER_DATA_SAVE: "[ME] USER_DATA_SAVE",
  OWNER_TOURNAMENTS_LOAD: "[OWNER_TOURNAMENTS] LOAD",
  OWNER_TOURNAMENTS_READY: "[OWNER_TOURNAMENTS] REDAY",
  OWNER_TOURNAMENTS_FEATURED_SAVE: "[OWNER_TOURNAMENTS] FEATURED_SAVE",
  OWNER_TOURNAMENTS_CUSTOM_SAVE: "[OWNER_TOURNAMENTS] CUSTOM_SAVE",
};

export const RegionActions = {
  REGION_LOAD: "[REGION] LOAD",
  REGION_READY: "[REGION] REDAY",
  REGION_SAVE: "[REGION] SAVE",
};

export const StaticActions = {
  STATIC_LOAD: "[STATIC] LOAD",
  STATIC_READY: "[STATIC] REDAY",
  STATIC_SAVE: "[STATIC] SAVE",
};

export const TournamentsActions = {
  TOURNAMENTS_LOAD: "[TOURNAMENTS] LOAD",
  TOURNAMENTS_READY: "[TOURNAMENTS] REDAY",
  TOURNAMENTS_SAVE: "[TOURNAMENTS] SAVE",
  TOURNAMENTS_FEATURED_SAVE: "[TOURNAMENTS] FEATURED_SAVE",
  TOURNAMENTS_SELECTED_SAVE: "[TOURNAMENTS] SELECTED_SAVE",
  SAVE_CUSTOM_TOURNAMENT_ENUMS: "[TOURNAMENTS] SAVE_CUSTOM_TOURNAMENT_ENUMS",
  SAVE_TOURNAMENT_FORMAT_CONT: "[TOURNAMENTS] SAVE_TOURNAMENT_FORMAT_CONT",
  SAVE_TOURNAMENT_BROADCAST_DAYS:
    "[TOURNAMENTS] SAVE_TOURNAMENT_BROADCAST_DAYS",
  SAVE_TOURNAMENT_SUPPORT_OPTIONS:
    "[TOURNAMENTS] SAVE_TOURNAMENT_SUPPORT_OPTIONS",
  SAVE_CUSTOM_TOURNAMENT_DETAILS:
    "[TOURNAMENTS] SAVE_CUSTOM_TOURNAMENT_DETAILS",
  SAVE_ONGOING_TOURNAMENTS: "[TOURNAMENTS] SAVE_ONGOING_TOURNAMENTS",
  SAVE_ONGOING_TOURNAMENTS_CALENDAR:
    "[TOURNAMENTS] SAVE_ONGOING_TOURNAMENTS_CALENDAR",
  ONGOING_TOURNAMENTS_LOAD: "[TOURNAMENTS] ONGOING_TOURNAMENTS_LOAD",
  ONGOING_TOURNAMENTS_READY: "[TOURNAMENTS] ONGOING_TOURNAMENTS_READY",
};
