import React from "react";

import "../styles/BroadcastTerms.scss";
import { Helmet } from "react-helmet";

function BroadcastTerms() {
  return (
    <div className="broadcast-terms-page">
      <Helmet>
        <title>Broadcast Terms</title>
      </Helmet>

      <div className="bg">
        <div className="container">
          <h1>Broadcast Terms</h1>
        </div>
      </div>

      <div className="container content-container">
        <div className="mt-5 pt-5">
          <h2 className="mt-0 mb-2">Company Guidelines and Restrictions</h2>

          <p className="text mb-4">
            The following guidelines and restrictions are meant to ensure that
            every Broadcast meets a minimum quality bar, while preserving Riot’s
            interests and legal obligations.
          </p>

          <ol type="1">
            <li>
              <p className="text under-line">
                Technical Broadcast Quality Requirements
              </p>

              <ol type="a">
                <li className="text">
                  Company shall adhere to and meet or exceed the following
                  minimum technical quality standards.
                </li>
                <li className="text">
                  Company shall strive to improve the quality of the Broadcast.
                  <ol type="i">
                    <li className="text">
                      Video
                      <ol type="1">
                        <li>Codec: H.264 Main Profile</li>
                        <li>Bitrate: 1,500 kbps</li>
                        <li>Frame Rate: 29.97 or 30 FPS</li>
                        <li>Resolution: 1280x720 (720p)</li>
                      </ol>
                    </li>

                    <li className="text">
                      Audio
                      <ol type="1">
                        <li>Bitrate: 128 kbps</li>
                        <li>Codec: AAC-LC</li>
                        <li>Format: Stereo 44.1kHz</li>
                      </ol>
                    </li>

                    <li className="text">
                      Other
                      <ol type="1">
                        <li>Stable network connection</li>
                        <li>
                          Must have 360p, 480p and 720p transcodes available for
                          viewers
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className="text mt-4">
              <p className="text under-line">Advertising</p>
              <ol type="a">
                <li className="text">
                  Company shall adhere to the following guidelines and
                  restrictions regarding advertising. Playable Periods:
                  <ol type="i">
                    <li>
                      Pre-roll ads up to 45 seconds in length may be enabled.
                    </li>
                    <li>
                      Mid-roll ads may only be run during Tournament game play
                      break periods, designated by the "Coming Up" title screen
                      and the accompanying countdown timer. Advertisements may
                      be played as frequently as desired within these break
                      periods. An advertisement may not be played if the
                      completion of the advertisement will occur after the
                      completion of the break period; the Advertisement must be
                      complete before the Tournament game play resumes.
                    </li>
                    <li>
                      At the request and direction of Riot, Company shall
                      integrate Riot provided sponsorship announcements and
                      advertisements into the broadcast which shall be limited
                      to two 30 second spots per broadcast during the ad breaks.
                      For the avoidance of doubt, this is without prejudice to
                      the ads incorporated into the Tournament Feed Content by
                      Riot, and Company shall not modify or conceal such
                      integrated ads.
                    </li>
                    <li>
                      No advertising of any kind is permissible outside of these
                      break periods.
                    </li>
                    <li>
                      Riot may, upon reasonable notice, add additional
                      parameters for advertising at its sole discretion.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className="text mt-4">
              <p className="under-line">Paywall and Viewer Segregation</p>
              <ol type="a">
                <li>
                  All portions of the broadcast both live and archived, must be
                  readily available to non-paying viewers.
                </li>
                <li>
                  Company may not limit HD content to any viewer pool, such as
                  subscribers.
                </li>
                <li>
                  Company may not segregate viewers for any reason, including
                  use of ad-blocking software, choice of browser, etc.
                </li>
              </ol>
            </li>

            <li className="text mt-4">
              <p className="under-line">
                Modification of Tournament Feed Content
              </p>
              <ol type="a">
                <li>
                  Company shall not conceal or alter Champion Selection and the
                  ongoing Tournament match, with one exception:{" "}
                  <span className="font-italic">
                    “The "caster zone" during Champion Selection may be removed
                    and/or replaced with a live feed of the Company’s casting
                    team.”
                  </span>
                </li>
                <li>
                  Company may use proprietary video content as a replacement for
                  Riot content during the broadcast, so long as the proprietary
                  content does not overlap with Champion Selection or an ongoing
                  Tournament match.
                </li>
                <li>
                  Champion Selection and the ongoing match must be displayed in
                  their entirety, from start to completion, with the exception
                  of mid-match pauses, which will be treated as replaceable
                  video content.
                </li>
              </ol>
            </li>

            <li className="text mt-4">
              <p className="under-line">Overlays</p>
              <ol type="a">
                <li>
                  Unless instructed otherwise, Company may not cover or alter
                  the in-game overlay or elements in any way.
                </li>
                <li>
                  Company logos may only be displayed during non-game content.
                  Company logos may not be visible and may not cover up Champion
                  Selection or an ongoing Tournament match.
                </li>
              </ol>
            </li>

            <li className="text mt-4">
              <p className="under-line">Communication</p>
              <ol type="a">
                <li>
                  Company must identify a representative to receive real-time
                  updates via Discord during the live Tournament feed.
                </li>
              </ol>
            </li>

            <li className="text mt-4">
              <p className="under-line">Other</p>
              <ol type="a">
                <li>
                  All portions of the Broadcast must abide by the Riot Games
                  Video Creation & Use Policy located at{" "}
                  <a
                    className="under-line"
                    href="http://www.riotgames.com/legal-jibber-jabber"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.riotgames.com/legal-jibber-jabber
                  </a>
                </li>
                <li>
                  Company shall use its reasonable best efforts and shall work
                  with Riot in good faith to display a high-quality Broadcast
                  and put its best efforts to continuously improve the quality
                  of the Broadcast.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>

      <div className="container content-container">
        <h2 className="mt-5 mb-3">Restricted Categories List</h2>

        <ol type="1">
          <li className="text">
            Broadcaster shall not place, display or post advertisements which
            (a) contain any material, which would reasonably be seen as
            unlawful, libelous, obscene, indecent, threatening, intimidating,
            harassing, or otherwise objectionable, or harmful to the goodwill of
            [LoL] or Riot, and/or (b) feature any of those companies listed
            below and/or products listed below:
            <ol type="a" className="mt-2">
              <li>Any video game or video game developer or publisher</li>
              <li>Prescription or non-“over-the-counter” drugs</li>
              <li>Firearms, handguns, ammunition, or peripherals</li>
              <li>Pornography or pornographic products</li>
              <li>Tobacco, tobacco products, or paraphernalia</li>
              <li>
                Alcohol products, or other intoxicants the sale or use of which
                is regulated by law
              </li>
              <li>
                Sellers or marketplaces of virtual items known to be counterfeit
                or illegal sellers thereof, or who are otherwise in breach of
                the Riot Games Terms of Service
              </li>
              <li>
                Businesses engaged in gambling, wagering, bookmaking, or sports
                or esports betting, including fantasy sports or esports
              </li>
              <li>Political campaigns</li>
              <li>
                Charities that endorse particular religious or political
                positions or are not reputable (by way of example, Red Cross,
                Stand-Up to Cancer and other mainstream charities shall be
                considered reputable)
              </li>
              <li>
                Cryptocurrencies or any unregulated financial instruments or
                markets
              </li>
              <li>
                Autarchies and government agencies such as Ministries,
                Secretariats, Governments (municipal, state or federal).
              </li>
            </ol>
          </li>

          <li>
            Riot will provide the Broadcaster with reasonable notice of any
            updates to the Restricted Categories via email. Any such updates are
            made at Riot’s sole discretion.{" "}
          </li>
        </ol>
      </div>

      <div className="container content-container">
        <div className="mt-5 mb-5 pb-5">
          <h2 className="mb-3">Riot On-Air talent guidelines</h2>

          <p className="text mb-4">
            Riot has certain guidelines and restrictions for the League of
            Legends on air talent role relating to external engagements in order
            to avoid potential conflict of interest for respective contractors
            shoutcasting and/or otherwise appearing on air for Riot (“Riot
            Shoutcasters”) the Broadcaster agrees, and agrees to ensure all on
            air talent agree, to be bound by the following:
          </p>

          <ol type="1" className="text">
            <li>
              Riot Shoutcasters are free to monetize through personal streaming
              and otherwise through personal sponsorships and endorsements
              provided they:
              <ol type="a" className="mt-2">
                <li>
                  are not displayed or referenced on, during or in association
                  with Riot produced broadcasts, content or other products;
                </li>
                <li>
                  do not conflict with sponsors of League of Legends, League of
                  Legends esports or any other Riot products; and
                </li>
                <li>do not conflict with the Restricted Categories list.</li>
              </ol>
            </li>

            <li>
              Riot Shoutcasters are permitted to engage in personal online
              streaming activities provided such streaming does not conflict
              with the legitimate interests of Riot and does not negatively
              affect the role and performance of a Riot Shoutcaster.
            </li>
            <li>
              Riot will in no event be party to any agreements between the Riot
              Shoutcasters and streaming platforms or shoutcaster sponsors. Riot
              Shoutcasters are fully in charge of their relationship with such
              third parties, including fulfilling their obligations, as well as
              enjoying the benefits thereof. Any compensation Riot Shoutcasters
              receive via third parties constitute their personal income, and
              will be managed by Riot Shoutcasters personally, including
              compliance with any tax liability.
            </li>
            <li>
              Riot Shoutcasters may not use Riot facilities or hardware for
              personal streaming.
            </li>

            <li>
              Riot Shoutcasters should avoid any content which:
              <ol type="a" className="mt-2">
                <li>
                  contains any material which is unlawful, purposefully
                  libelous, in violation of Riot’s anti-discrimination,
                  anti-harassment, retaliation, or other policies, threatening
                  and/or intimidating; and/or
                </li>
                <li>otherwise create a conflict of interest for Riot.</li>
              </ol>
            </li>

            <li>
              Riot Shoutcaster’s stream should not contain any content that
              would otherwise appear on an official Riot channel (running Riot
              trailers/IP, hosting a show, etc.). If in doubt about a piece of
              content or a potential conflict of interest with Riot, the Riot
              Shoutcaster or Broadcaster should consult Riot.
            </li>
            <li>
              Riot Shoutcaster may not use “Riot”, “Riot Games” or other Riot
              identifiers in its social media account handles and pages.
            </li>
            <li>
              During the period of engagement with Riot, Riot Shoutcaster shall
              maintain professional appearance, comply with wardrobe guidelines
              and refrain from altering his/her appearance in a way that would
              make them unfit for the Riot broadcast..{" "}
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default BroadcastTerms;
