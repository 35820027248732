import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LANG } from "./utils/app.constants";
import en from "./i18nLang/en";
import ar from "./i18nLang/ar";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en,
  ar,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: DEFAULT_LANG,
    // fallbackLng: DEFAULT_LANG,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.addResourceBundle("en", "ProjectTranslation", en);
i18n.addResourceBundle("ar", "ProjectTranslation", ar);

export default i18n;
