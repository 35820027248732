import { getAccessToken } from "../utils/getTokenFromCookie";
import { refreshToken } from "../utils/refreshToken";
import axios from "axios";
import store from "../store";
import { setErrorMessage } from "../store/actions";

const defaultAxiosParams = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "",
  },
  withCredentials: true,
};

export const axiosService = axios.create(defaultAxiosParams);

axiosService.interceptors.request.use(
  async function (config) {
    const token = getAccessToken();

    if (token) {
      config.headers = {
        ...config.headers,
      };
    }

    return config;
  },
  function (error) {
    //error logic

    return Promise.reject(error);
  }
);

let isGettingToken = false;
let failedPostAndPutRequestQueue = [];
let failedGetRequestQueue = [];

axiosService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest.sent) {
      originalRequest.sent = true;

      if (originalRequest.url === "/api/auth/signin") {
        error.message = "Invalid email or password";
        store.dispatch(setErrorMessage(error));
      } else if (originalRequest.url === "/api/auth/changePassword") {
        error.message = "Your request is expired";
        store.dispatch(setErrorMessage(error));
      } else if (!isRegister(originalRequest.url, originalRequest.method)) {
        addFailedRequest(originalRequest);

        if (!isGettingToken) {
          isGettingToken = true;

          const accessToken = await refreshToken();

          isGettingToken = false;

          if (accessToken) {
            onAccessTokenFetched(accessToken);
            return;
          }
        }
      }
    } else {
      store.dispatch(setErrorMessage(error));
    }

    return Promise.reject(error);
  }
);

const isRegister = (url, method) => {
  return url === "/api/user/create" && method === "post";
};

function addFailedRequest(originalRequest) {
  if (originalRequest.method === "post") {
    failedPostAndPutRequestQueue.push(originalRequest);
  } else if (originalRequest.method === "get") {
    failedGetRequestQueue.push(originalRequest);
  }
}

function onAccessTokenFetched(accessToken) {
  failedPostAndPutRequestQueue.forEach((request) => {
    if (request.headers.Authorization.includes("Bearer")) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      request.headers.Authorization = accessToken;
    }

    axios(request);
  });

  failedPostAndPutRequestQueue = [];

  if (failedGetRequestQueue.length > 0) {
    failedGetRequestQueue = [];
    window.location.reload();
  }
}
