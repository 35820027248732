import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation } from 'react-router-dom';

function Error404() {

    const {t} = useTranslation('ProjectTranslation');
    const lang = useLocation().pathname.slice(1, 3);

    return (
        <section id="hero" className="d-flex align-items-center inner-page-banner onepage">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                        <h1>{t('404 error')}</h1>
                        <h2>{t('Whoops! Something went wrong!')} </h2>
                        <p>{t('We couldn’t find that page.')}</p>
                        <Link to={`/${lang}`} className="getstarted btn-three col-sm-3">{t('go back to homepage')}</Link>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200"></div>
                </div>
            </div>
        </section>
    );
}

export default Error404;