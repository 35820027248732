import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { savePageLocation } from "../store/actions";

const Default = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(savePageLocation(location.pathname));
  }, [dispatch, location]);

  return children;
};

export default Default;
