import { getCookie } from "./getCookie";

export const getAccessToken = () => {
  const token = getTokenFromCookie("accessToken");

  return token ? `Bearer ${token}` : "";
};

export const getSecurityToken = () => {
  const token = getTokenFromCookie("accessToken");

  return token;
};

export const getRefreshToken = () => {
  return getTokenFromCookie("refreshToken");
};

export const getTokenFromCookie = (tokenName) => {
  const token = getCookie(tokenName);

  return token || "";
};
