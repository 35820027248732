import React from "react";
import { useHistory } from "react-router";

import "../../styles/Home/OurSupport.scss";

function OurSupport({ game }) {
  const history = useHistory();

  const supportDiscipline = [
    {
      title: "Communications",
      icon: "support-discipline-communications.png",
    },
    {
      title: "Brand",
      icon: "support-discipline-brand.png",
    },
    {
      title: "Content",
      icon: "support-discipline-content.png",
    },
    {
      title: "Community",
      icon: "support-discipline-community.png",
    },
    {
      title: "Miscellaneous",
      icon: "support-discipline-miscellaneous.png",
    },
    {
      title: "CXP",
      icon: "support-discipline-cxp.png",
    },
  ];

  const openSupporPagePage = () => {
    history.push(`/our-support`);
  };

  return (
    <div className="container">
      <h2 className="mb-0 section-title">Publishing Support</h2>

      <div className="row">
        <div className="col-12 col-lg-6 subtitle-container">
          <p className="subheading">
            From communications, content, brand and competitive experience
            services, you will gain access to a range of publishing support
            services that are designed to enhance your tournament and elevate
            your overall experience. Upon applying, you can select up to three
            services which are subject to approval by Riot Games depending on
            availability, partnership status and workload.
          </p>
        </div>

        <div className="row">
          {supportDiscipline?.map((support, index) => (
            <div
              className="col-12 col-lg-4 col-md-6 col-12 mt-2 mt-lg-0 mb-4"
              key={support.title}
            >
              <div className="support-card d-flex flex-column align-items-center">
                <div className="support-index">
                  <span className="current-index">{index + 1}</span> / 6
                </div>
                <img
                  className="support-icon"
                  src={`assets/img/${support.icon}`}
                  alt={`${support.title} icon`}
                />
                <p className="support-title my-auto">{support.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <button
          type="button"
          className="btn btn-primary custom-button mt-2 mt-md-0"
          onClick={openSupporPagePage}
        >
          View our support details
        </button>
      </div>
    </div>
  );
}

export default OurSupport;
