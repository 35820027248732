import i18n from "../../i18n";
import { LanguageActions } from "./types";

export function changeLanguage(lang) {
  return (dispatch, getState) => {
    i18n.dir(lang);
    i18n.changeLanguage(lang).then(() => {
      // document.getElementsByTagName('html')[0].setAttribute('lang', lang);
      document.getElementsByTagName("body")[0].setAttribute("id", lang);
      dispatch(languageChanged(lang));
    });
  };
}

export const languageChanged = (lang) => ({
  type: LanguageActions.LANGUAGE_LOAD,
  payload: lang,
});
