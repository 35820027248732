import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import FirstRow from "../../components/OnGoingTournaments/List/FirstRow";
import TournamentCard from "../../components/OnGoingTournaments/List/TournamentCard";

function OnGoingTournaments() {
  const [tournamentsExceptFirst3, setTournamentsExceptFirst3] = useState([]);

  const tournaments = useSelector(
    ({ tournaments }) => tournaments.onGoingTournaments
  );

  useEffect(() => {
    setTournamentsExceptFirst3(tournaments.slice(3));
  }, [tournaments]);

  return (
    <div className="tournaments-list">
      <FirstRow tournaments={tournaments} />

      <div className="row">
        {tournamentsExceptFirst3?.map((tournament, index) => (
          <div className="col-lg-4 col-12" key={`${tournament.id}_${index}`}>
            <TournamentCard tournament={tournament} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default OnGoingTournaments;
