import { FAQActions } from "../actions/types";

const initialState = {
  loading: false,
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAQActions.FAQ_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case FAQActions.FAQ_READY: {
      return {
        ...state,
        loading: false,
      };
    }
    case FAQActions.FAQ_SAVE: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default faqReducer;
