import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DateObject } from "react-multi-date-picker";
import { Link } from "react-router-dom";

import { COUNTRIES_EXCEPT_MENA } from "../../../utils/app.constants";
import { isOther } from "../../../utils/helpers/helpers";
import CustomTooltip from "../../Tooltip";
import i18n from "../../../i18n";

function OnGoingTournamentsCalendarTournament({ monthDays, tournament, date }) {
  const language = i18n.language;

  const [tournamentCountriesNames, setTournamentCountriesNames] = useState([]);
  const [tournamentBroadcastDays, setTournamentBroadcastDays] = useState([]);

  const countries = useSelector((state) => state.countries.list);
  const format_cont = useSelector(({ tournaments }) => tournaments.format_cont);

  const isAR = useCallback(() => {
    return language === "ar";
  }, [language]);

  const viewFormateType = () => {
    const format_type = tournament.format_type;

    return isOther(format_type)
      ? tournament.format_type_other
      : format_cont[format_type - 1]?.name;
  };

  const viewExtraCountriesNames = () => {
    const namesExceptFirst = tournamentCountriesNames.slice(1);
    let names = "";

    namesExceptFirst.forEach((country) => {
      names += `- ${country}\n`;
    });

    return names;
  };

  const getCountryNameUsingCode = useCallback(
    (code) => {
      const country = countries.filter((country) => country.code === code)[0];
      return isAR() ? country.nameAr : country.nameEn;
    },
    [isAR, countries]
  );

  const getOtherCountryNameUsingCode = useCallback(
    (code) => {
      const country = COUNTRIES_EXCEPT_MENA.filter(
        (country) => country.code === code
      )[0];
      return isAR() ? country.nameAr : country.name;
    },
    [isAR]
  );

  const getTournamentCountriesNames = useCallback(() => {
    const { focus_countries, focus_countries_other } = tournament;

    let countriesNames = [];

    focus_countries.forEach((country, index) => {
      countriesNames.push(getCountryNameUsingCode(country));
    });

    focus_countries_other.forEach((country, index) => {
      countriesNames.push(getOtherCountryNameUsingCode(country));
    });

    setTournamentCountriesNames(countriesNames);
  }, [tournament, getCountryNameUsingCode, getOtherCountryNameUsingCode]);

  useEffect(() => {
    getTournamentCountriesNames();
  }, [getTournamentCountriesNames]);

  const isTournamentDay = (day) => {
    const checkingDate = new DateObject(date, "DD/MM/YYYY");
    checkingDate.set({ day, hour: 0, minute: 0, second: 0, millisecond: 0 });

    const startDate = new DateObject(tournament.start_date, "DD/MM/YYYY");
    const endDate = new DateObject(tournament.end_date, "DD/MM/YYYY");

    const value =
      startDate.valueOf() <= checkingDate.valueOf() &&
      checkingDate.valueOf() <= endDate.valueOf();

    return value;
  };

  const ensureHttps = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return "https://" + url;
    }
    return url;
  };

  const getTournamentBroadcastDays = useCallback(() => {
    const tempBroadcastDays = [];
    tournament.broadcast_days.forEach((day) => {
      tempBroadcastDays.push(
        new DateObject(day, "DD/MM/YYYY").format("DD/MM/YYYY")
      );
    });

    setTournamentBroadcastDays(tempBroadcastDays);
  }, [tournament]);

  useEffect(() => {
    getTournamentBroadcastDays();
  }, [getTournamentBroadcastDays]);

  const isTournamentBroadcastDay = (day) => {
    const tempDate = new DateObject(date);
    tempDate.setDay(day);

    return tournamentBroadcastDays.includes(tempDate.format("DD/MM/YYYY"));
  };

  return (
    <div className="tournament row mx-0">
      <div className="col-6 col-lg-3 row-border-bottom tournament-details left-sticky-part">
        <div className="d-flex">
          <img
            src={tournament.tournament_keyart[0]}
            alt={tournament.tournament_english_name}
            className={`tournament-image ${isAR() ? "ms-2" : "me-2"}`}
          />
          <div>
            <Link
              to={{
                pathname: ensureHttps(tournament.organizer_website_url),
              }}
              target="_blank"
              className="tournament-name-container"
            >
              <p className="tournament-name mb-0">
                {isAR()
                  ? tournament.tournament_arabic_name
                  : tournament.tournament_english_name}
              </p>
            </Link>

            <div className="d-flex flex-wrap">
              <p className="country-name mb-0">{tournamentCountriesNames[0]}</p>
              {tournamentCountriesNames.length > 1 && (
                <CustomTooltip
                  content={viewExtraCountriesNames()}
                  tooltipClasses="countries-tooltip"
                >
                  <div
                    className={`countries-other ${isAR() ? "me-2" : "ms-2"}`}
                  >
                    +{tournamentCountriesNames.length - 1}
                  </div>
                </CustomTooltip>
              )}
              <div className={`format_type  ${isAR() ? "me-2" : "ms-2"}`}>
                {viewFormateType()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 col-lg-9">
        <div className="days-names row flex-nowrap">
          {monthDays.map((day) => (
            <div
              className={`day tournament-day cell-border-left row-border-bottom ${
                isTournamentDay(day.day) && "tournament-day-container"
              } ${
                isTournamentBroadcastDay(day.day) && "broadcast-day-container"
              } ${isAR() && "ar"}`}
              key={`${tournament.id}_${day.day}`}
            >
              <div></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OnGoingTournamentsCalendarTournament;
