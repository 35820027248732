import {
  BITS_IN_MEGA,
  MAX_IMAGE_FILE_SIZE,
  MAX_PDF_FILE_SIZE,
  MAX_TOTAL_PDF_FILES_SIZE,
  YES_NO,
} from "../app.constants";

export const isOther = (value) => value?.toLowerCase() === "other";

export const isArrayContainsOther = (array) => array.some(isOther);

export const getBooleanValue = (value) =>
  value === YES_NO.Yes.toString() ? true : false;

export const isPDFSizeValid = (file) =>
  file.size <= MAX_PDF_FILE_SIZE * BITS_IN_MEGA;

export const isTotalPDFsSizeValid = (files) => {
  const totalSize = files.reduce((acc, file) => acc + file.size, 0);
  return totalSize <= MAX_TOTAL_PDF_FILES_SIZE * BITS_IN_MEGA;
};

export const isImageSizeValid = (file) =>
  file.size <= MAX_IMAGE_FILE_SIZE * BITS_IN_MEGA;
