import React from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import "../../styles/Profile/TournamentContractForm.scss";
import { isOther } from "../../utils/helpers/helpers";

function TournamentContractForm() {
  const tournament = useSelector(({ tournaments }) => tournaments.selected);

  const games = useSelector(({ common }) => common.games);
  const custom_tournaments_enums = useSelector(
    ({ tournaments }) => tournaments.custom_tournaments_enums
  );
  const format_cont = useSelector(({ tournaments }) => tournaments.format_cont);

  const getGameName = () => {
    return games.find((game) => game.id === tournament?.game_name)?.name;
  };

  const getBroadcastPlatforms = () => {
    const { broadcast_platforms, broadcast_platforms_other } = tournament;
    const broadcastLanguages = custom_tournaments_enums?.broadcast_platforms;

    const platforms = broadcastLanguages?.filter((platform) =>
      broadcast_platforms?.includes(platform?.id?.toString())
    );

    const languagesNames = platforms?.map((platform) => {
      const platformName = platform.name;

      if (isOther(platformName)) {
        return broadcast_platforms_other;
      }
      return platformName;
    });

    return languagesNames.join(", ");
  };

  const getTournamentFormatType = () => {
    const { format_type, format_type_other } = tournament;

    const formatName = format_cont?.filter(
      (tournamentFormat) => tournamentFormat.id === +format_type
    )[0]?.name;

    return isOther(formatName) ? format_type_other : formatName;
  };

  const getTournamentFormats = () => {
    const { format } = tournament;
    const tournamentsFormats = custom_tournaments_enums?.format;

    const formatsNames = [];

    format?.forEach((format) => {
      const formatName = tournamentsFormats?.filter(
        (tournamentFormat) => tournamentFormat.id === +format
      )[0]?.name;
      formatsNames.push(formatName);
    });

    return formatsNames.join(", ");
  };

  return (
    <React.Fragment>
      {tournament?.id && (
        <div className="tournament-contract-form">
          <img
            src="assets/img/contract-riot-logo.png"
            alt="background"
            className="background-image"
          />

          <div style={{ position: "relative" }}>
            <p style={{ paddingTop: "80px" }} className="text-center bold">
              RIOT GAMES COMMUNITY COMPETITION LICENCE
            </p>

            <p style={{ paddingTop: "20px" }} className="text-center bold">
              INTRODUCTION
            </p>
            <p style={{ paddingTop: "15px" }}>
              A) Riot Games Limited (“<span className="bold">Riot</span>”) is a
              video games developer, publisher and owner and operator of the
              Game. The Organiser would like to host a community competition
              featuring the Game detailed in the table below (the “
              <span className="bold">Tournament</span>”).
            </p>
            <p style={{ paddingTop: "15px" }}>
              B) Riot has agreed to licence the Game to the Organiser for use at
              the Tournament (including the right to stream footage of the
              Tournament), subject to the Organiser’s ongoing compliance with
              the terms of the Agreement.
            </p>

            <p
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
              className="bold"
            >
              <span className="section-number">1.</span>ORGANISER AND TOURNAMENT
              DETAILS
            </p>

            <table className="bordered">
              <tbody>
                <tr>
                  <td className="bold" style={{ width: "27%" }} valign="top">
                    Organiser:
                  </td>
                  <td>
                    {tournament.company_legal_name}, incorporated and registered
                    in: {tournament.company_address}, with company number:{" "}
                    {tournament.company_registration_number}
                  </td>
                </tr>

                <tr>
                  <td className="bold" style={{ width: "27%" }} valign="top">
                    Tournament Details:
                  </td>
                  <td>
                    Tournament Name: {tournament.tournament_english_name}
                    <br />
                    <br />
                    Tournament Address (physical events only):{" "}
                    {tournament.tournament_address}
                    <br />
                    <br />
                    Tournament Dates:{" "}
                    {format(
                      new Date(tournament.start_date),
                      "dd/MM/yyyy"
                    )} -- {format(new Date(tournament.end_date), "dd/MM/yyyy")}
                    <br />
                    <br />
                    Broadcast Channel(s) please specify below:
                    <br />
                    {getBroadcastPlatforms()}
                  </td>
                </tr>

                <tr>
                  <td className="bold" style={{ width: "27%" }} valign="top">
                    Game:
                  </td>
                  <td>{getGameName()}</td>
                </tr>

                <tr>
                  <td className="bold" style={{ width: "27%" }} valign="top">
                    Tournament Type:
                  </td>
                  <td>
                    {getTournamentFormatType()} ({getTournamentFormats()})
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <p style={{ paddingTop: "25px" }} className="bold">
            <span className="section-number">2.</span>CORE TERMS
          </p>

          <table className="details-table">
            <tbody>
              <tr>
                <td style={{ width: "5%" }} valign="top">
                  2.1
                </td>
                <td>
                  <span className="under-line">Agreement and Duration.</span>{" "}
                  This “<span className="bold">Agreement</span>” is comprised of
                  this Community Competition Licence, the annexed Terms &
                  Conditions and the applicable Game Guidelines (defined below).
                  This Agreement is only effective as of the date that Riot
                  confirms in writing (email being sufficient) that it has
                  received a validly signed copy of this Agreement from the
                  Organiser and that Riot approves the Tournament and will
                  remain in effect until the end date of the Tournament detailed
                  above at which point it will automatically terminate, unless
                  terminated earlier in accordance with its terms (the “
                  <span className="bold">Term</span>”). If the Organiser
                  receives Riot’s written approval to host the Tournament, the
                  Tournament hosted by the Organiser must be the same as the
                  Tournament detailed above.
                </td>
              </tr>

              <tr>
                <td style={{ width: "5%" }} valign="top">
                  2.2
                </td>
                <td>
                  <span className="under-line">Game Guidelines.</span> In
                  addition to the terms of this Agreement, the Organiser and
                  Tournament must at all times comply with Riot’s online
                  guidelines applicable to the Game, available at:{" "}
                  <span className="link">
                    <a href="https://riot.eurcommunitycompetition.com/#games">
                      https://riot.eurcommunitycompetition.com/#games
                    </a>
                  </span>{" "}
                  and as updated by Riot from time to time (“Game Guidelines”).
                  The Game Guidelines contain important information regarding
                  topics like prize pool caps, Tournament duration and naming
                  restrictions, broadcasting rules and more. Failure to comply
                  with the Game Guidelines may result in suspension and/or
                  termination of this Agreement, so please read them carefully.
                  The applicable Game Guidelines form part of this Agreement.
                </td>
              </tr>

              <tr>
                <td style={{ width: "5%" }} valign="top">
                  2.3
                </td>
                <td>
                  <span className="under-line">Priority.</span> In the event of
                  any inconsistency between the terms of this Community
                  Competition Licence, the annexed Terms and Conditions and
                  applicable Game Guidelines, the Game Guidelines will take
                  priority, followed by the Terms & Conditions, followed by this
                  Community Competition Licence.
                </td>
              </tr>
            </tbody>
          </table>

          <p style={{ paddingTop: "15px" }}>
            By signing below you represent and warrant to Riot on an ongoing
            basis that: (a) you have all requisite power, authority, consents
            and approvals to enter into this Agreement, including where you are
            entering into this Agreement on behalf of a third party; (b) you are
            at least 18 years of age or the age of majority as defined in your
            country of residence; and (c) you will comply with the terms of this
            Agreement at all times.
          </p>
        </div>
      </div>

      {tournament?.id && (
        <div className="tournament-contract-form">
          <div style={{ position: "relative" }}>
            <p
              style={{ paddingTop: "25px", paddingBottom: "20px" }}
              className="bold"
            >
              SIGNED AND AGREED
            </p>

            <table style={{ width: "100%" }} className="bordered">
              <tbody>
                <tr>
                  <td>
                    Signature:{" "}
                    <span
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      {tournament.userName}
                    </span>
                    <br />
                    <br />
                    Name: {tournament.userName}
                    <br />
                    <br />
                    Date:{" "}
                    {format(
                      new Date(tournament.step3_submission_date),
                      "dd/MM/yyyy"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <p style={{ paddingTop: "25px" }} className="text-center bold">
            Community Competition Terms &amp; Conditions
          </p>

          <p style={{ paddingTop: "15px" }} className="text-center font-italic">
            These Terms & Conditions (“
            <span className="bold">Terms & Conditions</span>”) apply to the
            Tournament being organised by the Organiser and form part of the
            Agreement between the Organiser and Riot.
          </p>

          <p style={{ paddingTop: "15px" }} className="bold">
            <span className="section-number">1.</span>LICENCEAND INTELLECTUAL
            PROPERTY
          </p>
          <p style={{ paddingTop: "15px" }}>
            1.1.<span className="under-line">Licence.</span>
          </p>

          <table className="details-table">
            <tbody>
              <tr>
                <td style={{ width: "10%" }} valign="top">
                  a)
                </td>
                <td>
                  If Riot formally approves a Community Competition Licence in
                  writing, and subject to the Organiser’s ongoing compliance
                  with the terms and conditions of the Agreement, Riot grants
                  the Organiser a limited, worldwide, non-exclusive,
                  non-transferable, revocable and terminable licence during the
                  Term to: (a) use, operate and publicly display the Game solely
                  in connection with the normal operation of the Tournament; and
                  (b) subject to clause 3 (Broadcasting), stream live coverage
                  and upload VODs of the Tournament through permitted Streaming
                  Services. All rights not expressly granted in the Agreement by
                  Riot are exclusively reserved to Riot and the Organiser shall
                  not use the Riot Property in any way except as explicitly
                  permitted in the Agreement.
                </td>
              </tr>

              <tr>
                <td style={{ width: "5%" }} valign="top">
                  b)
                </td>
                <td>
                  Use of Riot’s company and game names, logos, branding and
                  trade marks (“<span className="bold">Riot Marks</span>”) is
                  subject to additional restrictions detailed in the Game
                  Guidelines and any brand or other guidelines provided by Riot
                  from time to time. In no circumstances shall the Organiser use
                  the Riot Marks: (i) in a manner that could cause people to
                  believe that the Tournament is an official Riot production or
                  that it has been endorsed or approved by Riot; (ii) in a
                  manner that may damage the value, reputation or goodwill of
                  Riot or the Riot Property; or (iii) to create, market or sell
                  merchandise of any kind.
                </td>
              </tr>
            </tbody>
          </table>

          <table className="details-table">
            <tbody>
              <tr>
                <td valign="top">1.2.</td>
                <td>
                  <span className="under-line">Riot Property.</span> As between
                  Riot and the Organiser, Riot remains at all times the owner
                  of: (a) the Game, Riot’s other games and all assets and
                  materials relating to them, (b) the Riot Marks, (c) the Riot
                  Data, and (d) all materials of any kind provided by Riot to
                  the Organiser in connection with the Agreement,
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <table className="details-table" style={{ paddingTop: "25px" }}>
            <tbody>
              <tr>
                <td valign="top"></td>
                <td>
                  in each case including any updates and modifications to or
                  derivative works of the same made by either party, together
                  with all intellectual property rights in the same (“
                  <span className="bold">Riot Property</span>”). The Organiser
                  shall immediately cease use of the Riot Property where
                  requested by Riot.
                </td>
              </tr>

              <tr>
                <td valign="top">1.3.</td>
                <td>
                  <span className="under-line">Tournament Footage.</span> The
                  Organiser hereby grants Riot an irrevocable, perpetual,
                  transferable, non-exclusive, royalty-free and worldwide
                  licence to broadcast, display, distribute, promote, exploit,
                  use, modify and create derivative works of footage of the
                  Tournament (including any Participant Materials contained
                  therein) in order for Riot to promote the Tournament and Riot
                  generally. “
                  <span className="bold">Participant Materials</span>” means a
                  Participant’s name, tag, nickname, initials, likeness, image,
                  picture, photograph, animation, persona, autograph/signature,
                  voice, statistics, biographical information and/or any and all
                  other personal indicia, identifying characteristics or
                  information, together with all intellectual property rights in
                  the same.
                </td>
              </tr>
            </tbody>
          </table>

          <p style={{ paddingTop: "15px" }} className="bold">
            <span className="section-number">2.</span>TOURNAMENT CRITERIA
          </p>

          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top">2.1.</td>
                <td>
                  <span className="under-line">General.</span> The Organiser is
                  solely responsible at its own cost for all matters concerning
                  the organisation, operation, marketing and promotion of the
                  Tournament, including: (a) payment to any third parties that
                  are involved with the Tournament and any and all taxes; and
                  (b) obtaining all governmental approvals, licences and permits
                  required to host the Tournament.
                </td>
              </tr>

              <tr>
                <td valign="top">2.2.</td>
                <td>
                  <span className="under-line">Sponsorships.</span> The
                  Organiser may have sponsors for the Tournament subject to the
                  terms and conditions detailed in the Game Guidelines,
                  including that the Organiser and Tournament may not feature
                  any sponsorship from third parties that could reasonably be
                  considered (determined by Riot in its sole discretion) to fall
                  within the Prohibited Sponsor and Advertiser List detailed in
                  the Game Guidelines.
                </td>
              </tr>

              <tr>
                <td valign="top">2.3.</td>
                <td>
                  <span className="under-line">Physical Tournaments.</span> If
                  hosting a physical Tournament, the Organiser is solely
                  responsible for: (a) properly identifying and using a safe,
                  secure, and suitable location for the Tournament; (b) the
                  execution of all agreements and payment of all fees for use of
                  such location; and (c) obtaining any and all required
                  permissions or permits.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <table className="details-table" style={{ paddingTop: "25px" }}>
            <tbody>
              <tr>
                <td valign="top">2.4.</td>
                <td>
                  <span className="under-line">Participants.</span> The
                  Tournament must be freely open to the public and the Organiser
                  may not charge any fee to participate. The Organiser shall
                  verify the eligibility of all participants in the Tournament
                  (“<span className="bold">Participants</span>”) prior to the
                  Tournament taking place, which will include (at a minimum)
                  ensuring that: (a) Participants are old enough to legally
                  participate in the Tournament and the Organiser has obtained
                  parental consent where necessary; (b) Participant’s Riot IDs
                  do not contain any profanity, insults or obscenities; and (c)
                  all Participants and the public are informed that the
                  Tournament has been independently organised by the Organiser
                  without any participation, governance, or oversight by Riot.
                  The Organiser will not enter into any agreement with
                  Participants that restricts a Participant’s ability to play in
                  Riot’s own events. The following persons cannot be
                  Participants: (i) any member of staff involved with organising
                  the Tournament; or (ii) professional contracted players from
                  the Riot Global Contract Database, unless otherwise approved
                  by Riot in writing (email being sufficient); or (iii) any
                  player that has received a temporary or permanent ban for any
                  of Riot’s games.
                </td>
              </tr>

              <tr>
                <td valign="top">2.5.</td>
                <td>
                  <span className="under-line">Rules.</span> The Organiser shall
                  prepare a set of Tournament rules (the “
                  <span className="bold">Rules</span>”) that are freely
                  available at the time of Participant sign-up and accessible
                  online to all Participants in at least the languages native to
                  the sub-region where the Tournament is being held. The Rules
                  must not conflict with the terms of the Agreement. The
                  Organiser will use its best efforts to ensure that all
                  Participants comply with the Rules and applicable Game End
                  User Licence Agreement. The Rules must not conflict with the
                  Riot Games Terms of Service (
                  <span className="link">
                    <a href="https://www.riotgames.com/en/terms-of-service">
                      https://www.riotgames.com/en/terms-of-service
                    </a>
                  </span>
                  ) or Riot Privacy Notice (
                  <span className="link">
                    <a href="https://www.riotgames.com/en/privacy-notice">
                      https://www.riotgames.com/en/privacy-notice
                    </a>
                  </span>
                  ) and must make clear that Riot and its affiliates have no
                  liability or responsibility in relation to the Tournament. The
                  Organiser must submit draft Rules to Riot at the time of
                  submitting its Community Competition Licence application. Riot
                  may at its sole and absolute discretion require amendments to
                  any Rules or other documentation at any time.
                </td>
              </tr>

              <tr>
                <td valign="top">2.6.</td>
                <td>
                  <span className="under-line">Winners and Prizes.</span> If
                  there are more than one hundred and twenty-eight (128)
                  Participants, at least the top four (4) Participants must be
                  awarded prizes. Following the conclusion of the Tournament,
                  the Organiser is solely responsible for notifying winners and
                  carrying out the delivery of the prizes in a timely manner.
                  The Organiser is solely responsible for any tax or
                  legal-related
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <table className="details-table" style={{ paddingTop: "25px" }}>
            <tbody>
              <tr>
                <td valign="top"></td>
                <td>
                  notices, taxation obligations or other requirements that may
                  be applicable to any Tournament, prizes and/or the Tournament
                  winners.
                </td>
              </tr>

              <tr>
                <td valign="top">2.7.</td>
                <td>
                  <span className="under-line">Other Games.</span> The
                  Tournament may include play of other games as long as: (a) the
                  Organiser obtains Riot’s prior written approval (email being
                  sufficient); and (b) the Game is promoted at least as
                  prominently as any other game at the Tournament (determined by
                  Riot in its sole discretion) as evidenced by factors such as
                  relative signage, physical square footage of the stage,
                  quantity and quality of spectator seating, number and
                  respective size of projection screens, plus the overall
                  prominence of location within the venue of any such
                  Tournament.
                </td>
              </tr>

              <tr>
                <td valign="top">2.8.</td>
                <td>
                  <span className="under-line">Prohibited Activities.</span> The
                  Organiser must not: (a) display any content at the Tournament
                  that could be considered (determined by Riot in its sole
                  discretion) pornographic or adult-only; (b) conduct, promote
                  and/or facilitate any form on gambling, bettering, wagering at
                  or on the Tournament; and (c) promote the consumption of
                  alcohol.
                </td>
              </tr>

              <tr>
                <td valign="top">2.9.</td>
                <td>
                  <span className="under-line">Advertising.</span> The Organiser
                  shall ensure that any marketing and advertising for or shown
                  in connection with the Tournament complies with all applicable
                  laws and regulations and is suitable for the Game’s general
                  audience and age ratings. In addition, the Organiser shall not
                  place, display or post advertisements which contain any
                  material which could be seen (determined by Riot in its sole
                  discretion) as unlawful, libellous, obscene, indecent,
                  threatening, intimidating, harassing, or otherwise
                  objectionable, or harmful to the goodwill of Riot or the Riot
                  Property.
                </td>
              </tr>

              <tr>
                <td valign="top">2.10.</td>
                <td>
                  <span className="under-line">Merchandising.</span> The
                  Organiser may not sell any merchandise, goods or services at
                  the Tournament which include any Riot Property, unless
                  explicitly agreed by Riot in writing.
                </td>
              </tr>

              <tr>
                <td valign="top">2.11.</td>
                <td>
                  <span className="under-line">Insurance.</span> The Organiser
                  shall obtain and maintain with a reputable insurer
                  comprehensive insurance as required by law and as would be
                  obtained by a reasonably prudent business in the Organiser’s
                  position.
                </td>
              </tr>

              <tr>
                <td valign="top">2.12.</td>
                <td>
                  <span className="under-line">Additional Rules.</span> The
                  Organiser shall comply with any additional rules and/or
                  instructions as issued by Riot from time to time.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <p style={{ paddingTop: "25px" }} className="bold">
            <span className="section-number">3.</span>BROADCASTING
          </p>
          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top">3.1.</td>
                <td>
                  <span className="under-line">Permitted Broadcast.</span> The
                  Organiser may stream the Tournament as a livestream (“
                  <span className="bold">Stream</span>”) or as on-demand viewing
                  (“<span className="bold">VOD</span>”) on freely-accessible
                  online streaming services only (e.g. YouTube, Twitch, Facebook
                  Gaming) (“<span className="bold">Streaming Services</span>”),
                  provided that the Organiser shall:
                </td>
              </tr>
            </tbody>
          </table>

          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td style={{ width: "10%" }} valign="top">
                  a)
                </td>
                <td>
                  only stream the Tournament at the channels detailed in the
                  Tournament Details section of the Community Competition
                  Licence, unless otherwise agreed with Riot in writing in
                  advance (email being sufficient);
                </td>
              </tr>

              <tr>
                <td style={{ width: "10%" }} valign="top">
                  b)
                </td>
                <td>
                  not charge online viewers to watch the Stream or VODs,
                  including via any “paywall” or similar;
                </td>
              </tr>

              <tr>
                <td style={{ width: "10%" }} valign="top">
                  c)
                </td>
                <td>
                  ensure that any Stream and/or VOD chat and other social spaces
                  are adequately moderated to prevent vulgar, abusive or an
                  otherwise mean-spirited environment;
                </td>
              </tr>

              <tr>
                <td style={{ width: "10%" }} valign="top">
                  d)
                </td>
                <td>
                  comply with all applicable broadcasting laws and regulations;
                  and
                </td>
              </tr>

              <tr>
                <td style={{ width: "10%" }} valign="top">
                  e)
                </td>
                <td>
                  ensure that the Tournament and any associated Streams will not
                  take place at the same time or during the live game or live
                  broadcast times for regional and national competitions for
                  Riots’ games
                </td>
              </tr>
            </tbody>
          </table>

          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top">3.2.</td>
                <td>
                  <span className="under-line">Prohibited Broadcast.</span> The
                  Organiser is not permitted to broadcast the Tournament
                  anywhere except the permitted Streaming Services, including
                  via television, catch-up apps or any form of linear media.
                </td>
              </tr>
            </tbody>
          </table>

          <p style={{ paddingTop: "15px" }} className="bold">
            <span className="section-number">4.</span>DATA PROTECTION AND
            REPORTING
          </p>
          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top">4.1.</td>
                <td>
                  <span className="under-line">Riot Data.</span> Riot will be
                  the sole and exclusive owner of any data, databases and data
                  files in relation to the Game (whether collected by Riot, the
                  Organiser or a third party analytics or data services
                  provider), including without limitation any: (a) user account
                  details, user statistics or metrics, device or user
                  identifiers, user acquisition or retention campaign
                  information and any other information provided by or collected
                  from users of the Game; and (b) any
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <table className="details-table" style={{ paddingTop: "25px" }}>
            <tbody>
              <tr>
                <td valign="top"></td>
                <td>
                  database or other storage media for the same (together “
                  <span className="bold">Riot Data</span>”) and Riot reserves
                  all rights (including without limitation all exploitation and
                  commercialisation rights) over the same. The Organiser will
                  not collect, copy, monitor, inspect, modify or otherwise deal
                  in or exploit the Riot Data without Riot’s prior written
                  consent.
                </td>
              </tr>

              <tr>
                <td valign="top">4.2.</td>
                <td>
                  <span className="under-line">Participant Consents</span> The
                  Organiser is solely responsible for the use of and entry into
                  an appropriate privacy policy between the Organiser and the
                  Participants. The Organiser will obtain and maintain any
                  required consents from the Participants to allow the Organiser
                  to host and record the Tournament, perform its obligations and
                  grant the rights and licences detailed in the Agreement. Any
                  personal information provided to or collected by Riot in
                  relation to the Tournament will be held in accordance with the
                  Riot Privacy Notice available at{" "}
                  <span className="link">
                    <a href="https://www.riotgames.com/en/privacy-notice">
                      https://www.riotgames.com/en/privacy-notice
                    </a>
                  </span>
                </td>
              </tr>

              <tr>
                <td valign="top">4.3.</td>
                <td>
                  <span className="under-line">Tournament Information.</span>{" "}
                  The Organiser shall promptly provide to Riot: (a) Tournament,
                  Stream and VOD metrics, including the number and Riot IDs of
                  Participants; (b) records, documentation, communications and
                  other materials related to the Tournament (including all prize
                  money, advertising and sponsorship revenues) so that Riot can
                  verify the Organiser’s compliance with the terms of the
                  Agreement; and (c) any other information related to the
                  Tournament reasonably requested by Riot. Riot may once in any
                  six (6) month period conduct an independent audit and review
                  of the Organiser’s records in order to audit the Organiser’s
                  compliance with the terms of the Agreement and the Organiser
                  will provide all required assistance for the purpose of such
                  audit and review.
                </td>
              </tr>
            </tbody>
          </table>

          <p style={{ paddingTop: "15px" }} className="bold">
            <span className="section-number">5.</span>REPRESENTATIONS AND
            WARRANTIES
          </p>
          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top">5.1.</td>
                <td>
                  The Organiser represents and warrants to Riot and Riot’s
                  affiliates (together, the “
                  <span className="bold">Riot Parties</span>”) on an ongoing
                  basis that:
                </td>
              </tr>
            </tbody>
          </table>

          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  a)
                </td>
                <td>
                  it has the requisite power to enter into the Agreement and
                  there are no third party agreements or arrangements or other
                  matters or situations that may or do materially impact on its
                  ability to perform its obligations in the Agreement;
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <table className="details-table" style={{ paddingTop: "25px" }}>
            <tbody>
              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  b)
                </td>
                <td>
                  it has and will maintain appropriate agreements in place with
                  all of its staff, employees, agents and sub-contractors in
                  order to grant the rights and licences to Riot detailed in the
                  Agreement;
                </td>
              </tr>

              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  c)
                </td>
                <td>
                  it has and will obtain written consent from Participants to
                  use the Participant Materials and to grant the licence
                  detailed in clause 1.3 (Tournament Footage) and a waiver to
                  use spectators’ image, likeness and any other relevant
                  information, to the extent permitted under applicable law;
                </td>
              </tr>

              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  d)
                </td>
                <td>
                  it will carry out its obligations under the Agreement using
                  all reasonable skill and care in accordance with best industry
                  standards;
                </td>
              </tr>

              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  e)
                </td>
                <td>
                  it will not collect, copy, modify or use Riot Data without
                  Riot’s prior written consent;
                </td>
              </tr>

              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  f)
                </td>
                <td>
                  it and the Tournament does and will comply with all applicable
                  laws and regulations, including all necessary health and
                  safety and security measures such as for epilepsy and
                  COVID-19;
                </td>
              </tr>

              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  g)
                </td>
                <td>
                  the Tournament and footage of the tournament is and will be
                  free from any unauthorised third party interest, right or
                  ownership; and
                </td>
              </tr>

              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  h)
                </td>
                <td>
                  the Organiser shall not use or permit the use of illegal,
                  unauthorised or modified copies of the Game at the Tournament.
                </td>
              </tr>
            </tbody>
          </table>

          <p style={{ paddingTop: "15px" }} className="bold">
            <span className="section-number">6.</span>TERMINATION
          </p>
          <table className="details-table" style={{ paddingTop: "25px" }}>
            <tbody>
              <tr>
                <td valign="top">6.1.</td>
                <td>
                  <span className="under-line">Termination.</span> Riot may
                  suspend or terminate the Agreement (in whole or in part)
                  immediately and without obligation or liability if:
                </td>
              </tr>
            </tbody>
          </table>

          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  b)
                </td>
                <td>
                  the Organiser commits a material breach of its obligations
                  under the Agreement (or any number of individual breaches
                  which in Riot’s reasonable view amounts to a material breach)
                  and if the breach is capable of remedy, fails to remedy it
                  during the period of seven (7) days starting on the date of
                  receipt of notice from Riot requiring it to be
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <table className="details-table" style={{ paddingTop: "25px" }}>
            <tbody>
              <tr>
                <td valign="top" style={{ width: "10%" }}></td>
                <td>
                  remedied. Any breach by the Organiser of the Game Guidelines
                  constitutes a material breach;
                </td>
              </tr>

              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  c)
                </td>
                <td>
                  Riot considers that the Tournament does not meet the standards
                  required by Riot for esports of its Games;
                </td>
              </tr>

              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  d)
                </td>
                <td>
                  the Organiser, its directors, officers, employees or other
                  representatives have acted in a way that is defamatory,
                  disparaging, racist, sexist, xenophobic, anti-Semitic,
                  homophobic, immoral, deceptive, criminal, violent, or
                  otherwise in a way that could cause damage to the name and
                  reputation of Riot and/or its Games;
                </td>
              </tr>

              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  e)
                </td>
                <td>
                  the Organiser becomes insolvent or bankrupt, or enters
                  insolvency or bankruptcy proceedings or takes any steps
                  towards the same, or if it ceases or threatens to cease to
                  carry on business; and/or
                </td>
              </tr>

              <tr>
                <td valign="top" style={{ width: "10%" }}>
                  f)
                </td>
                <td>
                  the Organiser is prevented from performing its obligations
                  under the Agreement by reason of Force Majeure for fourteen
                  (14) days or more.
                </td>
              </tr>
            </tbody>
          </table>

          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top">6.2.</td>
                <td>
                  <span className="under-line">
                    General Consequences of Termination.
                  </span>{" "}
                  Following expiry or termination of the Agreement for any
                  reason, the parties’ rights and obligations under it will end
                  immediately, but not the accrued rights and obligations of the
                  parties and any provisions of the Agreement necessary for its
                  interpretation or enforcement, provided that clauses 4 (Data
                  Protection and Reporting), 5 (Representations and Warranties),
                  7 (Liability), 8 (Confidentiality) and 10 (General) shall
                  survive termination of the Agreement. The Organiser must
                  immediately cease all use of the Riot Property. In addition,
                  Riot may in its sole and absolute discretion: (a) prohibit the
                  Organiser from hosting any future tournaments, including
                  revoking any current licences; and/or (b) cancel any other
                  ongoing or planned tournaments.
                </td>
              </tr>
            </tbody>
          </table>

          <p style={{ paddingTop: "15px" }} className="bold">
            <span className="section-number">7.</span>LIABILITY
          </p>
          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top">7.1.</td>
                <td>
                  <span className="under-line">Disclaimer.</span> Riot makes no
                  representation or warranty, and expressly disclaims any
                  liability with respect to the Riot Property. The Riot Property
                  is provided on an “as is” and “as available” basis, and Riot
                  disclaims any and all warranties,
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <table className="details-table" style={{ paddingTop: "25px" }}>
            <tbody>
              <tr>
                <td valign="top"></td>
                <td>
                  conditions, or representations (express, implied, oral or
                  written), relating to the Riot Property or any part thereof,
                  including, without limitation, any and all implied warranties
                  of availability, quality, performance, merchantability or
                  fitness for a particular purpose.
                </td>
              </tr>

              <tr>
                <td valign="top">7.2.</td>
                <td>
                  <span className="under-line">Liability Cap.</span> To the
                  extent permitted by law, Riot’s total liability to the
                  Organiser for any loss, harm or damage suffered by the
                  Organiser in connection with the present Licence Conditions,
                  use and/or access of the any of Riot’s Services is limited to
                  the total amounts paid by the Organiser to Riot during the six
                  (6) months immediately prior to the time the Organiser’s cause
                  of action first arose. In no event will the Riot Parties be
                  liable to the Organiser for any loss of earnings, profits,
                  charges or expenses, loss of data or any corruption or loss of
                  information, loss of business opportunity, reputational loss
                  or harm, or any special, exemplary, indirect, consequential or
                  punitive loss or damage or disruption of any kind, in any
                  case, whether based on breach of contract, tort (including
                  negligence or breach of statutory duty), misrepresentation,
                  restitution or otherwise, whether or not the relevant party
                  has been advised of the possibility of such damage. This
                  exclusion does not apply in the case of personal injury, death
                  or fraud.
                </td>
              </tr>

              <tr>
                <td valign="top">7.3.</td>
                <td>
                  <span className="under-line">Indemnity.</span> The Organiser
                  agrees on demand to indemnify and hold harmless the Riot
                  Parties (and keep them indemnified and held harmless) from and
                  against any and all damages, claims, suits, actions,
                  judgments, settlements, contributions, fines, penalties, costs
                  and expenses whatsoever, including reasonable legal fees and
                  costs, arising out of: (a) the Tournament; (b) any actual or
                  alleged unauthorised use by the Organiser of the Riot Property
                  or Confidential Information of Riot; (iii) any actual or
                  alleged unauthorised use by a third party of the Riot Property
                  or Confidential Information in connection with the Tournament;
                  and/or (iv) any material breach by the Organiser of the terms
                  of the Agreement.
                </td>
              </tr>

              <tr>
                <td valign="top">7.4.</td>
                <td>
                  <span className="under-line">.Equitable Relief.</span> The
                  Organiser acknowledges and agrees that a violation by it of
                  the Agreement may cause Riot irreparable harm, which cannot be
                  reasonably or adequately compensated in damages in an action
                  at law and accordingly the Organiser agrees that Riot will be
                  entitled to injunctive and other equitable relief to prevent
                  or curtail any actual or threatened breach of the Agreement.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <p style={{ paddingTop: "25px" }} className="bold">
            <span className="section-number">8.</span>CONFIDENTIALITY
          </p>
          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top">8.1.</td>
                <td>
                  Each party undertakes that it will not at any time hereafter
                  use, divulge or communicate to any person, except to its
                  professional representatives or advisers or as may be required
                  by law or any legal or regulatory authority, any Confidential
                  Information concerning the business or affairs of the other
                  party or of any member of the group of companies to which the
                  other party belongs which may have or may in future come to
                  its knowledge and each of the parties will use its reasonable
                  endeavours to prevent the publication or disclosure of any
                  Confidential Information concerning such matters.
                  “Confidential Information” means the Agreement and any
                  information or materials in whatever form which is disclosed
                  by one party to the other party and which would be regarded as
                  confidential by a reasonable business person including all
                  business, technical, statistical, financial, marketing and
                  personnel information, customer or supplier details, know-how,
                  designs, trade secrets, creative information or materials or
                  software of the disclosing party or any information that is
                  identified as "Confidential".
                </td>
              </tr>
            </tbody>
          </table>

          <p style={{ paddingTop: "15px" }} className="bold">
            <span className="section-number">9.</span>FORCE MAJEURE
          </p>
          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top">9.1.</td>
                <td>
                  Neither party shall be liable for non-performance, delay or
                  suspension of performance caused by any event or condition
                  beyond its control, including, but not limited to, fire,
                  earthquake, flood, or other natural disaster, explosion,
                  strike, sabotage, labour controversy, pandemic, act of
                  terrorism or war, “Act of God,” or any law, proclamation,
                  regulation, ordinance, or other act or order of any court,
                  government or government agency, or any failure or delay of
                  any power, server, network, or communication system (“Force
                  Majeure”). In the event of such Force Majeure contingency, the
                  affected party shall strive to notify the other within
                  twenty-four (24) hours of the occurrence.
                </td>
              </tr>
            </tbody>
          </table>

          <p style={{ paddingTop: "15px" }} className="bold">
            <span className="section-number">10.</span>GENERAL
          </p>
          <table className="details-table" style={{ paddingTop: "15px" }}>
            <tbody>
              <tr>
                <td valign="top">10.1.</td>
                <td>
                  <span className="under-line">Investigations.</span> Riot may
                  at its discretion carry out investigations into any actual or
                  alleged breach of the Agreement. The Organiser agrees to
                  provide all required assistance to Riot in relation to the
                  same. Failure by the Organiser to cooperate with any Riot
                  investigation constitutes a material breach of the Agreement.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tournament-contract-form">
        <div style={{ position: "relative" }}>
          <table className="details-table" style={{ paddingTop: "25px" }}>
            <tbody>
              <tr>
                <td valign="top">10.2.</td>
                <td>
                  <span className="under-line">Changes to the Agreement.</span>{" "}
                  Riot reserves the right to edit, change, delete or add to
                  these Terms & Conditions and the Game Guidelines in its sole
                  discretion at any time. Any other changes to the Agreement
                  must be in writing and signed by both parties.
                </td>
              </tr>

              <tr>
                <td valign="top">10.3.</td>
                <td>
                  <span className="under-line">Entire Agreement.</span> The
                  Agreement constitutes the whole agreement between the parties
                  and supersedes all previous agreements between them regarding
                  its subject matter. Each party acknowledges that, in entering
                  into the Agreement, it has not relied on, and will have no
                  right or remedy in respect of, any statement, representation,
                  assurance or warranty other than as expressly set out in the
                  Agreement.
                </td>
              </tr>

              <tr>
                <td valign="top">10.4.</td>
                <td>
                  <span className="under-line">Assignment.</span> The Organiser
                  may not assign, sub-contract, sub-licence or transfer the
                  Agreement or any of its rights or obligations under it without
                  Riot’s prior written consent.
                </td>
              </tr>

              <tr>
                <td valign="top">10.5.</td>
                <td>
                  <span className="under-line">Severability.</span> If any part
                  of the Agreement is found to be invalid or unenforceable, that
                  will not affect the rest of the Agreement.
                </td>
              </tr>

              <tr>
                <td valign="top">10.6.</td>
                <td>
                  <span className="under-line">No Partnership.</span> The
                  Agreement does not create any exclusive relationship between
                  the parties nor any partnership, joint venture, employment or
                  agency between them.
                </td>
              </tr>

              <tr>
                <td valign="top">10.7.</td>
                <td>
                  <span className="under-line">Governing Law.</span> The
                  Agreement and any dispute or claim arising out of or in
                  connection with it or its subject matter or formation will be
                  governed by and construed in accordance with the law of the
                  Republic of Ireland. The parties agree that the courts of
                  Dublin Ireland will have exclusive jurisdiction to settle any
                  dispute or claim in connection with the Agreement.
                  Notwithstanding the above, Riot retains the right to bring a
                  claim or action anywhere in the world in order to protect the
                  Riot Property.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TournamentContractForm;
