import React from "react";
import { Link } from "react-router-dom";

function FaqItem({ tIndex = 10000, fIndex, faq }) {
  return (
    <li className="faq-list-item">
      <Link
        to={`#faq-list-${tIndex}-${fIndex}`}
        data-bs-toggle="collapse"
        className="collapse collapsed"
        data-bs-target={`#faq-list-${tIndex}-${fIndex}`}
      >
        <div>{faq.question}</div>
        <i className="bx bx-chevron-down icon-close"></i>
        <i className="bx bx-chevron-up icon-show"></i>
      </Link>
      <div
        id={`faq-list-${tIndex}-${fIndex}`}
        className="collapse"
        data-bs-parent=".faq-list"
      >
        <p>{faq.answer}</p>
      </div>
    </li>
  );
}

export default FaqItem;
