import { axiosService as API } from "../../services/axiosInterceptors";
import { RegionActions } from "./types";

export const fetchRegions = () => {
  return async (dispatch, getState) => {
    dispatch(loadRegions);
    API.get("api/common/regions")
      .then((response) => {
        dispatch(saveRegions(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(readyRegions());
      });
  };
};

export const loadRegions = () => ({
  type: RegionActions.REGION_LOAD,
});

export const readyRegions = () => ({
  type: RegionActions.REGION_READY,
});

export const saveRegions = (list) => ({
  type: RegionActions.REGION_SAVE,
  payload: list,
});
