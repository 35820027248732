import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import jsPDF from "jspdf";

import * as Actions from "../../../store/actions";

import {
  STATUS_COLOR,
  TOURNAMENT_STATUS,
  TOURNAMENT_STEPS,
} from "../../../utils/app.constants";

import TournamentContractForm from "../../../components/profile/TournamentContractForm";
import RiotSans from "../../../assets/fonts/RiotSans-Bold.js";

import "../../../styles/Profile/TournamentDetails.scss";
import useRequireAuth from "../../../hooks/useRequireAuth.js";
import { Helmet } from "react-helmet";

function TournamentHistory() {
  useRequireAuth();

  const { t } = useTranslation("ProjectTranslation");
  const history = useHistory();
  const dispatch = useDispatch();

  const TournamentContractFormRef = useRef(null);

  const tournament = useSelector(({ tournaments }) => tournaments.selected);
  const tournamentDetails = useSelector(
    ({ tournaments }) => tournaments.tournament_details
  );
  const games = useSelector(({ common }) => common.games);

  let { id } = useParams();

  const getGameCode = () => {
    return games.find((game) => game.id === tournamentDetails?.game_name)?.code;
  };

  const downloadAssets = () => {
    const gameCode = getGameCode();

    dispatch(Actions.downloadTournamentAssets(gameCode));
  };

  const downloadContract = () => {
    let contract = document.createElement("div");
    contract = TournamentContractFormRef.current.cloneNode(true);

    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      compress: true,
    });

    doc.addFileToVFS("RiotSans-Bold.otf", RiotSans);
    doc.addFont("RiotSans-Bold.otf", "RiotSans", "normal", "normal");
    doc.setFont("RiotSans");

    contract.style.display = "block";
    contract.style.width = "450px";

    doc.html(contract, {
      async callback(doc) {
        await doc.save(`${tournamentDetails.tournament_english_name} contract`);
      },
    });
  };

  const handleStatusClick = (step, status, index) => {
    if (status === 1 && index === tournamentDetails?.details?.length - 1) {
      openSubmitStep(step);
    }
  };

  const openEditStep = (step) => {
    history.push(`/tournaments/update-custom-tournament/${id}/step-${step}`);
  };

  const openSubmitStep = (step) => {
    history.push(`/tournaments/create-custom-tournament/${id}/step-${step}`);
  };

  useEffect(() => {
    dispatch(Actions.fetchCustomTournamentDetails(id));
    dispatch(Actions.getCustomTournamentById(id));
  }, [dispatch, id]);

  return (
    <div className="profile-tournament-details">
      <Helmet>
        <title>Tournament History</title>
      </Helmet>

      <div className="header">
        <div className="container text-container">
          <h1 className="px-0">{t("profile_tournaments.heading")}</h1>
        </div>
      </div>

      <section className="pageContainer">
        <div className="container pt-5">
          <h2>{tournamentDetails.tournament_english_name}</h2>
          <h2 className="mb-5">{tournamentDetails.tournament_arabic_name}</h2>

          {tournamentDetails?.step > 1 && (
            <button
              className="btn btn-primary custom-button mb-5"
              onClick={downloadAssets}
            >
              {t("profile_tournaments.download_assets")}
            </button>
          )}

          <div className="tournament-details">
            {tournamentDetails?.details?.map((action, index) => (
              <div
                className="detail-row p-3"
                key={`history_action_${action.id}`}
              >
                <div className="d-flex flex-column flex-lg-row justify-content-between">
                  <div className="d-flex flex-column flex-lg-row">
                    <div className="d-flex flex-wrap">
                      <div className="step-number pe-5 pt-1">
                        Step {action.step}
                      </div>
                      <div className="step pe-lg-5 pe-0">
                        {TOURNAMENT_STEPS[action.step]}
                      </div>
                    </div>
                    <div className="date pe-lg-5 pe-0">
                      {action.status !== 1 && (
                        <>
                          {"on "}
                          {new DateObject(action.createdAt).format(
                            "DD/MM/YYYY"
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={`status ${action.status === 1 && "submit"}`}
                    onClick={() => {
                      handleStatusClick(action.step, action.status, index);
                    }}
                  >
                    {action.status === 2 &&
                      index === tournamentDetails?.details?.length - 1 && (
                        <span
                          className="edit-submission"
                          onClick={() => openEditStep(action.step)}
                        >
                          {t("profile_tournaments.edit_submission")}
                        </span>
                      )}

                    {!(
                      action.status === 1 &&
                      index !== tournamentDetails?.details?.length - 1
                    ) && (
                      <span
                        className="status-name"
                        style={{
                          background:
                            STATUS_COLOR[TOURNAMENT_STATUS[action.status]],
                        }}
                      >
                        {TOURNAMENT_STATUS[action.status]}
                        {action.status === 1 && (
                          <i className="bi bi-arrow-right ms-2"></i>
                        )}
                      </span>
                    )}
                  </div>
                </div>

                {(action.status === 4 || action.status === 5) && (
                  <>
                    <div className="action-note-container">
                      <p className="mb-0 note">{action.note}</p>
                      <p className="note-by mb-0"> by {action.note_by}</p>
                    </div>
                    <div>
                      {action.status === 5 &&
                        index === tournamentDetails?.details?.length - 1 && (
                          <p
                            className="edit-rejection mb-0"
                            onClick={() => {
                              openEditStep(action.step);
                            }}
                          >
                            {t("form.edit")}{" "}
                            <i className="bi bi-arrow-right ms-1"></i>
                          </p>
                        )}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>

          {tournamentDetails?.step === 3 &&
            (tournamentDetails?.status === 3 ||
              tournamentDetails?.status === 4) &&
            tournament?.step === 3 &&
            (tournament?.status === 3 || tournament?.status === 4) && (
              <React.Fragment>
                <p className="pt-5 approved-congratulations">
                  CONGRATULATIONS! YOUR TOURNAMENT APPLICATION HAS BEEN
                  APPROVED!
                </p>
                <button
                  className="btn btn-primary custom-button mt-2"
                  onClick={downloadContract}
                >
                  {t("profile_tournaments.download_contract")}
                </button>
                <div
                  ref={TournamentContractFormRef}
                  style={{ display: "none" }}
                >
                  <TournamentContractForm />
                </div>
              </React.Fragment>
            )}
        </div>
      </section>
    </div>
  );
}

export default TournamentHistory;
