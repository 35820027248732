import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DateObject } from "react-multi-date-picker";

import * as Actions from "../../store/actions";
import HomeBanner from "../../components/Home/Banner";
import List from "../OnGoingTournaments/List";
import Calendar from "../OnGoingTournaments/Calendar";
import HowItWorks from "../../components/Home/HowItWorks";

import "../../styles/Home/index.scss";
import HomeFAQs from "../../components/Home/FAQs";
import OurSupport from "../../components/Home/OurSupport";
import { Helmet } from "react-helmet";

const PAGE_SIZE = 9;

function HomePage() {
  const { game } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [date, setDate] = useState(new Date());

  const onGoingCount = useSelector(({ common }) => common.onGoingCount);
  const games = useSelector(({ common }) => common.games);

  const openOngoingPage = (page) => {
    history.push(`/tournaments/${game}/on-going/${page}`);
  };

  const changeMonth = (type) => {
    if (type === "increase") {
      setDate(new DateObject(date).add(1, "month"));
    } else {
      setDate(new DateObject(date).subtract(1, "month"));
    }
  };

  const getGameName = () => {
    return games?.filter((g) => g.code === game)[0]?.name || "";
  };

  useEffect(() => {
    if (games.length > 0) {
      const gameId = games.filter((g) => g.code === game)[0]?.id;

      dispatch(
        Actions.fetchCustomTournaments(gameId, 0, PAGE_SIZE, "all", "all")
      );

      const month = new DateObject(date).format("M");
      const year = new DateObject(date).format("YYYY");

      dispatch(
        Actions.fetchCustomTournamentsCalendar(
          gameId,
          0,
          PAGE_SIZE,
          "all",
          "all",
          month,
          year
        )
      );
    }
  }, [dispatch, date, games, game]);

  return (
    <div className="home-page">
      <Helmet>
        <title>{getGameName()} - Home</title>
      </Helmet>

      <HomeBanner />

      <div className="container">
        <div className="d-flex ongoing-count-container">
          <img
            className="cup-icon"
            src="assets/img/shape-684.png"
            alt="cup icon"
          />
          <p className="ongoing-count ms-2 mb-0">{onGoingCount}</p>
          <p className="ongoing-text ms-2 mb-0">ongoing tournaments</p>
        </div>

        <section className="ongoing-list mt-5 pt-5">
          <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
            <h2 className="heading-text mb-0">ONGOING TOURNAMENTS</h2>

            <button
              type="button"
              className="btn btn-primary custom-button outline mt-3 mt-md-0"
              onClick={() => openOngoingPage("list")}
            >
              View All
            </button>
          </div>
          <List isHome />
        </section>

        <section className="mt-5 pt-5">
          <Calendar date={date} changeMonth={changeMonth} isHome />

          <div className="d-flex justify-content-center mt-5">
            <button
              type="button"
              className="btn btn-primary custom-button outline mt-3 mt-md-0"
              onClick={() => openOngoingPage("calendar")}
            >
              View All
            </button>
          </div>
        </section>

        <section className="mt-5 pt-5 how-it-works">
          <HowItWorks game={game} />
        </section>

        <section className="mt-5 pt-5 our-support">
          <OurSupport />
        </section>

        <section className="mt-5 pt-5 home-faqs">
          <HomeFAQs />
        </section>
      </div>
    </div>
  );
}

export default HomePage;
