import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { formValidations } from "../../utils/formValidations";
import Input from "../../components/Input";
import * as Actions from "../../store/actions";

import "../../styles/ResetPassword.scss";
import { Helmet } from "react-helmet";

function ResetPasswordPage() {
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({ defaultValues: { ...initialValues } });

  const history = useHistory();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const password = watch("password");

  const onSubmit = (data) => {
    dispatch(Actions.ResetPassword({ ...data, token })).then(() => {
      reset({ ...initialValues });
      history.push("/login");
    });
  };

  const { t } = useTranslation("ProjectTranslation");

  return (
    <div className="reset-password-page">
      <Helmet>
        <title>Reset Password</title>
      </Helmet>

      <div className="formContainer">
        <div className="container">
          <h1 className="heading">{t("reset_password.enter_new_password")}</h1>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-8 col-12">
                <Input
                  hiddenLabel
                  type="password"
                  placeholder={t("form.new_password")}
                  defaultValue=""
                  {...register("password", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(6),
                    maxLength: formValidations.maxLength(15),
                    pattern: formValidations.validatePassword(),
                  })}
                  errors={errors.password?.message}
                  inputCols={12}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-8 col-12 mt-3">
                <Input
                  hiddenLabel
                  type="password"
                  placeholder={t("form.confirm_new_password")}
                  defaultValue=""
                  {...register("confirmPassword", {
                    required: formValidations.required(),
                    validate: (value) =>
                      formValidations.validateConfirmPassword(password, value),
                  })}
                  errors={errors.confirmPassword?.message}
                  inputCols={12}
                />
              </div>
            </div>

            <div className="row mt-4 pt-3">
              <button
                type="submit"
                className="btn btn-primary custom-button mt-5"
              >
                {t("form.submit")}
              </button>
            </div>

            <div className="row">
              <Link className="back-to-sign-in" to={`/login`}>
                {t("form.back_to_sign_in")}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
