import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { formValidations } from "../../../utils/formValidations";
import Checkbox from "../../../components/Checkbox";

import { COUNTRIES_EXCEPT_MENA } from "../../../utils/app.constants";

function FocusCountries({
  register,
  handleSubmit,
  control,
  errors,
  reset,
  watch,
  setValue,
  trigger,
  isSubmitted,
  countriesInRegion,
  isOther,
}) {
  const { t } = useTranslation("ProjectTranslation");

  const countries = useSelector((state) => state.countries.list);

  const focus_countries = watch("focus_countries");
  const focus_countries_other = watch("focus_countries_other");

  const handleMenaCkeckboxClick = async () => {
    if (isMenaChecked()) {
      setValue("focus_countries", []);
    } else {
      const countriesList = countries?.map((country) => country.code);

      setValue("focus_countries", [...countriesList]);
    }
    if (isSubmitted) {
      await trigger("focus_countries");
    }
  };

  const isMenaChecked = () => {
    let isAllRegionChecked = true;

    countriesInRegion?.forEach((region) => {
      if (!isRegionChecked(region.countries) && !isOther(region.regionName)) {
        isAllRegionChecked = false;
      }
    });
    return isAllRegionChecked;
  };

  const isRegionChecked = (regionCountries) => {
    return regionCountries.every((country) =>
      focus_countries.includes(country.code)
    );
  };

  const handleRegionClick = async (countries) => {
    if (isRegionChecked(countries)) {
      deselectAllCountriesInRegion(countries);
    } else {
      selectAllCountriesInRegion(countries);
    }
    if (isSubmitted) {
      await trigger("focus_countries");
    }
  };

  const selectAllCountriesInRegion = (countries) => {
    const countriesList = getUnSelectedCountriesInRegion(countries);
    setValue("focus_countries", [...focus_countries, ...countriesList]);
  };

  const getUnSelectedCountriesInRegion = (countries) => {
    const countriesList = [];
    countries?.forEach((country) => {
      if (!focus_countries.includes(country.code)) {
        countriesList.push(country.code);
      }
    });
    return countriesList;
  };

  const deselectAllCountriesInRegion = (countries) => {
    const countriesIds = countries?.map((country) => country.code);
    setValue(
      "focus_countries",
      focus_countries.filter((country) => !countriesIds.includes(country))
    );
  };

  const handleOtherRegionCLicked = () => {
    if (isOtherRegionChecked()) {
      setValue("focus_countries_other", []);
    } else {
      setValue("focus_countries_other", [
        ...COUNTRIES_EXCEPT_MENA.map((country) => ({
          label: country.name,
          value: country.code,
        })),
      ]);
    }
  };

  const isOtherRegionChecked = () => {
    return focus_countries_other.length === 195 - countries.length;
  };

  useEffect(() => {
    async function triggerFocusCountriesValidation() {
      if (isSubmitted) {
        await trigger("focus_countries");
      }
    }
    triggerFocusCountriesValidation();
  }, [focus_countries_other, trigger, isSubmitted]);

  return (
    <div className="row">
      <div className="col-lg-3 col-12">
        <p className="col-form-label field-label pb-0">
          {t("custom_tournament_form_step_1.regions_label")}
        </p>
        <p className="field-label field-sub-label mb-0">
          {t("custom_tournament_form_step_1.regions_label_sub")}
        </p>
      </div>
      <div className="col-lg-9 col-12 mt-2">
        <div className="form-field-container">
          <div className="d-flex form-check form-check-inline custom-radio-button mena-checkbox-container px-0 pb-2 mb-2">
            <Checkbox
              onChange={() => handleMenaCkeckboxClick()}
              label="MENA"
              checked={isMenaChecked()}
              labelClass="bold"
              name="mena_region"
            />
          </div>

          <div className="container px-0">
            <div className="row col-12">
              {countriesInRegion?.map((region) => (
                <div
                  className="col-xxl-3 col-lg-5 col-12 custom-radio-button"
                  key={region.regionId}
                >
                  {isOther(region.regionName) ? (
                    <Checkbox
                      label={region.regionName}
                      checked={isOtherRegionChecked()}
                      onChange={() => handleOtherRegionCLicked()}
                      labelClass="bold"
                      name={`region_${region.regionName}`}
                    />
                  ) : (
                    <Checkbox
                      label={region.regionName}
                      checked={isRegionChecked(region.countries)}
                      onChange={() => handleRegionClick(region.countries)}
                      labelClass="bold"
                      name={`region_${region.regionName}`}
                    />
                  )}

                  <div className="d-flex flex-column">
                    {isOther(region.regionName) ? (
                      <Controller
                        name="focus_countries_other"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isMulti
                            options={COUNTRIES_EXCEPT_MENA.map((country) => {
                              return {
                                value: country.code,
                                label: country.name,
                              };
                            })}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Please specify"
                          />
                        )}
                      />
                    ) : (
                      region.countries?.map((country) => (
                        <div
                          className="form-check form-check-inline custom-radio-button"
                          key={country.code}
                        >
                          <Checkbox
                            {...register("focus_countries", {
                              required:
                                focus_countries_other.length === 0 &&
                                formValidations.required(),
                            })}
                            checked={focus_countries.includes(
                              country.code.toString()
                            )}
                            label={country.nameEn}
                            value={country.code}
                          />
                        </div>
                      ))
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {errors?.focus_countries && (
        <p className="input-error col-12 offset-lg-3">
          * {errors?.focus_countries?.message}
        </p>
      )}
    </div>
  );
}

export default FocusCountries;
