import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import LoginRegisterModal from "../LoginRegisterModal";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function HomeBanner() {
  const history = useHistory();
  const { game } = useParams();

  const user = useSelector(({ me }) => me.user);
  const data = require("../../staticContent.json");
  const bannerImages = data.bannerImages;
  const games = useSelector(({ common }) => common.games);

  const [isLoginRegisterModalOpen, setIsLoginRegisterModalOpen] =
    useState(false);
  const [isAuthorized, setIsAuthorized] = useState(!!user?.id);

  const viewGameBanner = () => {
    return bannerImages[game];
  };

  const applyPage = () => {
    if (!isAuthorized) {
      setIsLoginRegisterModalOpen(!isLoginRegisterModalOpen);
    } else history.push("/tournaments/create-custom-tournament");
  };

  const getGameName = () => {
    return games?.filter((iGame) => iGame.code === game)[0]?.name;
  };

  useEffect(() => {
    setIsAuthorized(!!user?.id);
  }, [user]);

  return (
    <div className={`bg ${game}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="heading">ORGANIZE YOUR TOURNAMENT WITH US</h1>
          </div>
          <div className="col-lg-6 col-12">
            <p className="subtext">
              Looking to get your {getGameName()} tournament licensed by Riot
              Games? Look no further than our Tournament On-Demand platform! Our
              streamlined registration process allows you to obtain licensing
              from Riot Games and gain access to a wide range of benefits and
              services.
            </p>
            <div className="applyHere" onClick={applyPage}>
              apply now <i className="bi bi-arrow-right ms-2"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="game-banner-container col-lg-6 col-12 offset-lg-6 p-0">
            <img
              className="game-banner to-game-banner"
              src={viewGameBanner()}
              alt="Game banner"
            />
          </div>
        </div>
      </div>

      <LoginRegisterModal
        isOpened={isLoginRegisterModalOpen}
        setIsOpened={setIsLoginRegisterModalOpen}
      />
    </div>
  );
}

export default HomeBanner;
