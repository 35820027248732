import { CountryActions } from "../actions/types";

const initialState = {
  loading: false,
  list: [],
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CountryActions.COUNTRY_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case CountryActions.COUNTRY_READY: {
      return {
        ...state,
        loading: false,
      };
    }
    case CountryActions.COUNTRY_SAVE: {
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    }
    case CountryActions.COUNTRY_SELECTED_SAVE: {
      return {
        ...state,
        loading: false,
        selected: action.payload,
      };
    }
    case CountryActions.COUNTRY_CITY_SAVE: {
      return {
        ...state,
        loading: false,
        cities: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default countryReducer;
