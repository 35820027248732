import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Actions from "../store/actions";
import { useLocation } from "react-router-dom";
import { DEFAULT_LANG } from "../utils/app.constants";

import "../styles/global.scss";

const Home = (props) => {
  const lang = useLocation().pathname.slice(1, 3);

  const dispatch = useDispatch();

  useEffect(() => {
    if (lang !== DEFAULT_LANG) {
      const newLang = lang === "ar" ? "ar" : "en";

      dispatch(Actions.changeLanguage(newLang));
    }
  }, [dispatch, lang]);

  return (
    <div className="main" style={{ height: "100%" }}>
      <main>{props.children}</main>
    </div>
  );
};

export default Home;
