import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { formValidations } from "../../utils/formValidations";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Input from "../../components/Input";
import * as Actions from "../../store/actions";
import "../../styles/LoginPage.scss";
import { Helmet } from "react-helmet";

function LoginPage() {
  const initialValues = {
    email: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { ...initialValues } });

  const history = useHistory();
  const dispatch = useDispatch();

  const page_path = useSelector(({ common }) => common.page_path);

  const onSubmit = (data) => {
    dispatch(Actions.Login(data)).then((response) => {
      if (response) {
        reset({ ...initialValues });
        history.push(page_path);
      }
    });
  };

  const { t } = useTranslation("ProjectTranslation");

  return (
    <div className="login-page">
      <Helmet>
        <title>Login Page</title>
      </Helmet>

      <div className="formContainer">
        <div className="container">
          <h1 className="heading">{t("login.sign_in")}</h1>
          <p className="subHeading">
            Or <Link to={`/register`}>Create a TOD account</Link>
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-8 col-12">
                <Input
                  hiddenLabel
                  label={t("form.email")}
                  placeholder={t("form.email")}
                  defaultValue=""
                  {...register("email", {
                    required: formValidations.required(),
                    pattern: formValidations.validateEmail(),
                  })}
                  errors={errors.email?.message}
                  inputCols={12}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-8 col-12 mt-3">
                <Input
                  hiddenLabel
                  type="password"
                  label={t("form.password")}
                  placeholder={t("form.password")}
                  defaultValue=""
                  {...register("password", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(6),
                    maxLength: formValidations.maxLength(15),
                    pattern: formValidations.validatePassword(),
                  })}
                  errors={errors.password?.message}
                  inputCols={12}
                />
              </div>
            </div>

            <div className="row">
              <button
                type="submit"
                className="btn btn-primary custom-button mt-5"
              >
                {t("form.sign_in")}
              </button>
            </div>

            <div className="row">
              <Link className="forgotten-password" to={`/forgot-password`}>
                {t("form.forgotten_your_password")}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
