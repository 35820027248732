import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DateObject } from "react-multi-date-picker";

import Pagination from "../../../components/Pagination";
import * as Actions from "../../../store/actions";

import {
  STATUS_COLOR,
  TOURNAMENT_STATUS,
  TOURNAMENT_STEPS,
} from "../../../utils/app.constants";

import "../../../styles/Profile/Tournaments.scss";

function UserTournaments() {
  const { t } = useTranslation("ProjectTranslation");

  const tournaments = useSelector(({ me }) => me.custom_tournaments);
  const pageCount = useSelector(({ me }) => me.custom_tournaments_total_pages);
  const selectedGame = useSelector(({ common }) => common.selectedGame);
  const games = useSelector(({ common }) => common.games);

  const history = useHistory();
  const dispatch = useDispatch();

  const openDetailsPage = (id) => {
    history.push(`/profile/tournaments/${id}`);
  };

  const openHomePage = () => {
    history.push(`/tournaments/${selectedGame}`);
  };

  const getGameId = useCallback(() => {
    const game = games.find(
      (game) => game.code.toLowerCase() === selectedGame.toLowerCase()
    );
    return game?.id;
  }, [games, selectedGame]);

  const handlePageClick = (event) => {
    const newPage = event.selected;
    dispatch(Actions.fetchOwnerCustomTournaments(newPage, 10, getGameId()));
  };

  useEffect(() => {
    dispatch(Actions.fetchOwnerCustomTournaments(0, 10, getGameId()));
  }, [dispatch, getGameId]);

  return (
    <div className="container">
      {tournaments?.length > 0 ? (
        <React.Fragment>
          <h2 className="heading">{t("profile_tournaments.page_heading")}</h2>

          <section className="tournaments-table-data py-0">
            <div className="table-responsive">
              <table className="table table-striped table-hover align-middle">
                <tbody>
                  {tournaments?.map((tournament) => (
                    <tr
                      key={tournament.id}
                      onClick={() => openDetailsPage(tournament.id)}
                    >
                      <td className="name pre-line-space">
                        {tournament.tournament_english_name}
                        {"\n"}
                        {tournament.tournament_arabic_name}
                      </td>
                      <td className="step">
                        STEP {tournament.step}{" "}
                        {TOURNAMENT_STEPS[tournament.step]}
                      </td>
                      <td className="date">
                        on{" "}
                        {tournament?.updated_at
                          ? new DateObject(tournament.updated_at).format(
                              "DD.MM.YYYY"
                            )
                          : new DateObject(tournament.created_at).format(
                              "DD.MM.YYYY"
                            )}
                      </td>
                      <td
                        className={`status ${
                          tournament.status === 1 && "submit"
                        }`}
                      >
                        <span
                          className="status-text"
                          style={{
                            background:
                              STATUS_COLOR[
                                TOURNAMENT_STATUS[tournament.status]
                              ],
                          }}
                        >
                          {TOURNAMENT_STATUS[tournament.status]}
                          {tournament.status === 1 && (
                            <i className="bi bi-arrow-right ms-2"></i>
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
            />
          </section>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h2 className="heading">
            {t("profile_tournaments.no_current_tournaments")}
          </h2>

          <button
            type="button"
            className="btn btn-primary custom-button outline"
            onClick={openHomePage}
          >
            {t("profile_tournaments.back_to_home")}
          </button>
        </React.Fragment>
      )}
    </div>
  );
}

export default UserTournaments;
