import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import i18n from "../../i18n";

import valorant from "../../assets/img/valorant-avatar.png";
import valorantLogo from "../../assets/img/valorant-logo.png";
import lol from "../../assets/img/league-of-legends-avatar.png";
import lolLogo from "../../assets/img/league-of-legends-logo.png";
import wildRift from "../../assets/img/wild-rift-avatar.png";
import wildRiftLogo from "../../assets/img/wild-rift-logo.png";
import arrowRight from "../../assets/icons/multiGamesSelectGamesArrowRight.png";

import { GAMES } from "../../utils/app.constants";

import "../../styles/LandingPageMultiGames.scss";
import { Helmet } from "react-helmet";

function LandingPageMultiGames() {
  const { t } = useTranslation("ProjectTranslation");
  const history = useHistory();
  const pathName = useLocation().pathname;

  const selectGame = (game) => {
    if (pathName.includes("/tournaments")) {
      history.push(`/tournaments/${game}`);
    } else {
      const lang = i18n.language;
      console.log(lang);
      history.push(`/${lang}/user-game-select/${game}`);
    }
  };

  return (
    <div className="multi-games-page">
      <Helmet>
        <title>TOs - Game Select</title>
      </Helmet>

      <div className="bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9">
              <h1>
                {t("landingPageMultiGames.tournament_on_demand_1")}
                <span className="game-name">
                  {t("landingPageMultiGames.tournament_on_demand_2")}{" "}
                </span>
                {t("landingPageMultiGames.tournament_on_demand_3")}
              </h1>
              <p> {t("landingPageMultiGames.tournament_on_demand_text")}</p>
            </div>
          </div>
        </div>

        <div className="games-section">
          <div className="container ">
            <div className="row justify-content-center">
              <div
                className="col-12 col-md-9 col-lg-6 col-xxl-4"
                onClick={() => selectGame(GAMES.VALORANT)}
              >
                <div className="game valorant d-flex justify-content-end">
                  <img
                    className="game-logo"
                    src={valorantLogo}
                    alt="valorant"
                  />
                  <img className="avatar" src={valorant} alt="lol" />
                  <div className="arrow-container">
                    <img className="arrow" src={arrowRight} alt="arrow-right" />
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-md-9 col-lg-6 col-xxl-4 mt-2 mt-lg-0"
                onClick={() => selectGame(GAMES.LOL)}
              >
                <div className="game lol d-flex justify-content-end">
                  <img className="game-logo" src={lolLogo} alt="lol-logo" />
                  <img className="avatar lol-avatar" src={lol} alt="lol" />
                  <div className="arrow-container">
                    <img className="arrow" src={arrowRight} alt="arrow-right" />
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-md-9 col-lg-6 col-xxl-4 mt-2 mt-xxl-0"
                onClick={() => selectGame(GAMES.WILD_RIFT)}
              >
                <div className="game wild-rift d-flex justify-content-end">
                  <img
                    className="game-logo"
                    src={wildRiftLogo}
                    alt="wild-rift-logo"
                  />
                  <img className="avatar" src={wildRift} alt="lol" />
                  <div className="arrow-container">
                    <img className="arrow" src={arrowRight} alt="arrow-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageMultiGames;
