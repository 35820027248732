import React from "react";
import i18n from "../../../i18n";

function OnGoingTournamentsCalendarHeading({
  changeMonth,
  getShortMonthName,
  getFullYear,
  monthDays,
  isBroadcastDay,
}) {
  const language = i18n.language;

  const isAR = () => {
    return language === "ar";
  };

  return (
    <section className="heading mt-5">
      <div className="row mx-0">
        <div className="col-6 col-lg-3 row-border-bottom left-sticky-part">
          <div className="month-selector d-flex flex-nowrap justify-content-around align-items-center">
            <i
              className={`bi ${
                isAR() ? "bi-chevron-compact-right" : "bi-chevron-compact-left "
              } d-inline-block`}
              onClick={() => changeMonth("decrease")}
            ></i>
            <div>
              <span className="month mx-2">{getShortMonthName()}</span>
              <span className="year">{getFullYear()}</span>
            </div>
            <i
              className={`bi ${
                isAR() ? "bi-chevron-compact-left" : "bi-chevron-compact-right "
              } d-inline-block`}
              onClick={() => changeMonth("increase")}
            ></i>
          </div>
        </div>
        <div className="col-6 col-lg-9">
          <div className="days-names row flex-nowrap">
            {monthDays.map((day) => (
              <div
                className={`day cell-border-left row-border-bottom ${
                  isBroadcastDay(day.day) && "broadcast-day"
                } ${isAR() && "ar"}`}
                key={day.day}
              >
                <div className="mb-1">{day.dayName}</div>
                <div className="mb-2">{day.day}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default OnGoingTournamentsCalendarHeading;
