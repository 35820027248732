import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import i18n from "../../i18n";

import "../../styles/LandingPage.scss";
import { Helmet } from "react-helmet";

function LandingPage() {
  const { t } = useTranslation("ProjectTranslation");
  const history = useHistory();

  const openPage = (page) => {
    history.push(`/${page}`);
  };

  const openUserSelectGamePage = () => {
    const lang = i18n.language;
    history.push(`/${lang}/user-game-select`);
  };

  return (
    <div className="landing-page">
      <Helmet>
        <title>TOD - Home Page</title>
      </Helmet>

      <div className="bg">
        <div className="container image-container">
          <img
            src="assets/img/landing-page.png"
            className="riot-image"
            alt="landing page"
          />
        </div>
      </div>
      <div className="container text-center">
        <div className="row">
          <div className="col-12 mb-3 riot-small-image">
            <img
              src="assets/img/landing-page.png"
              className="riot-image"
              alt="landing page"
            />
          </div>
          <div className="col-12 col-lg-6">
            <h4 className="title">{t("landingPage.for_the_players")}</h4>
            <hr />
            <p className="text riot-sans-font">
              {t("landingPage.ongoing_tournaments")}
            </p>
            <button
              className="action-button  mb-5 mb-lg-0"
              onClick={openUserSelectGamePage}
            >
              {t("landingPage.browse_tournaments")}
            </button>
          </div>
          <div className="col-12 col-lg-6">
            <h4 className="title">
              {t("landingPage.for_the_tournament_organizers")}
            </h4>
            <hr />
            <p className="text riot-sans-font">
              {t("landingPage.apply_for_the_tournament_license")}
            </p>
            <button
              className="action-button"
              onClick={() => openPage("tournaments")}
            >
              {t("landingPage.apply_here")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
