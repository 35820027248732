import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { formValidations } from "../../../utils/formValidations";
import Input from "../../../components/Input";
import FilesPicker from "../../../components/FilesPicker";

import * as Actions from "../../../store/actions";

import "../../../styles/CustomTournamentForms.scss";
import useRequireAuth from "../../../hooks/useRequireAuth";
import { isTotalPDFsSizeValid } from "../../../utils/helpers/helpers";
import { TOTAL_FILES_AND_IMAGES_SIZE_ERROR_MESSAGE } from "../../../utils/app.constants";
import { Helmet } from "react-helmet";
import useIsStepCanOpen from "../../../hooks/useIsStepCanOpen";

function CreateCustomTournamentStep2() {
  const initialValues = {
    assets_link: "",
    pdf_presentation: [],
    selected_pdf_presentation: [],
    tournament_keyart: [],
    tournament_keyart_file: [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({ defaultValues: { ...initialValues } });

  useRequireAuth();

  const { t } = useTranslation("ProjectTranslation");

  const games = useSelector(({ common }) => common.games);
  const tournament = useSelector(({ tournaments }) => tournaments.selected);
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  useIsStepCanOpen(2, tournament);

  const pdf_presentation = watch("pdf_presentation");
  const selected_pdf_presentation = watch("selected_pdf_presentation");
  const tournament_keyart = watch("tournament_keyart");
  const tournament_keyart_file = watch("tournament_keyart_file");

  const [isLoading, setIsLoading] = useState(false);

  const isUpdatePage = () => {
    return history.location.pathname.includes("update-custom-tournament");
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    const action = isUpdatePage() ? updateStep2 : createStep2;

    dispatch(action({ ...data, id }))
      .then(() => {
        const gameName = games.filter(
          (game) => game.id === tournament.game_name
        )[0].code;

        history.push(`/tournaments/${gameName}/thank-you/2`);
        reset({ ...initialValues });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createStep2 = (data) => {
    return Actions.registerCustomTournamentStep2(data);
  };

  const updateStep2 = (data) => {
    return Actions.updateCustomTournamentStep2(data);
  };

  const deleteTournamentFile = (fieldName, url) => {
    dispatch(
      Actions.deleteTournamentFile({
        tournamentId: id,
        fieldName: fieldName,
        url: url,
      })
    );
  };

  const addPresentationFile = async (file) => {
    if (
      isTotalPDFsSizeValid([
        ...selected_pdf_presentation,
        file,
        ...tournament_keyart_file,
      ])
    ) {
      await setValue("selected_pdf_presentation", [
        ...selected_pdf_presentation,
        file,
      ]);

      if (isSubmitted) {
        await trigger("selected_pdf_presentation");
      }
    } else {
      dispatch(
        Actions.saveErrorMessage({
          errorMessage: TOTAL_FILES_AND_IMAGES_SIZE_ERROR_MESSAGE,
          isError: true,
        })
      );
    }
  };

  const removePresentationFile = async (index) => {
    await setValue(
      "selected_pdf_presentation",
      selected_pdf_presentation.filter((file, fileIndex) => fileIndex !== index)
    );
    if (isSubmitted) {
      await trigger("selected_pdf_presentation");
    }
  };

  const removePresentationFileLink = async (index) => {
    deleteTournamentFile("pdf_presentation", pdf_presentation[index]);

    await setValue(
      "pdf_presentation",
      pdf_presentation.filter((file, fileIndex) => fileIndex !== index)
    );
    if (isSubmitted) {
      await trigger("pdf_presentation");
    }
  };

  const addKeyartFile = async (file) => {
    if (isTotalPDFsSizeValid([...selected_pdf_presentation, file])) {
      await setValue("tournament_keyart_file", [file]);
      await setValue("tournament_keyart", []);
      if (isSubmitted) {
        await trigger("tournament_keyart_file");
        await trigger("tournament_keyart");
      }
    } else {
      dispatch(
        Actions.saveErrorMessage({
          errorMessage: TOTAL_FILES_AND_IMAGES_SIZE_ERROR_MESSAGE,
          isError: true,
        })
      );
    }
  };

  const removeKeyartFile = async () => {
    await setValue("tournament_keyart_file", []);
    if (isSubmitted) {
      await trigger("tournament_keyart_file");
    }
  };

  const removeKeyartLink = async () => {
    deleteTournamentFile("tournament_keyart", tournament_keyart[0]);

    await setValue("tournament_keyart", []);
    if (isSubmitted) {
      await trigger("tournament_keyart");
    }
  };

  useEffect(() => {
    dispatch(Actions.getCustomTournamentById(id)).then((response) => {
      reset({
        ...response,
        selected_pdf_presentation: [],
        tournament_keyart_file: [],
      });
    });
  }, [reset, dispatch, id]);

  return (
    <div className="custom-tournament-create-pages">
      <Helmet>
        <title>Broadcast & Social Assets</title>
      </Helmet>

      <div className="header">
        <div className="container text-container">
          <h1>{t("custom_tournament_form_step_2.heading")}</h1>
        </div>
      </div>

      <div className="formContainer">
        <div className="container">
          <h2 className="heading">
            {t("custom_tournament_form_step_2.form_heading")}
          </h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <p className="form_note col-lg-9 col-12 offset-lg-3 form-note mt-1 mb-0">
                {t("custom_tournament_form_step_2.form_note")}
              </p>
              <div className="col-12 mt-3">
                <Input
                  label={t("custom_tournament_form_step_2.form.assets_link")}
                  labelClass="bold"
                  placeholder={t("custom_tournament_form_step_2.form.link")}
                  {...register("assets_link", {
                    required: formValidations.required(),
                    pattern: formValidations.validateURL(),
                    maxLength: formValidations.maxLength(255),
                  })}
                  errors={errors.assets_link?.message}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <FilesPicker
                  label={t(
                    "custom_tournament_form_step_2.form.pdf_presentation"
                  )}
                  labelClass="bold"
                  files={selected_pdf_presentation}
                  addFile={addPresentationFile}
                  removeFile={removePresentationFile}
                  links={pdf_presentation}
                  removeLink={removePresentationFileLink}
                  {...register("selected_pdf_presentation", {
                    validate: () =>
                      pdf_presentation?.length > 0 ||
                      selected_pdf_presentation?.length > 0 ||
                      "Required",
                  })}
                  errors={errors.selected_pdf_presentation?.message}
                  attachWord="Presentation(s)"
                />
                <p className="col-lg-9 col-12 offset-lg-3 field-note mt-1 mb-0">
                  {t(
                    "custom_tournament_form_step_2.form.pdf_presentation_note"
                  )}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <FilesPicker
                  label={t(
                    "custom_tournament_form_step_2.form.tournament_keyart"
                  )}
                  labelClass="bold"
                  files={tournament_keyart_file}
                  addFile={addKeyartFile}
                  removeFile={removeKeyartFile}
                  links={tournament_keyart}
                  removeLink={removeKeyartLink}
                  extensions={["jpg", "jpeg", "png"]}
                  maxSize={5}
                  dims={{
                    minWidth: 1920,
                    maxWidth: 1920,
                    minHeight: 1080,
                    maxHeight: 1080,
                  }}
                  {...register("tournament_keyart_file", {
                    validate: () =>
                      tournament_keyart?.length > 0 ||
                      tournament_keyart_file?.length > 0 ||
                      "Required",
                  })}
                  errors={errors.tournament_keyart_file?.message}
                  attachWord="Image"
                />
                <p className="col-lg-9 col-12 offset-lg-3 field-note mt-1 mb-0">
                  {t(
                    "custom_tournament_form_step_2.form.tournament_keyart_note"
                  )}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-9 col-12 mt-4 offset-lg-3">
                <button
                  type="submit"
                  className="btn btn-primary custom-button full-width"
                  disabled={isLoading}
                  onClick={() => {
                    window.gtag("event", "submit-step-2", {
                      event_category: "Button",
                      event_label: "submit-step-2",
                    });
                  }}
                >
                  {t("custom_tournament_form_step_2.form.submit_your_assets")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateCustomTournamentStep2;
