import React, { forwardRef } from "react";

import "../styles/input.scss";

const Input = forwardRef(
  (
    {
      type = "text",
      placeholder = "",
      name,
      label,
      defaultValue = "",
      errors,
      onChange,
      onBlur,

      topLabel = false,
      labelCols = 3,
      inputCols = 9,
      hiddenLabel = false,

      labelClass = "",
      containerClass = "",
      disabled = false,
    },
    ref
  ) => (
    <div
      className={`input-form-group form-group row ${
        topLabel ? "top-label-container" : ""
      } ${containerClass}`}
    >
      {!hiddenLabel && (
        <label
          htmlFor={name}
          className={`col-12 col-form-label ${
            topLabel ? "top-label col-lg-12" : `col-lg-${labelCols}`
          } ${labelClass}`}
        >
          {label}
        </label>
      )}
      <div
        className={`col-12 ${
          hiddenLabel ? "col-sm-12" : `col-lg-${inputCols}`
        } ${containerClass === "other-input-container" && "ps-0"}`}
      >
        <input
          ref={ref}
          id={name}
          name={name}
          type={type}
          className="form-control"
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          aria-invalid={errors ? "true" : "false"}
          disabled={disabled}
        />
        {errors && <p className="input-error">* {errors}</p>}
      </div>
    </div>
  )
);

export default Input;
