import React, { forwardRef } from "react";

import "../styles/input.scss";

const Textarea = forwardRef(
  (
    {
      placeholder = "",
      name,
      label,
      errors,
      onChange,
      onBlur,

      topLabel = false,
      labelCols = 3,
      inputCols = 9,
      hiddenLabel = false,

      labelClass = "",

      rows = 5,
    },
    ref
  ) => (
    <div
      className={`input-form-group form-group row ${
        topLabel ? "top-label-container" : ""
      }`}
    >
      {!hiddenLabel && (
        <label
          htmlFor={name}
          className={`col-12 col-form-label ${
            topLabel ? "top-label col-lg-12" : `col-lg-${labelCols}`
          } ${labelClass}`}
        >
          {label}
        </label>
      )}
      <div
        className={`col-lg-${inputCols} col-12 ${
          hiddenLabel ? "col-sm-12" : `col-lg-${inputCols}`
        }`}
      >
        <textarea
          ref={ref}
          id={name}
          name={name}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          aria-invalid={errors ? "true" : "false"}
          rows={rows}
        ></textarea>
        {errors && <p className="input-error">* {errors}</p>}
      </div>
    </div>
  )
);

export default Textarea;
