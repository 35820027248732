import React from "react";
import { useTranslation } from "react-i18next";

import EditUserDate from "../../../components/profile/EditUserDate";
import EditUserPassword from "../../../components/profile/EditUserPassword";

import "../../../styles/Profile/EditProfileData.scss";

function EditProfileData() {
  const { t } = useTranslation("ProjectTranslation");

  return (
    <div className="edit-profile-data">
      <div className="formContainer">
        <div className="container">
          <h2 className="heading">{t("edit_profile_data.subheader")}</h2>

          <EditUserDate />

          <EditUserPassword />
        </div>
      </div>
    </div>
  );
}

export default EditProfileData;
