import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateObject } from "react-multi-date-picker";
import { useSelector } from "react-redux";

import { isOther } from "../../../utils/helpers/helpers";
import { COUNTRIES_EXCEPT_MENA } from "../../../utils/app.constants";

import "../../../styles/OnGoingTournaments/TournamentCard.scss";

import calendar from "../../../assets/icons/calendar.png";
import timeCountdown from "../../../assets/icons/time-countdown.png";
import CustomTooltip from "../../Tooltip";
import i18n from "../../../i18n";

function TournamentCard({ tournament, cardClass = "", isRFP = false }) {
  const { t } = useTranslation("ProjectTranslation");
  const language = i18n.language;

  const [tournamentCountriesNames, setTournamentCountriesNames] = useState([]);

  const format_cont = useSelector(({ tournaments }) => tournaments.format_cont);
  const countries = useSelector((state) => state.countries.list);

  const viewFormateType = () => {
    const format_type = tournament.format_type;

    return isOther(format_type)
      ? tournament.format_type_other
      : format_cont[format_type - 1]?.name;
  };

  const viewName = () => {
    return isAR()
      ? tournament.tournament_arabic_name
      : tournament.tournament_english_name;
  };

  const isAR = useCallback(() => {
    return language === "ar";
  }, [language]);

  const calculateRemainingDays = (date) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(date);
    const secondDate = new Date();

    return Math.round(Math.abs((firstDate - secondDate) / oneDay));
  };

  const viewDay = (date) => {
    return new DateObject(date).format("DD");
  };

  const viewMonth = (date) => {
    return new DateObject(date).format("MMM");
  };

  const viewExtraCountriesNames = () => {
    const namesExceptFirst = tournamentCountriesNames.slice(1);
    let names = "";

    namesExceptFirst.forEach((country) => {
      names += `- ${country}\n`;
    });

    return names;
  };

  const getCountryNameUsingCode = useCallback(
    (code) => {
      const country = countries.filter((country) => country.code === code)[0];
      return isAR() ? country.nameAr : country.nameEn;
    },
    [isAR, countries]
  );

  const getOtherCountryNameUsingCode = useCallback(
    (code) => {
      const country = COUNTRIES_EXCEPT_MENA.filter(
        (country) => country.code === code
      )[0];
      return isAR() ? country.nameAr : country.name;
    },
    [isAR]
  );

  const getTournamentCountriesNames = useCallback(() => {
    const { focus_countries, focus_countries_other } = tournament;

    let countriesNames = [];

    focus_countries.forEach((country, index) => {
      countriesNames.push(getCountryNameUsingCode(country));
    });

    focus_countries_other.forEach((country, index) => {
      countriesNames.push(getOtherCountryNameUsingCode(country));
    });

    setTournamentCountriesNames(countriesNames);
  }, [tournament, getCountryNameUsingCode, getOtherCountryNameUsingCode]);

  const ensureHttps = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return "https://" + url;
    }
    return url;
  };

  useEffect(() => {
    getTournamentCountriesNames();
  }, [getTournamentCountriesNames]);

  return (
    <div
      className={`tournament-card ${cardClass}`}
      style={{
        backgroundImage: `url(${
          tournament?.tournament_keyart?.length > 0
            ? tournament.tournament_keyart[0].replace(/\s/g, "%20")
            : "https://asvs.in/wp-content/uploads/2017/08/dummy.png"
        })`,
      }}
    >
      <div className="tournament-card__content-container">
        <div className="tournament-card__region-and-type">
          <div className="d-flex align-items-center justify-content-between">
            <div className="regions d-flex">
              <div className="countries">{tournamentCountriesNames[0]}</div>
              {tournamentCountriesNames.length > 1 && (
                <CustomTooltip
                  content={viewExtraCountriesNames()}
                  tooltipClasses="countries-tooltip"
                >
                  <div className="countries countries-other mx-2">
                    + {tournamentCountriesNames.length - 1}
                  </div>
                </CustomTooltip>
              )}
            </div>
            <div className="format_type">{viewFormateType()}</div>
          </div>
        </div>

        <div className="tournament-card__name-and-dates">
          <div className="name">{viewName()}</div>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <img src={timeCountdown} alt="time-countdown" className="me-2" />
              <div className="left-days">
                {new Date(tournament.start_date).getTime() <=
                new Date().getTime()
                  ? // t("tournament_card.tournament_started")
                    "Tournament is Live"
                  : `${calculateRemainingDays(tournament.start_date)} ${t(
                      "tournament_card.days_left_for_register"
                    )}`}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <img src={calendar} alt="calendar" className="me-2" />
              <div className="left-days">{`${viewDay(
                tournament.start_date
              )} ${viewMonth(tournament.start_date)} - ${viewDay(
                tournament.end_date
              )} ${viewMonth(tournament.end_date)}`}</div>
            </div>
          </div>

          <Link
            to={{ pathname: ensureHttps(tournament.organizer_website_url) }}
            target="_blank"
          >
            <button
              type="button"
              className="btn btn-primary custom-button visit-registration-website"
              onClick={() => {
                window.gtag("event", "Visit-Registration-Website", {
                  event_category: "Button",
                  event_label: "Registration-Here",
                });
              }}
            >
              {t("tournament_card.visit_registration_website")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TournamentCard;
