import { axiosService as API } from "../../services/axiosInterceptors";
import { CommonActions } from "./types";

export const setSelectedGame = (game) => {
  return async (dispatch) => {
    dispatch(saveSelectedGame(game));
  };
};

export const setErrorMessage = (error) => {
  return async (dispatch) => {
    let errorMessage = error?.response?.data?.message || error?.message;

    if (
      Array.isArray(error?.response?.data?.errors) &&
      error?.response?.data?.errors.length > 0
    ) {
      errorMessage = error?.response?.data?.errors.map((error) => {
        if (error?.field) {
          return `- ${error.field} ${error.defaultMessage}\n`;
        } else {
          return `- ${error}\n`;
        }
      });
    } else if (typeof error?.response?.data?.errors === "string") {
      errorMessage = error?.response?.data?.errors;
    }

    dispatch(saveErrorMessage({ errorMessage, isError: true }));
  };
};

export const fetchGames = () => {
  return async (dispatch) => {
    API.get("/api/common/gameNames").then((response) => {
      dispatch(saveGames(response.data));
    });
  };
};

export const fetchOnGoingTournamentsNumber = (game) => {
  return async (dispatch) => {
    API.get("/api/common/ongoingTournaments").then((response) => {
      dispatch(saveOnGoingTournamentsNumber(response.data));
    });
  };
};

export const fetchBannerImages = () => {
  return async (dispatch, getState) => {
    await API.get("/api/bannerImages")
      .then((response) => {
        dispatch(saveBannerImages(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const downloadTournamentAssets = (gameName) => {
  return async () => {
    try {
      const url = `${API.defaults.baseURL}/api/common/s3/${gameName}`;
      window.location.href = url;
    } catch (error) {
      console.log(error);
    }
  };
};

export const saveGames = (list) => ({
  type: CommonActions.SAVE_GAMES,
  payload: list,
});

export const saveSelectedGame = (list) => ({
  type: CommonActions.SAVE_SELECTED_GAME,
  payload: list,
});

export const saveOnGoingTournamentsNumber = (count) => ({
  type: CommonActions.SAVE_ON_GOING_TOURNAMENTS_NUMBER,
  payload: count,
});

export const saveErrorMessage = ({ errorMessage, isError }) => ({
  type: CommonActions.SAVE_ERROR_MESSAGE,
  payload: {
    isError,
    errorMessage,
  },
});

export const saveSuccessMessage = ({ successMessage, isSuccess }) => ({
  type: CommonActions.SAVE_SUCCESS_MESSAGE,
  payload: {
    isSuccess,
    successMessage,
  },
});

export const saveBannerImages = (data) => ({
  type: CommonActions.SAVE_BANNER_IMAGES,
  payload: data,
});

export const savePageLocation = (page) => ({
  type: CommonActions.SAVE_PAGE_LOCATION,
  payload: page,
});
