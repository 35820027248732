import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { FilePicker } from "react-file-picker";
import * as Actions from "../store/actions";

import "../styles/FilesPicker.scss";

const CustomFilePicker = forwardRef(
  (
    {
      name,
      label,
      errors,
      onBlur,

      topLabel = false,
      labelCols = 3,
      inputCols = 9,
      hiddenLabel = false,

      labelClass = "",
      files = [],
      links = [],
      removeFile = () => {},
      removeLink = () => {},
      addFile = () => {},
      extensions = ["pdf"],
      dims = {},
      maxSize = 10,

      attachWord = "Attach",
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const getName = (file) => {
      if (file?.name) {
        return file.name;
      }

      return file;
    };

    const viewErrorMessages = (error) => {
      dispatch(Actions.setErrorMessage({ message: error }));
    };

    const getFileName = (fileName) => {
      let newFileName = fileName.split(".");
      newFileName.pop();
      newFileName = newFileName
        .join("")
        .substring(0, 85)
        .replace(/[^a-zA-Z0-9\s\u0621-\u064A]/g, "");

      const fileExtension = fileName.split(".").pop();

      return newFileName + " " + Date.now() + "." + fileExtension;
    };

    const setFile = (file) => {
      const newFileName = getFileName(file.name);
      const newFile = new File([file], newFileName, { type: file.type });
      addFile(newFile);
    };

    return (
      <div
        className={`files-picker-form-group form-group row ${
          topLabel ? "top-label-container" : ""
        }`}
      >
        {!hiddenLabel && (
          <label
            htmlFor={name}
            className={`col-12 col-form-label ${
              topLabel ? "top-label col-lg-12" : `col-lg-${labelCols}`
            } ${labelClass}`}
          >
            {label}
          </label>
        )}
        <div
          className={`col-lg-${inputCols} col-12 ${
            hiddenLabel ? "col-sm-12" : `col-lg-${inputCols}`
          }`}
        >
          {!!links?.length &&
            links.map((link, index) => (
              <div className="d-flex mb-2" key={`${link}_${index}`}>
                <p className="picker-text picker-gray-container mb-0">{link}</p>
                <p
                  className="icon-button picker-gray-container mb-0 pointer"
                  onClick={() => removeLink(index)}
                >
                  <i className="bi bi-x-lg"></i>
                </p>
              </div>
            ))}

          {!!files?.length &&
            files.map((file, index) => (
              <div className="d-flex mb-2" key={`${getName(file)}_${index}`}>
                <p className="picker-text picker-gray-container mb-0">
                  {getName(file)}
                </p>
                <p
                  className="icon-button picker-gray-container mb-0 pointer"
                  onClick={() => removeFile(index)}
                >
                  <i className="bi bi-x-lg"></i>
                </p>
              </div>
            ))}

          <div className="d-flex">
            <p className="picker-text picker-gray-container mb-0">
              <img
                src="assets/icons/attachment.png"
                alt="attachment"
                className="attachment-icon"
              />
              {attachWord}
            </p>
            <FilePicker
              ref={ref}
              id={name}
              name={name}
              extensions={extensions}
              dims={dims}
              maxSize={maxSize}
              onChange={(FileObject) => setFile(FileObject)}
              onError={(errMsg) => {
                console.log(errMsg);
                viewErrorMessages(errMsg);
              }}
              aria-invalid={errors ? "true" : "false"}
            >
              <p className="picker-button picker-gray-container mb-0 pointer">
                <i className="bi bi-plus-lg"></i>
              </p>
            </FilePicker>
          </div>
          {errors && <p className="input-error">* {errors}</p>}
        </div>
      </div>
    );
  }
);

export default CustomFilePicker;
