import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { DateObject } from "react-multi-date-picker";

import useIsScreenBottom from "../../hooks/useIsScreenBottom";
import * as Actions from "../../store/actions";
import List from "./List";
import { GAMES, YES_NO } from "../../utils/app.constants";
// import WantToWorkWithUs from "../../components/OnGoingTournaments/WantToWorkWithUs";
import Spinner from "../../components/Spinner";
import Calendar from "./Calendar";

import "../../styles/OnGoingTournaments/index.scss";
import i18n from "../../i18n";
import { Helmet } from "react-helmet";

const DEFAULT_PAGE = 0;
const PAGE_SIZE = 15;

function OnGoingTournaments() {
  const language = i18n.language;

  const { t } = useTranslation("ProjectTranslation");
  const inBottom = useIsScreenBottom(50);

  const DEFAULT_REGION = useMemo(
    () => ({
      value: "all",
      label: t("on_going_tournaments.all"),
    }),
    [t]
  );
  const DEFAULT_VRC = useMemo(
    () => ({
      value: "all",
      label: t("yes_no.all"),
    }),
    [t]
  );

  const { game } = useParams();
  const pathName = useLocation().pathname;
  const dispatch = useDispatch();
  const history = useHistory();

  const games = useSelector(({ common }) => common.games);
  const pageCount = useSelector(
    ({ tournaments }) => tournaments.onGoingTournamentsTotalPages
  );
  const calendarPageCount = useSelector(
    ({ tournaments }) => tournaments.onGoingTournamentsCalendarTotalPages
  );
  const isLoading = useSelector(
    ({ tournaments }) => tournaments.onGoingTournamentsLoading
  );

  const regions = useSelector((state) => state.regions.list);

  const [region, setRegion] = useState(DEFAULT_REGION);
  const [vrc, setVrc] = useState(DEFAULT_VRC);
  const [isList, setIsList] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [date, setDate] = useState(new Date());

  const isEn = () => {
    return language === "en";
  };

  const changeMonth = (type) => {
    if (type === "increase") {
      setDate(new DateObject(date).add(1, "month"));
    } else {
      setDate(new DateObject(date).subtract(1, "month"));
    }
    setPage(DEFAULT_PAGE);
  };

  const isPlayer = () => {
    return pathName.includes("player");
  };

  const openListPage = (page) => {
    if (!isList) {
      if (isPlayer()) {
        history.push(`/${language}/ongoing/${game}/player/on-going/list`);
      } else {
        history.push(`/tournaments/${game}/on-going/list`);
      }
    }
  };

  const openCalendarPage = (page) => {
    if (isList) {
      if (isPlayer()) {
        history.push(`/${language}/ongoing/${game}/player/on-going/calendar`);
      } else {
        history.push(`/tournaments/${game}/on-going/calendar`);
      }
    }
  };

  const viewYesNoOptions = (option) => {
    return t(`yes_no.${option.toLowerCase()}`);
  };

  const vrcText = () => {
    if (game === GAMES.LOL) {
      return t("on_going_tournaments.arabianLeague");
    } else if (game === GAMES.VALORANT) {
      return t("on_going_tournaments.VRC");
    } else if (game === GAMES.WILD_RIFT) {
      return "";
    }
  };

  const isVrcViewed = () => {
    return game === GAMES.LOL || game === GAMES.VALORANT;
  };

  useEffect(() => {

    if (pathName.includes("/on-going/list")) {
      setIsList(true);
    } else {
      setIsList(false);
    }

    setRegion(DEFAULT_REGION);
    setVrc(DEFAULT_VRC);
    setPage(DEFAULT_PAGE);
    setDate(new Date());
  }, [pathName, DEFAULT_REGION, DEFAULT_VRC]);

  const getGameName = () => {
    return games?.filter((g) => g.code === game)[0]?.name || "";
  };

  useEffect(() => {
    if (games.length > 0) {
      const gameId = games.filter((g) => g.code === game)[0]?.id;

      if (isList) {
        dispatch(
          Actions.fetchCustomTournaments(
            gameId,
            page,
            PAGE_SIZE,
            region.value,
            vrc.value
          )
        );
      } else {
        const month = new DateObject(date).format("M");
        const year = new DateObject(date).format("YYYY");

        dispatch(
          Actions.fetchCustomTournamentsCalendar(
            gameId,
            page,
            PAGE_SIZE,
            region.value,
            vrc.value,
            month,
            year
          )
        );
      }
    }
  }, [dispatch, page, region, vrc, isList, date, games, game]);

  const changePageNumber = useCallback(() => {
    if (isList && page < pageCount - 1) {
      setPage(page + 1);
    } else if (!isList && page < calendarPageCount - 1) {
      setPage(page + 1);
    }
  }, [page, pageCount, calendarPageCount, isList]);

  useEffect(() => {
    if (inBottom && !isLoading) {
      changePageNumber();
    }
  }, [changePageNumber, inBottom, isLoading]);

  return (
    <div className="on-going-tournaments">
      <Helmet>
        <title>{getGameName()} - Calendar</title>
      </Helmet>

      <div className={`bg ${game}`}>
        <div className="container">
          <h1>{t("on_going_tournaments.ongoing_tournaments")}</h1>
          <p className="subtext">{t("on_going_tournaments.subheading")}</p>
        </div>
      </div>
      <div className="container pt-5">
        <div className="d-flex flex-wrap justify-content-between pb-5">
          <div className="d-flex flex-wrap pages-buttons">
            <button
              className={`btn btn-primary custom-button me-3 mt-5 ${
                isList ? "" : "outline"
              }`}
              onClick={openListPage}
            >
              {t("on_going_tournaments.list")}
            </button>
            <button
              className={`btn btn-primary custom-button me-3 mt-5 ${
                isList ? "outline" : ""
              }`}
              onClick={openCalendarPage}
            >
              {t("on_going_tournaments.calendar")}
            </button>
          </div>

          <div className="d-flex flex-wrap">
            <div className="input-form-group form-group d-flex align-items-center mx-4 mt-5">
              <label htmlFor="region" className="field-form-label mx-2">
                {t("on_going_tournaments.region")}
              </label>
              <Select
                value={region}
                name="region"
                id="region"
                placeholder="Region"
                options={[
                  { id: "all", nameEn: "All", nameAr: "الكل" },
                  ...regions,
                ].map((region) => {
                  return {
                    value: region?.id,
                    label: isEn() ? region.nameEn : region.nameAr,
                  };
                })}
                onChange={(region) => {
                  setRegion(region);
                  setPage(DEFAULT_PAGE);
                }}
                className="un-bordered-select"
                classNamePrefix="select"
              />
            </div>

            {isVrcViewed() && (
              <div className="input-form-group form-group d-flex align-items-center mt-5">
                <label htmlFor="vrc" className="field-form-label mx-2">
                  {vrcText()}
                </label>
                <Select
                  value={vrc}
                  name="vrc"
                  id="vrc"
                  placeholder={vrcText()}
                  options={["all", ...Object.keys(YES_NO)].map((option) => {
                    return {
                      value: option === "all" ? "all" : YES_NO[option],
                      label: viewYesNoOptions(option),
                    };
                  })}
                  onChange={(vrc) => {
                    setVrc(vrc);
                    setPage(DEFAULT_PAGE);
                  }}
                  className="un-bordered-select"
                  classNamePrefix="select"
                />
              </div>
            )}
          </div>
        </div>
        {isList ? <List /> : <Calendar date={date} changeMonth={changeMonth} />}
        {isLoading && <Spinner />}
      </div>
      <div className="mt-5 pt-5">{/* <WantToWorkWithUs game={game} /> */}</div>
    </div>
  );
}

export default OnGoingTournaments;
