import { RegionActions } from "../actions/types";

const initialState = {
  loading: false,
  list: [],
};

const regionReducer = (state = initialState, action) => {
  switch (action.type) {
    case RegionActions.REGION_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case RegionActions.REGION_READY: {
      return {
        ...state,
        loading: false,
      };
    }
    case RegionActions.REGION_SAVE: {
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default regionReducer;
