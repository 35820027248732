require("dotenv").config();

export const BITS_IN_MEGA = 1048576;
export const MAX_PDF_FILE_SIZE = 10;
export const MAX_TOTAL_PDF_FILES_SIZE = 20;
export const MAX_IMAGE_FILE_SIZE = 50;
export const FILE_SIZE_ERROR_MESSAGE = `Max File Size Should be ${MAX_PDF_FILE_SIZE}MB`;
export const TOTAL_FILES_SIZE_ERROR_MESSAGE = `Max Total Files Size Should be ${MAX_TOTAL_PDF_FILES_SIZE}MB`;
export const TOTAL_FILES_AND_IMAGES_SIZE_ERROR_MESSAGE = `Max Total Files and Images Size Should be ${MAX_TOTAL_PDF_FILES_SIZE}MB`;
export const IMAGE_SIZE_ERROR_MESSAGE = `Max File Size Should be ${MAX_IMAGE_FILE_SIZE}MB`;

export const GAMES = {
  VALORANT: "valorant",
  LOL: "lol",
  WILD_RIFT: "wr",
};

export const YES_NO = {
  Yes: true,
  No: false,
};

export const TOURNAMENT_STEPS = {
  1: "Tournament Information Provided",
  2: "Submit Broadcast Assets",
  3: "Licence Agreement",
};

export const COUNTRIES_EXCEPT_MENA = [
  { nameAr: "أفغانستان", name: "Afghanistan", code: "AF" },
  { nameAr: "ألبانيا", name: "Albania", code: "AL" },
  { nameAr: "أندورا", name: "Andorra", code: "AD" },
  { nameAr: "أنغولا", name: "Angola", code: "AO" },
  { nameAr: "أنتيغوا وبربودا", name: "Antigua and Barbuda", code: "AG" },
  { nameAr: "", name: "Argentina", code: "AR" },
  { nameAr: "", name: "Armenia", code: "AM" },
  { nameAr: "", name: "Australia", code: "AU" },
  { nameAr: "", name: "Austria", code: "AT" },
  { nameAr: "", name: "Azerbaijan", code: "AZ" },
  { nameAr: "", name: "Bahamas", code: "BS" },
  { nameAr: "", name: "Bangladesh", code: "BD" },
  { nameAr: "", name: "Barbados", code: "BB" },
  { nameAr: "", name: "Belarus", code: "BY" },
  { nameAr: "", name: "Belgium", code: "BE" },
  { nameAr: "", name: "Belize", code: "BZ" },
  { nameAr: "", name: "Benin", code: "BJ" },
  { nameAr: "", name: "Bhutan", code: "BT" },
  { nameAr: "", name: "Bolivia", code: "BO" },
  { nameAr: "", name: "Bosnia and Herzegovina", code: "BA" },
  { nameAr: "", name: "Botswana", code: "BW" },
  { nameAr: "", name: "Brazil", code: "BR" },
  { nameAr: "", name: "Brunei", code: "BN" },
  { nameAr: "", name: "Bulgaria", code: "BG" },
  { nameAr: "", name: "Burkina Faso", code: "BF" },
  { nameAr: "", name: "Burundi", code: "BI" },
  { nameAr: "", name: "	Côte d'Ivoire", code: "CI" },
  { nameAr: "", name: "Cabo Verde", code: "CV" },
  { nameAr: "", name: "Cambodia", code: "KH" },
  { nameAr: "", name: "Cameroon", code: "CM" },
  { nameAr: "", name: "Canada", code: "CA" },
  { nameAr: "", name: "Central African Republic", code: "CF" },
  { nameAr: "", name: "Chad", code: "TD" },
  { nameAr: "", name: "Chile", code: "CL" },
  { nameAr: "", name: "China", code: "CN" },
  { nameAr: "", name: "Colombia", code: "CO" },
  { nameAr: "", name: "Comoros", code: "KM" },
  { nameAr: "", name: "Congo (Congo-Brazzaville)", code: "CG" },
  { nameAr: "", name: "Costa Rica", code: "CR" },
  { nameAr: "", name: "Croatia", code: "HR" },
  { nameAr: "", name: "Cuba", code: "CU" },
  { nameAr: "", name: "Cyprus", code: "CY" },
  { nameAr: "", name: "Czechia (Czech Republic)", code: "CZ" },
  { nameAr: "", name: "Democratic Republic of the Congo", code: "CD" },
  { nameAr: "", name: "Denmark", code: "DK" },
  { nameAr: "", name: "Djibouti", code: "DJ" },
  { nameAr: "", name: "Dominica", code: "DM" },
  { nameAr: "", name: "Dominican Republic", code: "DO" },
  { nameAr: "", name: "Ecuador", code: "EC" },
  { nameAr: "", name: "El Salvador", code: "SV" },
  { nameAr: "", name: "Equatorial Guinea", code: "GQ" },
  { nameAr: "", name: "Eritrea", code: "ER" },
  { nameAr: "", name: "Estonia", code: "EE" },
  { nameAr: "", name: "Eswatini (formerly Swaziland)", code: "SZ" },
  { nameAr: "", name: "Ethiopia", code: "ET" },
  { nameAr: "", name: "Fiji", code: "FJ" },
  { nameAr: "", name: "Finland", code: "FI" },
  { nameAr: "", name: "France", code: "FR" },
  { nameAr: "", name: "Gabon", code: "GA" },
  { nameAr: "", name: "Gambia", code: "GM" },
  { nameAr: "", name: "Georgia", code: "GE" },
  { nameAr: "", name: "Germany", code: "DE" },
  { nameAr: "", name: "Ghana", code: "GH" },
  { nameAr: "", name: "Greece", code: "GR" },
  { nameAr: "", name: "Grenada", code: "GD" },
  { nameAr: "", name: "Guatemala", code: "GT" },
  { nameAr: "", name: "Guinea", code: "GN" },
  { nameAr: "", name: "Guinea-Bissau", code: "GW" },
  { nameAr: "", name: "Guyana", code: "GY" },
  { nameAr: "", name: "Haiti", code: "HT" },
  { nameAr: "", name: "Holy See", code: "VA" },
  { nameAr: "", name: "Honduras", code: "HN" },
  { nameAr: "", name: "Hungary", code: "HU" },
  { nameAr: "", name: "Iceland", code: "IS" },
  { nameAr: "", name: "India", code: "IN" },
  { nameAr: "", name: "Indonesia", code: "ID" },
  { nameAr: "", name: "Iran", code: "IR" },
  { nameAr: "", name: "Ireland", code: "IE" },
  { nameAr: "", name: "Israel", code: "IL" },
  { nameAr: "", name: "Italy", code: "IT" },
  { nameAr: "", name: "Jamaica", code: "JM" },
  { nameAr: "", name: "Japan", code: "JP" },
  { nameAr: "", name: "Kazakhstan", code: "KZ" },
  { nameAr: "", name: "Kenya", code: "KE" },
  { nameAr: "", name: "Kiribati", code: "KI" },
  { nameAr: "", name: "Kyrgyzstan", code: "KG" },
  { nameAr: "", name: "Lao People's Democratic Republic", code: "LA" },
  { nameAr: "", name: "Latvia", code: "LV" },
  { nameAr: "", name: "Lesotho", code: "LS" },
  { nameAr: "", name: "Liberia", code: "LR" },
  { nameAr: "", name: "Liechtenstein", code: "LI" },
  { nameAr: "", name: "Lithuania", code: "LT" },
  { nameAr: "", name: "Luxembourg", code: "LU" },
  { nameAr: "", name: "Madagascar", code: "MG" },
  { nameAr: "", name: "Malawi", code: "MW" },
  { nameAr: "", name: "Malaysia", code: "MY" },
  { nameAr: "", name: "Maldives", code: "MV" },
  { nameAr: "", name: "Mali", code: "ML" },
  { nameAr: "", name: "Malta", code: "MT" },
  { nameAr: "", name: "Marshall Islands", code: "MH" },
  { nameAr: "", name: "Mauritania", code: "MR" },
  { nameAr: "", name: "Mauritius", code: "MU" },
  { nameAr: "", name: "Mexico", code: "MX" },
  { nameAr: "", name: "Micronesia (Federated States of)", code: "FM" },
  { nameAr: "", name: "Moldova (Republic of)", code: "MD" },
  { nameAr: "", name: "Monaco", code: "MC" },
  { nameAr: "", name: "Mongolia", code: "MN" },
  { nameAr: "", name: "Montenegro", code: "ME" },
  { nameAr: "", name: "Mozambique", code: "MZ" },
  { nameAr: "", name: "Myanmar", code: "MM" },
  { nameAr: "", name: "Namibia", code: "NA" },
  { nameAr: "", name: "Nauru", code: "NR" },
  { nameAr: "", name: "Nepal", code: "NP" },
  { nameAr: "", name: "Netherlands", code: "NL" },
  { nameAr: "", name: "New Zealand", code: "NZ" },
  { nameAr: "", name: "Nicaragua", code: "NI" },
  { nameAr: "", name: "Niger", code: "NE" },
  { nameAr: "", name: "Nigeria", code: "NG" },
  {
    nameAr: "",
    name: "North Korea (Democratic People's Republic of)",
    code: "KP",
  },
  { nameAr: "", name: "North Macedonia", code: "MK" },
  { nameAr: "", name: "Norway", code: "NO" },
  { nameAr: "", name: "Pakistan", code: "PK" },
  { nameAr: "", name: "Palau", code: "PW" },
  { nameAr: "", name: "Panama", code: "PA" },
  { nameAr: "", name: "Papua New Guinea", code: "PG" },
  { nameAr: "", name: "Paraguay", code: "PY" },
  { nameAr: "", name: "Peru", code: "PE" },
  { nameAr: "", name: "Philippines", code: "PH" },
  { nameAr: "", name: "Poland", code: "PL" },
  { nameAr: "", name: "Portugal", code: "PT" },
  { nameAr: "", name: "Romania", code: "RO" },
  { nameAr: "", name: "Russia", code: "RU" },
  { nameAr: "", name: "Rwanda", code: "RW" },
  { nameAr: "", name: "Saint Kitts and Nevis", code: "KN" },
  { nameAr: "", name: "Saint Lucia", code: "LC" },
  { nameAr: "", name: "Saint Vincent and the Grenadines", code: "VC" },
  { nameAr: "", name: "Samoa", code: "WS" },
  { nameAr: "", name: "San Marino", code: "SM" },
  { nameAr: "", name: "Sao Tome and Principe", code: "ST" },
  { nameAr: "", name: "Senegal", code: "SN" },
  { nameAr: "", name: "Serbia", code: "RS" },
  { nameAr: "", name: "Seychelles", code: "SC" },
  { nameAr: "", name: "Sierra Leone", code: "SL" },
  { nameAr: "", name: "Singapore", code: "SG" },
  { nameAr: "", name: "Slovakia", code: "SK" },
  { nameAr: "", name: "Slovenia", code: "SI" },
  { nameAr: "", name: "Solomon Islands", code: "SB" },
  { nameAr: "", name: "Somalia", code: "SO" },
  { nameAr: "", name: "South Africa", code: "ZA" },
  { nameAr: "", name: "South Korea", code: "KR" },
  { nameAr: "", name: "South Sudan", code: "SS" },
  { nameAr: "", name: "Spain", code: "ES" },
  { nameAr: "", name: "Sri Lanka", code: "LK" },
  { nameAr: "", name: "Sudan", code: "SD" },
  { nameAr: "", name: "Suriname", code: "SR" },
  { nameAr: "", name: "Sweden", code: "SE" },
  { nameAr: "", name: "Switzerland", code: "CH" },
  { nameAr: "", name: "Syria", code: "SY" },
  { nameAr: "", name: "Taiwan", code: "TW" },
  { nameAr: "", name: "Tajikistan", code: "TJ" },
  { nameAr: "", name: "Tanzania", code: "TZ" },
  { nameAr: "", name: "Thailand", code: "TH" },
  { nameAr: "", name: "Timor-Leste", code: "TL" },
  { nameAr: "", name: "Togo", code: "TG" },
  { nameAr: "", name: "Tonga", code: "TO" },
  { nameAr: "", name: "Trinidad and Tobago", code: "TT" },
  { nameAr: "", name: "Turkey", code: "TR" },
  { nameAr: "", name: "Turkmenistan", code: "TM" },
  { nameAr: "", name: "Tuvalu", code: "TV" },
  { nameAr: "", name: "Uganda", code: "UG" },
  { nameAr: "", name: "Ukraine", code: "UA" },
  { nameAr: "", name: "United Kingdom", code: "GB" },
  { nameAr: "", name: "United States of America", code: "US" },
  { nameAr: "", name: "Uruguay", code: "UY" },
  { nameAr: "", name: "Uzbekistan", code: "UZ" },
  { nameAr: "", name: "Venezuela", code: "VE" },
  { nameAr: "", name: "Vietnam", code: "VN" },
  { nameAr: "", name: "Yemen", code: "YE" },
  { nameAr: "", name: "Zambia", code: "ZM" },
  { nameAr: "", name: "Zimbabwe", code: "ZW" },
];

export const GOOGLE_MAP_API_KEY = "AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc";
export const MAP_CONSTANT = {
  center: { lat: 25.035344762834526, lng: 55.63260348802649 },
  zoom: 10,
};

export const RECAPTCHA_SITE_KEY = "";
export const DEFAULT_LANG = "ar";
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const DAY_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const TOURNAMENT_STATUS = [
  "",
  "Submit",
  "Awaiting Approval",
  "Approved",
  "Approved with Note",
  "Rejected with Note",
];
export const APPLICANT_STATUS = [
  "",
  "Pending",
  "In Review",
  "Approved",
  "Rejected",
];
export const STATUS_COLOR = {
  Submit: "transparent",
  "Awaiting Approval": "#ff9e00",
  Approved: "#8cd60b",
  "Approved with Note": "#8cd60b",
  "Rejected with Note": "#eb0029",
};

export const TOURNAMENT_LIST_SIZE = 12;
