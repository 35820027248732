import axios from "axios";
import { saveToken, saveRefreshToken } from "./saveToken";
import store from "../store";
import * as Actions from "../store/actions";
import { getRefreshToken, getAccessToken } from "./getTokenFromCookie";

export const refreshToken = async () => {
  const token = getRefreshToken();

  return axios
    .post(
      `/api/auth/token/refresh`,
      { refreshToken: token },
      {
        baseURL: process.env.REACT_APP_AUTH_BASE_URL,
        headers: {
          Authorization: getAccessToken(),
        },
      }
    )
    .then((response) => {
      const { accessToken, refreshToken } = response.data;

      if (accessToken) {
        saveToken(accessToken);
        saveRefreshToken(refreshToken);
      }

      return accessToken;
    })
    .catch((error) => {
      store.dispatch(Actions.readyUserData());
      if (token) {
        store.dispatch(Actions.Logout());
      }
      throw error;
    });
};
