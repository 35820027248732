import React, { forwardRef } from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-multi-date-picker";

import "../styles/DatePicker.scss";

const CustomDatePicker = forwardRef(
  (
    {
      rules,
      control,
      name,
      label,
      placeholder = "DD/MM/YYYY",

      topLabel = false,
      labelCols = 3,
      inputCols = 9,
      hiddenLabel = false,
      labelClass = "",

      format = "DD/MM/YYYY",
      multiple = false,
      range = false,
      minDate = null,
      maxDate = null,
      disabledDates = [],
    },
    ref
  ) => (
    <div
      className={`date-picker-form-group form-group row ${
        topLabel ? "top-label-container" : ""
      }`}
    >
      {!hiddenLabel && (
        <label
          htmlFor={name}
          className={`col-12 col-form-label ${
            topLabel ? "top-label col-lg-12" : `col-lg-${labelCols}`
          } ${labelClass}`}
        >
          {label}
        </label>
      )}
      <div
        className={`col-12 ${
          hiddenLabel ? "col-sm-12" : `col-lg-${inputCols}`
        }`}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({
            field: { onChange, name, value },
            fieldState: { invalid, isDirty }, //optional
            formState: { errors },
          }) => (
            <>
              <DatePicker
                placeholder={placeholder}
                value={value || ""}
                onChange={onChange}
                format={format}
                inputClass={errors[name]?.message && "validation-error"}
                multiple={multiple}
                range={range}
                rangeHover={range}
                minDate={minDate}
                maxDate={maxDate}
                editable={false}
                mapDays={({ date }) => {
                  let isDisabled = disabledDates.includes(
                    date.format("DD/MM/YYYY")
                  );

                  if (isDisabled) {
                    return {
                      disabled: true,
                      style: { color: "#eb0029" },
                    };
                  }
                }}
              />
              {errors[name]?.message && (
                <p className="input-error">* {errors[name]?.message}</p>
              )}
            </>
          )}
        />
      </div>
    </div>
  )
);

export default CustomDatePicker;
