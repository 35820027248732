import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DateObject } from "react-multi-date-picker";
import { useTranslation } from "react-i18next";

import OnGoingTournamentsCalendarHeading from "../../components/OnGoingTournaments/Calendar/Heading";
import OnGoingTournamentsCalendarTournament from "../../components/OnGoingTournaments/Calendar/Tournament";

import "../../styles/OnGoingTournaments/Calendar.scss";
import i18n from "../../i18n";
import arabic_ar from "react-date-object/locales/arabic_ar";

function OnGoingTournamentsCalendar({ date, changeMonth, isHome = false }) {
  const language = i18n.language;

  const { t } = useTranslation("ProjectTranslation");

  const [monthDays, setMonthDays] = useState([]);
  const [broadcastDays, setBroadcastDays] = useState([]);

  const tournaments = useSelector(
    ({ tournaments }) => tournaments.onGoingTournamentsCalendar
  );

  const isAr = useCallback(() => {
    return language === "ar";
  }, [language]);

  const getShortMonthName = () => {
    const theNewDate = new DateObject(date);
    if (isAr()) {
      theNewDate.setLocale({
        ...arabic_ar,
        months: [
          ["يناير", "يناير"],
          ["فبراير", "فبراير"],
          ["مارس", "مارس"],
          ["أبريل", "أبريل"],
          ["مايو", "مايو"],
          ["يونيو", "يونيو"],
          ["يوليو", "يوليو"],
          ["أغسطس", "أغسطس"],
          ["سبتمبر", "سبتمبر"],
          ["أكتوبر", "أكتوبر"],
          ["نوفمبر", "نوفمبر"],
          ["ديسمبر", "ديسمبر"],
        ],
      });
    }

    return theNewDate.format("MMMM");
  };

  const getFullYear = useCallback(() => {
    return new DateObject(date).format("YYYY");
  }, [date]);

  const getDayName = useCallback(
    (day) => {
      const month = new DateObject(date).format("M");
      const year = getFullYear();

      const theNewDate = new DateObject(new Date(year, month - 1, day));

      if (isAr()) {
        theNewDate.setLocale(arabic_ar);
      }

      if (isAr()) {
        const dayMappings = {
          السّبت: "السبت",
          الإثنينِ: "الإثنين",
        };

        const formattedDay = theNewDate.format("dddd");

        return dayMappings[formattedDay] || formattedDay;
      } else {
        return theNewDate.format("dddd");
      }
    },
    [date, getFullYear, isAr]
  );

  const getMonthDays = useCallback(() => {
    const month = new DateObject(date).format("M");
    const year = getFullYear();
    const daysInMonth = new Date(year, month, 0).getDate();
    const currentMonthDays = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const dayName = getDayName(day);

      currentMonthDays.push({
        day,
        dayName: isAr() ? dayName : dayName[0],
      });
    }

    setMonthDays(currentMonthDays);
  }, [date, getDayName, getFullYear, isAr]);

  useEffect(() => {
    getMonthDays();
  }, [getMonthDays]);

  const getTournamentsBroadcastDays = useCallback(() => {
    const tempBroadcastDays = [];
    tournaments.forEach((tournament) => {
      tournament.broadcast_days.forEach((day) => {
        tempBroadcastDays.push(
          new DateObject(day, "DD/MM/YYYY").format("DD/MM/YYYY")
        );
      });
    });

    setBroadcastDays(tempBroadcastDays);
  }, [tournaments]);

  useEffect(() => {
    getTournamentsBroadcastDays();
  }, [getTournamentsBroadcastDays]);

  const isBroadcastDay = (day) => {
    const tempDate = new DateObject(date);
    tempDate.setDay(day);

    return broadcastDays.includes(tempDate.format("DD/MM/YYYY"));
  };

  return (
    <div className="tournaments-calendar">
      <div
        className={`d-flex flex-wrap colors-meaning ${
          isHome && "align-items-baseline"
        }`}
      >
        {isHome && (
          <div className="calendar-home-title mx-4">
            <h2 className="mb-0">{t("calendar.heading")}</h2>
          </div>
        )}
        <div
          className={`d-flex align-items-center ${isAr() ? "ms-5" : "me-5"}`}
        >
          <div className="duration-color mx-2"></div> {t("calendar.duration")}
        </div>
        <div className="d-flex align-items-center">
          <div className="broadcast-days-color mx-2"></div>
          {t("calendar.broadcast_days")}
        </div>
      </div>

      <div className="tournaments-calendar-container">
        <OnGoingTournamentsCalendarHeading
          changeMonth={changeMonth}
          getShortMonthName={getShortMonthName}
          getFullYear={getFullYear}
          monthDays={monthDays}
          isBroadcastDay={isBroadcastDay}
        />

        <section className="tournaments">
          {tournaments.map((tournament) => (
            <OnGoingTournamentsCalendarTournament
              key={tournament.id}
              monthDays={monthDays}
              tournament={tournament}
              date={date}
            />
          ))}
        </section>
      </div>
    </div>
  );
}

export default OnGoingTournamentsCalendar;
