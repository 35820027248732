import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAccessToken } from "../utils/getTokenFromCookie";

export default function useRequireAuth() {
  const history = useHistory();

  const user = useSelector(({ me }) => me.user);
  const userDataLoading = useSelector(({ me }) => me.userDataLoading);
  const isFetchingStartedBefore = useSelector(
    ({ me }) => me.isFetchingStartedBefore
  );
  const userToken = getAccessToken();

  useEffect(() => {
    if (
      !userToken ||
      (isFetchingStartedBefore && !userDataLoading && !user?.id)
    ) {
      history.push("/login");
    }
  }, [userToken, isFetchingStartedBefore, userDataLoading, user, history]);

  return true;
}
