import React, { useState } from "react";
import { useParams } from "react-router";
import LoginRegisterModal from "../LoginRegisterModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

function PlayerHomeBanner() {
  const { t } = useTranslation("ProjectTranslation");

  const { game } = useParams();

  const data = require("../../staticContent.json");
  const bannerImages = data.bannerImages;
  const onGoingCount = useSelector(({ common }) => common.onGoingCount);

  const [isLoginRegisterModalOpen, setIsLoginRegisterModalOpen] =
    useState(false);

  const viewGameBanner = () => {
    return bannerImages[game];
  };

  return (
    <div className={`bg ${game}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="heading">{t("playerHome.banner.heading")}</h1>
          </div>

          <div className="col-lg-6 col-12">
            <p className="subtext">{t("playerHome.banner.subheading")}</p>
            <div className="d-flex align-items-center ongoing-count-container inHeader">
              <img
                className="cup-icon"
                src="assets/img/shape-684.png"
                alt="cup icon"
              />
              <p className="ongoing-count red mx-2 mb-0">{onGoingCount}</p>
              <p className="ongoing-text offWhite mb-0">
                {t("playerHome.banner.ongoing_tournaments")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`game-banner-container col-lg-6 col-12 ${
              i18n.language === "en" ? "offset-lg-6" : i18n.language
            } p-0`}
          >
            <img
              className={`game-banner ${i18n.language}`}
              src={viewGameBanner()}
              alt="Game banner"
            />
          </div>
        </div>
      </div>

      <LoginRegisterModal
        isOpened={isLoginRegisterModalOpen}
        setIsOpened={setIsLoginRegisterModalOpen}
      />
    </div>
  );
}

export default PlayerHomeBanner;
