import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import * as Actions from "../store/actions";

import "../styles/Alerts.scss";

function SuccessAlert() {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const isSuccess = useSelector(({ common }) => common.isSuccess);
  const successMessage = useSelector(({ common }) => common.successMessage);

  useEffect(() => {
    setShow(isSuccess);

    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          Actions.saveSuccessMessage({ isSuccess: false, successMessage: "" })
        );
      }, 5000);
    }
  }, [dispatch, isSuccess]);

  return (
    <>
      {show && (
        <div className="custom-alert toast-container position-fixed start-50 translate-middle-x">
          <div className="toast success show w-100">
            <div className="toast-body">
              <p className="message mb-0">{successMessage}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SuccessAlert;
