import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DateObject } from "react-multi-date-picker";
import { useTranslation } from "react-i18next";

import * as Actions from "../../store/actions";
import PlayerHomeBanner from "../../components/PlayerHome/Banner";
import List from "../OnGoingTournaments/List";
import Calendar from "../OnGoingTournaments/Calendar";
import i18n from "../../i18n";

import "../../styles/Home/index.scss";
import { Helmet } from "react-helmet";

const PAGE_SIZE = 9;

function PlayerHomePage() {
  const { t } = useTranslation("ProjectTranslation");

  const { game } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [date, setDate] = useState(new Date());

  const games = useSelector(({ common }) => common.games);

  const openOngoingPage = (page) => {
    const lang = i18n.language;
    history.push(`/${lang}/ongoing/${game}/player/on-going/${page}`);
  };

  const changeMonth = (type) => {
    if (type === "increase") {
      setDate(new DateObject(date).add(1, "month"));
    } else {
      setDate(new DateObject(date).subtract(1, "month"));
    }
  };

  const getGameName = () => {
    return games?.filter((g) => g.code === game)[0]?.name || "";
  };

  useEffect(() => {
    if (games.length > 0) {
      const gameId = games.filter((g) => g.code === game)[0]?.id;

      dispatch(
        Actions.fetchCustomTournaments(gameId, 0, PAGE_SIZE, "all", "all")
      );

      const month = new DateObject(date).format("M");
      const year = new DateObject(date).format("YYYY");

      dispatch(
        Actions.fetchCustomTournamentsCalendar(
          gameId,
          0,
          PAGE_SIZE,
          "all",
          "all",
          month,
          year
        )
      );
    }
  }, [dispatch, date, games, game]);

  return (
    <div className="home-page">
      <Helmet>
        <title>{getGameName()} - Home</title>
      </Helmet>

      <PlayerHomeBanner />

      <div className="container">
        <section className="ongoing-list mt-5 pt-5">
          <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
            <h2 className="heading-text mb-0">
              {t("playerHome.ongoing_tournaments")}
            </h2>

            <button
              type="button"
              className="btn btn-primary custom-button outline mt-3 mt-md-0"
              onClick={() => openOngoingPage("list")}
            >
              {t("playerHome.view_all")}
            </button>
          </div>
          <List isHome />
        </section>

        <section className="mt-5 pt-5">
          <Calendar date={date} changeMonth={changeMonth} isHome />

          <div className="d-flex justify-content-center mt-5">
            <button
              type="button"
              className="btn btn-primary custom-button outline mt-3 mt-md-0"
              onClick={() => openOngoingPage("calendar")}
            >
              {t("playerHome.view_all")}
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PlayerHomePage;
