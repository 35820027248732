import { useState, useEffect, useCallback } from "react";

export default function useWindowDimensions(bottomScrollOffset = 200) {
  const [inBottom, setInBottom] = useState(false);

  const handleScroll = useCallback(async () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight - bottomScrollOffset;

    if (bottom) {
      if (!inBottom) {
        setInBottom(true);
      }
    } else if (inBottom) {
      setInBottom(false);
    }
  }, [inBottom, bottomScrollOffset]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return inBottom;
}
