import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { formValidations } from "../../utils/formValidations";
import Input from "../../components/Input";
import AcceptTermsAndConditionsCheckbox from "../../components/AcceptTermsAndConditionsCheckbox";
import PhoneInput from "../../components/PhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Actions from "../../store/actions";

import "../../styles/RegisterPage.scss";
import { Helmet } from "react-helmet";

function RegisterPage() {
  const initialValues = {
    userName: "",
    password: "",
    contactPerson: "",
    companyName: "",
    phone: "971",
    address: "",
    email: "",
    website: "",
    registerAcceptanceText: false,
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { ...initialValues } });

  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(Actions.Register(data)).then(() => {
      reset({ ...initialValues });
      history.push("/login");
    });
  };

  const { t } = useTranslation("ProjectTranslation");

  return (
    <div className="register-page">
      <Helmet>
        <title>Create Account</title>
      </Helmet>

      <div className="header">
        <div className="container text-container">
          <h1>{t("register.header")}</h1>
          <p>{t("register.subheading")}</p>
        </div>
      </div>
      <div className="formContainer">
        <div className="container">
          <h2 className="heading">{t("register.organizer_information")}</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6 col-12 offset-lg-1">
                <Input
                  label={t("form.username")}
                  placeholder={t("form.username")}
                  defaultValue=""
                  {...register("userName", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(3),
                    maxLength: formValidations.maxLength(15),
                    pattern: formValidations.validateLetters(),
                  })}
                  errors={errors.userName?.message}
                  labelCols={4}
                  inputCols={8}
                />
              </div>

              <div className="col-lg-5 col-12">
                <Input
                  type="password"
                  label={t("form.password")}
                  placeholder={t("form.passwordPlaceholder")}
                  defaultValue=""
                  {...register("password", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(6),
                    pattern: formValidations.validatePassword(),
                  })}
                  errors={errors.password?.message}
                />
              </div>

              <div className="col-12 mt-3">
                <Input
                  label={t("form.contactPerson")}
                  placeholder={t("form.contactPersonPlaceholder")}
                  defaultValue=""
                  {...register("contactPerson", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(6),
                    pattern: formValidations.validateLettersWithSpaces(),
                  })}
                  errors={errors.contactPerson?.message}
                />
              </div>

              <div className="col-12 mt-3">
                <Input
                  label={t("form.companyName")}
                  placeholder={t("form.companyName")}
                  defaultValue=""
                  {...register("companyName", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(3),
                    maxLength: formValidations.maxLength(255),
                    pattern:
                      formValidations.validateLettersAndNumbersAndSpaces(),
                  })}
                  errors={errors.companyName?.message}
                />
              </div>

              <div className="col-12 mt-3">
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: formValidations.required(),
                    validate: (value) =>
                      isValidPhoneNumber(`+${value}`) ||
                      "Phone number is not valid",
                  }}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      label={t("form.phoneLabel")}
                      errors={errors.phone?.message}
                    />
                  )}
                />
              </div>

              <div className="col-12 mt-3">
                <Input
                  label={t("form.address")}
                  placeholder={t("form.addressPlaceholder")}
                  defaultValue=""
                  {...register("address", {
                    required: formValidations.address(6),
                    minLength: formValidations.minLength(6),
                    maxLength: formValidations.maxLength(255),
                  })}
                  errors={errors.address?.message}
                />
              </div>

              <div className="col-12 mt-3">
                <Input
                  type="email"
                  label={t("form.email")}
                  placeholder={t("form.emailPlaceholder")}
                  defaultValue=""
                  {...register("email", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(3),
                    pattern: formValidations.validateEmail(),
                  })}
                  errors={errors.email?.message}
                />
              </div>

              <div className="col-12 mt-3">
                <Input
                  label={t("form.website")}
                  placeholder={t("form.websitePlaceholder")}
                  defaultValue=""
                  {...register("website", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(3),
                    pattern: formValidations.validateURL(),
                  })}
                  errors={errors.website?.message}
                />
              </div>

              <div className="col-lg-9 col-12 mt-5 offset-lg-3">
                <Controller
                  name="registerAcceptanceText"
                  control={control}
                  rules={{ required: formValidations.required() }}
                  render={({ field }) => (
                    <AcceptTermsAndConditionsCheckbox
                      {...field}
                      errors={errors.registerAcceptanceText?.message}
                    />
                  )}
                />
              </div>

              <div className="col-lg-9 col-12 mt-4 offset-lg-3">
                <button
                  type="submit"
                  className="btn btn-primary custom-button full-width"
                >
                  {t("form.createAccount")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
