import React from "react";
import { useSelector } from "react-redux";

import "../styles/OurSupport/index.scss";
import { Helmet } from "react-helmet";

function OurSupport() {
  const supportOptions = useSelector(
    ({ tournaments }) => tournaments.support_options
  );

  return (
    <div className="our-support-page">
      <Helmet>
        <title>Publishing Support</title>
      </Helmet>

      <div className="bg">
        <div className="container">
          <h1>Publishing Support Services</h1>
          <p className="subtext">
            As a Riot Games partner, you have the privilege of accessing up to
            three comprehensive publishing support services offered by our team.
          </p>
        </div>
      </div>
      <div className="container our-support-container">
        <div className="row">
          <h2 className="col-12 section-title">what do you get</h2>

          <div className="col-12 col-lg-6 offset-lg-6 subtitle-container">
            <p className="m-0 heading">Publishing Support</p>
            <p className="subheading">
              From communications, content, brand and competitive experience
              services, you will gain access to a range of publishing support
              services that are designed to enhance your tournament and elevate
              your overall experience. Upon applying, you can select up to three
              services which are subject to approval by Riot Games depending on
              availability, partnership status and workload.
            </p>
          </div>
        </div>

        <div className="row support-options">
          <div className="col-lg-4 col-md-6 col-12 mt-2">
            <ul className="mb-0">
              {supportOptions?.map((option, index) => (
                <li className="option mt-0 mb-1" key={`${option}_${index}`}>
                  {option.title}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-8 col-md-6 col-12">
            <img
              src="assets/img/our-support-game-screenshot.png"
              alt="game screenshot"
              className="game-screenshot mt-4 mt-lg-0"
            />
          </div>
        </div>

        <div className="row how-it-works">
          <h2 className="col-12 section-title">how it works</h2>
          <div className="col-12 col-lg-4 HIW-card">
            <div className="HIW-card-content">
              <div className="icon-container">
                <img src="assets/img/our-support-1.png" alt="icon" />
              </div>
              <div className="text-details">
                <h3 className="title">step 1</h3>
                <p className="description">
                  Choose the support options from the list in form, when you
                  apply for the tournament.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 HIW-card">
            <div className="HIW-card-content">
              <div className="icon-container">
                <img src="assets/img/our-support-2.png" alt="icon" />
              </div>
              <div className="text-details">
                <h3 className="title">step 2</h3>
                <p className="description">
                  You will get confirmation from our team about the requested
                  support.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 HIW-card">
            <div className="HIW-card-content">
              <div className="icon-container">
                <img src="assets/img/our-support-3.png" alt="icon" />
              </div>
              <div className="text-details">
                <h3 className="title">step 3</h3>
                <p className="description">
                  Implementation of the support provided from the Riot Games
                  team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurSupport;
