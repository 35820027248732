import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { formValidations } from "../../utils/formValidations";
import Input from "../Input";
import * as Actions from "../../store/actions";

function EditUserPassword() {
  const initialValues = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: { ...initialValues } });

  const { t } = useTranslation("ProjectTranslation");
  const dispatch = useDispatch();

  const newPassword = watch("newPassword");

  const onSubmit = (data) => {
    dispatch(Actions.UpdateUserPassword(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-5 pt-5">
      <div className="row">
        <div className="col-12">
          <Input
            type="password"
            label={t("edit_profile_data.form.currentPassword")}
            placeholder={t("edit_profile_data.form.currentPassword")}
            defaultValue=""
            {...register("password", {
              required: formValidations.required(),
              minLength: formValidations.minLength(6),
              pattern: formValidations.validatePassword(),
            })}
            errors={errors.password?.message}
          />
        </div>

        <div className="col-12 mt-2">
          <Input
            type="password"
            label={t("edit_profile_data.form.newPassword")}
            labelClass="pb-0"
            placeholder={t("edit_profile_data.form.newPassword")}
            defaultValue=""
            {...register("newPassword", {
              required: formValidations.required(),
              minLength: formValidations.minLength(6),
              pattern: formValidations.validatePassword(),
            })}
            errors={errors.newPassword?.message}
          />
        </div>

        <div className="col-12 mt-2">
          <Input
            type="password"
            label={t("form.confirm_password")}
            placeholder={t("form.confirm_password")}
            defaultValue=""
            {...register("confirmPassword", {
              required: formValidations.required(),
              validate: (value) =>
                formValidations.validateConfirmPassword(newPassword, value),
            })}
            errors={errors.confirmPassword?.message}
          />
        </div>

        <div className="col-lg-9 col-12 mt-4 offset-lg-3">
          <button
            type="submit"
            className="btn btn-primary custom-button full-width"
          >
            {t("edit_profile_data.form.update_password")}
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditUserPassword;
