import { combineReducers } from "redux";
import languageReducer from "./i18n.reducer";
import commonReducer from "./common.reducer";
import mapReducer from "./map.reducer";
import faqReducer from "./faq.reducer";
import staticReducer from "./static.reducer";
import tournamentsReducer from "./tournament.reducer";
import countryReducer from "./country.reducer";
import meReducer from "./me.reducer";
import regionReducer from "./region.reducer";

const createReducer = (asyncReducers) =>
  combineReducers({
    common: commonReducer,
    language: languageReducer,
    map: mapReducer,
    faq: faqReducer,
    static: staticReducer,
    tournaments: tournamentsReducer,
    countries: countryReducer,
    regions: regionReducer,
    me: meReducer,
    ...asyncReducers,
  });

export default createReducer;
