import { axiosService as API } from "../../services/axiosInterceptors";
import {
  getAccessToken,
  getRefreshToken,
} from "../../utils/getTokenFromCookie";
import { saveToken, saveRefreshToken } from "../../utils/saveToken";
import { fetchUserData, saveUserData } from "./me.action";
import { resetTokens } from "../../utils/resetTokens";
import { saveSuccessMessage } from "./common.action";

export const Register = (form) => {
  return async (dispatch) => {
    return API.post(
      "/api/user/create/website",
      {
        ...form,
        regionIds: [0],
        fullName: form.userName,
        userRole: "ORGANIZER",
      },
      {
        baseURL: process.env.REACT_APP_BASE_URL,
      }
    );
  };
};

export const Login = (form) => {
  return async (dispatch) => {
    return API.post("/api/auth/signin", form, {
      baseURL: process.env.REACT_APP_AUTH_BASE_URL,
      withCredentials: true,
    }).then(async (response) => {
      const accessToken = response.data.accessToken;

      saveToken(accessToken);
      saveRefreshToken(response.data.refreshToken);
      await dispatch(fetchUserData());

      return response.data;
    });
  };
};

export const ForgotPassword = (email) => {
  return async (dispatch) => {
    return API.post(
      "/api/auth/resetPasswordFE",
      { email, appId: 1 },
      {
        baseURL: process.env.REACT_APP_AUTH_BASE_URL,
      }
    ).then((response) => {
      dispatch(
        saveSuccessMessage({
          isSuccess: true,
          successMessage: "Reset Link has been sent to your email",
        })
      );
      return response;
    });
  };
};

export const ResetPassword = (form) => {
  return async (dispatch) => {
    return API.post("/api/auth/changePassword", form, {
      baseURL: process.env.REACT_APP_AUTH_BASE_URL,
    });
  };
};

export const Logout = () => {
  return async (dispatch) => {
    API.post(
      `/api/auth/signOut?refreshToken=${getRefreshToken()}`,
      {},
      {
        baseURL: process.env.REACT_APP_AUTH_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: getAccessToken(),
        },
      }
    );
    resetTokens();
    dispatch(
      saveUserData({
        userName: "",
        contactPerson: "",
        companyName: "",
        phone: "",
        address: "",
        email: "",
        website: "",
      })
    );
  };
};
