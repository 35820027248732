import { parseJwt } from "./jwtParser";

export const saveToken = (accessToken: string) => {
  const accessTokenData = parseJwt(accessToken);

  document.cookie = `accessToken=${accessToken}; expires=${new Date(
    accessTokenData.exp * 1000
  ).toISOString()}; path=/`;
};

export const saveRefreshToken = (refreshToken: string) => {
  const refreshTokenData = parseJwt(refreshToken);

  document.cookie = `refreshToken=${refreshToken}; expires=${new Date(
    refreshTokenData.exp * 1000
  ).toISOString()}; path=/`;
};
