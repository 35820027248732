const locale = {
  header: {
    ONGOING: "البطولات الجارية",
    RFP: "بطولات رايوت",
    FAQ: "الأسئلة",
    SIGN_IN: "تسجيل الدخول",
    SIGN_OUT: "تسجيل خروج",
    profile: "حسابى",
    TOURNAMENT_APPLICATION: "تسجيل بطولة",
    VALORANT: "VALORANT",
    LOL: "LEAGUE OF LEGENDS",
    WILD_RIFT: "WILD RIFT",
    LOR: "LOR",
    TFT: "TFT",
  },
  footer: {
    copy_rights: "2023 Riot Games, كل الحقوق محفوظة.",
    termsOfUse: "شروط الاستخدام",
    privacyPolicy: "سياسة الخصوصية",
    have_questions: "هل لديك اسئلة؟",
    broadcast_terms: "شروط البث",
  },
  landingPage: {
    for_the_players: "for the players",
    ongoing_tournaments: "the ongoing \nTournaments\nand register",
    for_the_tournament_organizers: "for the tournament organizers",
    apply_for_the_tournament_license: "apply for the \n tournament \n license",
    browse_tournaments: "browse tournaments",
  },
  landingPageMultiGames: {
    tournament_on_demand_1: "BECOME THE NEXT \n",
    tournament_on_demand_2: "RIOT GAMES",
    tournament_on_demand_3: "PARTNER!",
    tournament_on_demand_text:
      "Welcome to TOD, your on-demand tournament website. Here, we can help you secure licensing from Riot Games and attract more players. To kick off your journey, please select the game you'd like to host a tournament for.",
    select_games: "Select Game",
    ongoing_tournaments: "ongoing tournaments",
  },
  PlayerLandingPageMultiGames: {
    tournament_on_demand:
      "ابقَ على اطلاع ببطولات \nRiot Games في الشرق الأوسط وشمال أفريقيا",
    tournament_on_demand_text:
      "مرحباً في TOD! هنا يمكنك تصفح مختلف أنواع البطولات في منطقة الشرق الأوسط وشمال أفريقيا والتسجيل فيها بخطوت سهلة. إذاً، ماذا تنتظر؟ اختر لعبتك المفضّلة وأطلق العنان لمهاراتك التنافسية!",
  },
  registerPopup: {
    register: "register or\nsign in",
    register_title: "lorem ipsum",
    register_text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    register_btn: "register",
    sign_in_btn: "sign in",
  },
  on_going_tournaments: {
    list: "قائمة",
    calendar: "تقويم",
    ongoing_tournaments: "البطولات النشطة",
    subheading:
      "تحقق من جدول البطولات الجارية للاطلاع على كافة الفعاليات المنظمة من قبل شركائنا.",
    region: "منطقة",
    VRC: "VRC",
    arabianLeague: "الدوري العربي",
    all: "الكل",
  },
  yes_no: {
    all: "الكل",
    yes: "نعم",
    no: "لا",
  },
  tournament_card: {
    days_left_for_register: "يوم متبقي للتسجيل",
    visit_registration_website: "سجل هنا",
  },
  calendar: {
    heading: "التقويم",
    duration: "المدة",
    broadcast_days: "تواريخ البث",
  },
  custom_tournament_form_step_1: {
    tournament_application: "Tournament Application",
    tournament_information: "Tournament Information",
    mena_information_part_1:
      "return Riot Games MENA currently operates in three different region:",
    mena_information_part_2:
      "and offer single country or multi country tournament licenses across these regions",
    select_the_game: "SELECT THE GAME",
    regions_label: "FOCUS COUNTRY",
  },
  playerHome: {
    banner: {
      heading: "اطّلع على البطولات وسجّل فيها",
      subheading:
        "سواء كنت لاعباً محترفاً أو مبتدئاً، ستجد هنا البطولة التي تناسب مستواك وأسلوب لعبك. اكتشف وشارك في مختلف البطولات المرخصة من قبل Riot Games في الشرق الأوسط وشمال أفريقيا، ودع أداءك يتحدّث عنك في الوسط التنافسي.",
      ongoing_tournaments: "عدد البطولات الجارية في المنطقة",
    },
    ongoing_tournaments: "جدول البطولات",
    view_all: "عرض المزيد",
  },
};

export default locale;
