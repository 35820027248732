import React, { forwardRef } from "react";

import "../styles/checkbox.scss";

const Input = forwardRef(
  (
    { name, label, errors, onChange, onBlur, value, checked, labelClass = "" },
    ref
  ) => (
    <div className={"checkbox-form-group form-check row align-items-center"}>
      <div className="col-12 check-extra">
        <div className="d-flex align-items-center">
          <input
            ref={ref}
            id={`${name}_${value}`}
            name={name}
            type="checkbox"
            className="form-check-input"
            checked={checked}
            aria-invalid={errors}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
          <label
            htmlFor={`${name}_${value}`}
            className={`form-check-label col-form-label ${labelClass}`}
          >
            {label}
          </label>
        </div>
        {errors && (
          <p className="input-error" role="alert">
            * {errors}
          </p>
        )}
      </div>
    </div>
  )
);

export default Input;
