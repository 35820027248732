import React from "react";
import TournamentCard from "./TournamentCard";

function OnGoingTournaments({ tournaments }) {
  return (
    <React.Fragment>
      <div className="row">
        {tournaments?.length > 0 &&
          !tournaments[0].isFeatured &&
          tournaments?.slice(0, 3)?.map((tournament, index) => (
            <div className="col-lg-4 col-12" key={`${tournament?.id}_${index}`}>
              <TournamentCard tournament={tournament} />
            </div>
          ))}
      </div>

      {tournaments?.length > 0 && tournaments[0].isFeatured && (
        <div className="row">
          {tournaments?.length > 0 && (
            <div className="col-lg-8 col-12">
              <TournamentCard
                tournament={tournaments[0]}
                cardClass="first-card"
              />
            </div>
          )}
          <div className="col-lg-4 col-12">
            {tournaments?.length > 1 && (
              <TournamentCard tournament={tournaments[1]} />
            )}
            {tournaments?.length > 2 && (
              <TournamentCard tournament={tournaments[2]} />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default OnGoingTournaments;
