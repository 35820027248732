import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import Tournaments from "./Tournaments";

import "../../../styles/Profile/index.scss";
import EditProfileData from "./EditProfileData";
import useRequireAuth from "../../../hooks/useRequireAuth";
import { Helmet } from "react-helmet";

function UserProfile() {
  useRequireAuth();

  const { t } = useTranslation("ProjectTranslation");
  const history = useHistory();
  const pathName = useLocation().pathname;

  const openTab = (link) => {
    history.push(`/profile/${link}`);
  };

  const getPageTitle = () => {
    if (pathName === "/profile/edit") {
      return "Edit Profile";
    } else {
      return "Custom Tournaments";
    }
  };

  return (
    <div className="profile-tournaments">
      <Helmet>
        <title>{getPageTitle()}</title>
      </Helmet>

      <div className="header">
        <div className="container text-container">
          <h1 className="px-0">{t("profile_tournaments.heading")}</h1>

          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item pc-form">
              <button
                className={`nav-link ${
                  pathName === "/profile/tournaments" ? "active" : ""
                }`}
                onClick={() => openTab("tournaments")}
              >
                <h3>{t("profile_tournaments.custom_tournaments")}</h3>
              </button>
            </li>
            <li className="nav-item pc-form">
              <button
                className={`nav-link ${
                  pathName === "/profile/edit" ? "active" : ""
                }`}
                onClick={() => openTab("edit")}
              >
                <h3>{t("profile_tournaments.edit_information")}</h3>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <section className="pageContainer">
        <div className="container">
          <div className="tab-content">
            <div
              className={`tab-pane ${
                pathName === "/profile/tournaments" ? "show active" : ""
              }`}
            >
              <div className="container">
                <Tournaments />
              </div>
            </div>

            <div
              className={`tab-pane ${
                pathName === "/profile/edit" ? "show active" : ""
              }`}
            >
              <div className="container">
                <EditProfileData />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UserProfile;
