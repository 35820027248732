import { axiosService as API } from "../../services/axiosInterceptors";
import { MeActions } from "../actions/types";
import * as Actions from "../actions";
import {
  getAccessToken,
  getSecurityToken,
} from "../../utils/getTokenFromCookie";

export const fetchUserData = () => {
  return (dispatch, getState) => {
    dispatch(loadUserData());

    return API.get("/api/organizer/user/getUserByToken", {
      baseURL: process.env.REACT_APP_AUTH_BASE_URL,
      headers: {
        Authorization: getAccessToken(),
      },
    })
      .then((response) => {
        dispatch(saveUserData(response.data));
        return response.data;
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };
};

export const UpdateUserData = (form) => {
  return async (dispatch) => {
    const data = { ...form, regionIds: [0] };
    delete data["password"];

    API.put("/api/organizer/user/updateOrgUser", data, {
      baseURL: process.env.REACT_APP_AUTH_BASE_URL,
      headers: {
        Authorization: getAccessToken(),
      },
    }).then((response) => {
      dispatch(fetchUserData());
      dispatch(
        Actions.saveSuccessMessage({
          isSuccess: true,
          successMessage: "Your Data Updated Successfully",
        })
      );
      return response.data;
    });
  };
};

export const UpdateUserPassword = (form) => {
  return async (dispatch) => {
    API.put(
      "/api/organizer/user/password",
      {
        oldPassword: form.password,
        newPassword: form.newPassword,
        confirmPassword: form.confirmPassword,
      },
      {
        baseURL: process.env.REACT_APP_AUTH_BASE_URL,
        headers: {
          Authorization: getAccessToken(),
        },
      }
    ).then((response) => {
      dispatch(
        Actions.saveSuccessMessage({
          isSuccess: true,
          successMessage: "Your Password Successfully",
        })
      );
      return response.data;
    });
  };
};

export const fetchOwnerCustomTournaments = (
  page = 0,
  itemsPerPage = 10,
  game = 1
) => {
  return async (dispatch, getState) => {
    dispatch(loadTournaments());
    await API.get(
      `/api/custom-tournament/v2/list/page/${page}/size/${itemsPerPage}?gameId=${game}`,
      {
        headers: {
          Authorization: getSecurityToken(),
        },
      }
    )
      .then((response) => {
        dispatch(
          saveOwnerCustomTournaments({
            data: response.data.tournaments,
            pages_count: Math.ceil(response.data.total / itemsPerPage),
          })
        );
      })
      .catch((err) => {
        console.log("error: ", err);
        dispatch(readyTournaments());
      });
  };
};

export const fetchOwnerFeaturedTournaments = () => {
  return async (dispatch, getState) => {
    dispatch(loadTournaments());
    await API.get("/api/tournament/applicant/list/me", {
      withCredentials: true,
      headers: {
        Authorization: getSecurityToken(),
      },
    })
      .then((response) => {
        const result = response.data;
        dispatch(saveOwnerFeaturedTournaments(result));
      })
      .catch((err) => {
        console.log("error: ", err);
        dispatch(readyTournaments());
      });
  };
};

export const loadUserData = () => ({
  type: MeActions.USER_DATA_LOAD,
});

export const readyUserData = () => ({
  type: MeActions.USER_DATA_READY,
});

export const saveUserData = (data) => ({
  type: MeActions.USER_DATA_SAVE,
  payload: data,
});

export const loadTournaments = () => ({
  type: MeActions.OWNER_TOURNAMENTS_LOAD,
});

export const readyTournaments = () => ({
  type: MeActions.OWNER_TOURNAMENTS_READY,
});

export const saveOwnerCustomTournaments = (data) => ({
  type: MeActions.OWNER_TOURNAMENTS_CUSTOM_SAVE,
  payload: data,
});

export const saveOwnerFeaturedTournaments = (data) => ({
  type: MeActions.OWNER_TOURNAMENTS_FEATURED_SAVE,
  payload: data,
});
