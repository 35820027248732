import React from "react";
import { useTranslation } from "react-i18next";

import "../styles/LoginRegisterModal.scss";

import closeImg from "../assets/icons/closeImg.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function LoginRegisterModal({ isOpened, setIsOpened }) {
  const history = useHistory();
  const { t } = useTranslation("ProjectTranslation");

  const openPage = (page) => {
    setIsOpened(false);
    history.push(page);
  };

  return (
    <div className="loginRegisterModal-container">
      {isOpened && (
        <div
          className="modal fade show opened-modal"
          tabIndex={-1}
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content bg">
              <div className="modal-header d-flex  justify-content-end">
                <button
                  type="button"
                  className="close button-bg"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsOpened(false)}
                >
                  <img aria-hidden="true" src={closeImg} alt="close-btn" />
                </button>
              </div>
              <div className="modal-body">
                <h1>{t("registerPopup.register")}</h1>
                <div className="text">
                  <p>{t("registerPopup.register_text")}</p>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-around">
                <button
                  type="button"
                  className="btn btn-primary custom-button "
                  onClick={() => openPage("/register")}
                >
                  {t("registerPopup.register_btn")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary custom-button outline"
                  onClick={() => openPage("/login")}
                >
                  {t("registerPopup.sign_in_btn")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginRegisterModal;
