import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import FaqItem from "./FAQs/Item";

import "../styles/FAQs/index.scss";
import { Helmet } from "react-helmet";

function Faq() {
  const { t } = useTranslation("ProjectTranslation");
  const dispatch = useDispatch();
  const data = require("../staticContent.json");
  const faqs = data.faqs;
  const [faqData, setFaqData] = useState([]);
  const [faqTags, setFaqTags] = useState([]);

  useEffect(() => {
    const questions = faqs;

    if (questions && questions?.length > 0) {
      let tagsArr = [];
      tagsArr.push(questions[0].tag);
      questions.map((faq, fIndex) => {
        if (tagsArr.indexOf(faq.tag) === -1) {
          tagsArr.push(faq.tag);
        }
        return tagsArr;
      });
      setFaqTags(tagsArr);
      setFaqData(questions);
    }
  }, [dispatch, faqs]);

  return (
    <div className="faq-page">
      <Helmet>
        <title>FAQs</title>
      </Helmet>

      <div className="bg">
        <div className="container">
          <h1>frequently asked questions</h1>
        </div>
      </div>
      <div className="faqs-container">
        <div className="faq">
          {faqTags &&
            faqTags.map((tag, tIndex) => (
              <div className="container" key={"tag_" + tIndex}>
                <h2>{t(tag)}</h2>
                <div className="faq-list">
                  <ul className="faq-list-ul">
                    {faqData &&
                      faqData
                        .filter((faq) => faq.tag === tag)
                        .map((faq, fIndex) => (
                          <FaqItem
                            tIndex={tIndex}
                            fIndex={fIndex}
                            faq={faq}
                            key={"faq_list" + fIndex}
                          />
                        ))}
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Faq;
