import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import "../styles/Footer.scss";
import i18n from "../i18n";

function Footer(props) {
  const { t } = useTranslation("ProjectTranslation");
  const pathName = useLocation().pathname;

  const [isPlayerPage, setIsPlayerPage] = useState(false);

  const language = i18n.language;

  const isEn = () => {
    return language === "en";
  };

  useEffect(() => {
    if (pathName.includes("/user") || pathName.includes("/player")) {
      setIsPlayerPage(true);
    } else {
      setIsPlayerPage(false);
    }
  }, [pathName]);

  return (
    <>
      <footer id="footer">
        <div className="container footer-bottom d-flex flex-column flex-xl-row flex-wrap align-items-center">
          <div className="d-flex flex-column flex-lg-row flex-wrap align-items-center">
            <img
              src="assets/icons/rg-full-lockup-offwhite.png"
              className="img-fluid riot-logo mb-3 mb-lg-0"
              alt="Riot logo"
            />
            <p className="footer-copy-rights mb-3 mb-lg-0">
              {t("footer.copy_rights")}
            </p>

            {!isPlayerPage && (
              <React.Fragment>
                <div className="credits mb-3 mb-lg-0">
                  <Link
                    className="termsOfUse"
                    to={`/terms-of-use`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer.termsOfUse")}
                  </Link>
                  <Link
                    className="termsOfUse"
                    to={`/privacy-policy`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer.privacyPolicy")}
                  </Link>
                  <Link
                    to={`/broadcast-terms`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer.broadcast_terms")}
                  </Link>
                </div>

                <p
                  className={`footer-contact mb-3 mb-lg-0 ${
                    isEn() ? "me-lg-0 ms-lg-5" : "ms-lg-0 me-lg-5"
                  } `}
                >
                  {t("footer.have_questions")}{" "}
                  <a
                    className="email"
                    href="mailto: tod-support-rg@riotgames.com"
                  >
                    tod-support-rg@riotgames.com
                  </a>
                </p>
              </React.Fragment>
            )}
          </div>

          <div className="social d-flex flex-wrap align-items-center mt-5 mt-xl-0">
            <Link
              to={{ pathname: "https://playvalorant.com/" }}
              target="_blank"
            >
              <img src="assets/icons/footer-valorant.png" alt="Valorant" />
            </Link>

            <Link
              to={{ pathname: "https://leagueoflegends.com/" }}
              target="_blank"
            >
              <img src="assets/icons/footer-lol.png" alt="LOL" />
            </Link>

            <Link
              to={{ pathname: "https://wildrift.leagueoflegends.com/" }}
              target="_blank"
            >
              <img src="assets/icons/footer-wr.png" alt="WR" />
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
}

export default React.memo(Footer);
