import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import LoginRegisterModal from "./LoginRegisterModal";

import * as Actions from "../store/actions";
import { GAMES } from "../utils/app.constants";

import "../styles/NavMenu.scss";
import { getAccessToken } from "../utils/getTokenFromCookie";

function NavMenu({ isHeaderTransparent }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(({ me }) => me.user);
  const selectedGame = useSelector(({ common }) => common.selectedGame);

  const [viewGamesName, setViewGamesName] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(!!user?.id);
  const [isNavItemsViewed, setIsNavItemsViewed] = useState(false);
  const [isGameSelectViewed, setIsGameSelectViewed] = useState(false);
  const [isLangViewed, setIsLangViewed] = useState(false);
  const [isAuthPages, setIsAuthPages] = useState(false);
  const [isSelectGamePage, setIsSelectGamePage] = useState(false);

  const [isLoginRegisterModalOpen, setIsLoginRegisterModalOpen] =
    useState(false);

  const { t } = useTranslation("ProjectTranslation");
  const lang = useLocation().pathname.slice(1, 3);
  const loc = useLocation().pathname.slice(3);
  const pathName = useLocation().pathname;

  localStorage.setItem("selectedGame", selectedGame);

  const viewGameIcon = () => {
    if (selectedGame === GAMES.VALORANT) {
      return "assets/img/valorantHeaderIcon.png";
    } else if (selectedGame === GAMES.LOL) {
      return "assets/img/lolHeaderIcon.png";
    } else if (selectedGame === GAMES.WILD_RIFT) {
      return "assets/img/wrHeaderIcon.png";
    }
  };

  const changeGame = (game) => {
    const newPath = pathName.replace(selectedGame, game);

    dispatch(Actions.setSelectedGame(game));

    history.push(newPath);
  };

  const logout = () => {
    history.push("/login");
    dispatch(Actions.Logout());
  };

  const tournamentApplicationButtonClick = () => {
    if (!isAuthorized) {
      setIsLoginRegisterModalOpen(!isLoginRegisterModalOpen);
    } else history.push("/tournaments/create-custom-tournament");
  };

  useEffect(() => {
    const newLang = lang === "ar" ? "ar" : "en";

    dispatch(Actions.changeLanguage(newLang));
  }, [dispatch, lang]);

  useEffect(() => {
    setIsAuthorized(!!user?.id);
  }, [user]);

  useEffect(() => {
    if (pathName.includes(GAMES.VALORANT)) {
      dispatch(Actions.setSelectedGame(GAMES.VALORANT));
    } else if (pathName.includes(GAMES.LOL)) {
      dispatch(Actions.setSelectedGame(GAMES.LOL));
    } else if (pathName.includes(GAMES.WILD_RIFT)) {
      dispatch(Actions.setSelectedGame(GAMES.WILD_RIFT));
    }
    setViewGamesName(false);
  }, [dispatch, pathName]);

  useEffect(() => {
    if (pathName.includes("/user") || pathName.includes("/player")) {
      setIsLangViewed(true);
    } else {
      setIsLangViewed(false);
    }
  }, [pathName]);

  useEffect(() => {
    if (
      pathName.includes("/tournaments") ||
      pathName.includes("/tournaments/") ||
      pathName.includes("/thank-you") ||
      pathName.includes("/profile") ||
      pathName === "/terms-of-use" ||
      pathName === "/privacy-policy" ||
      pathName === "/faq" ||
      pathName === "/our-support" ||
      pathName === "/broadcast-terms" ||
      pathName === "/register" ||
      pathName === "/login" ||
      pathName === "/forgot-password" ||
      pathName === "/reset-password" ||
      pathName === "/verify-email" ||
      pathName === "/verify-phone"
    ) {
      setIsNavItemsViewed(true);
    } else {
      setIsNavItemsViewed(false);
    }
  }, [pathName]);

  useEffect(() => {
    if (pathName === "/tournaments" || pathName.includes("/user-game-select")) {
      setIsSelectGamePage(true);
    } else {
      setIsSelectGamePage(false);
    }
  }, [pathName]);

  useEffect(() => {
    if (pathName.includes("/player") || pathName.includes("/user")) {
      setIsGameSelectViewed(true);
    } else {
      setIsGameSelectViewed(false);
    }
  }, [pathName]);

  useEffect(() => {
    if (
      pathName === "/register" ||
      pathName === "/login" ||
      pathName === "/forgot-password" ||
      pathName === "/reset-password" ||
      pathName === "/verify-email" ||
      pathName === "/verify-phone"
    ) {
      setIsAuthPages(true);
    } else {
      setIsAuthPages(false);
    }
  }, [pathName]);

  useEffect(() => {
    if (getAccessToken()) {
      dispatch(Actions.fetchUserData());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(Actions.fetchCountries());
    dispatch(Actions.fetchRegions());
    dispatch(Actions.fetchGames());
    dispatch(Actions.fetchCustomTournamentEnums());
    dispatch(Actions.fetchTournamentFormatCont());
    dispatch(Actions.fetchTournamentSupportOptions());
    dispatch(Actions.fetchOnGoingTournamentsNumber());
  }, [dispatch]);

  return (
    <header
      id="header"
      className={`fixed-top ${isHeaderTransparent ? "header-scrolled" : ""}`}
    >
      <div className="container-fluid d-flex align-items-start header-container px-5">
        <div className="logo-games-section">
          <div className="d-flex align-items-center">
            <Link to={`/`}>
              <img src="assets/img/logo.png" alt="Logo" />
            </Link>
            {(isNavItemsViewed || isGameSelectViewed) &&
              !isAuthPages &&
              !isSelectGamePage && (
                <>
                  <img
                    className="mx-3 select-games-arrow"
                    src="assets/icons/headerSelectGamesArrow.svg"
                    alt="select games arrow"
                    onClick={() => setViewGamesName(!viewGamesName)}
                  />
                  <img
                    className="game-logo"
                    src={viewGameIcon()}
                    alt="Game Logo"
                  />
                </>
              )}
          </div>
          {viewGamesName && (
            <ul className="d-flex games-list">
              <li
                className={selectedGame === GAMES.VALORANT ? "selected" : ""}
                onClick={() => changeGame(GAMES.VALORANT)}
              >
                {t("header.VALORANT")}
              </li>
              <li
                className={selectedGame === GAMES.LOL ? "selected" : ""}
                onClick={() => changeGame(GAMES.LOL)}
              >
                {t("header.LOL")}
              </li>
              <li
                className={selectedGame === GAMES.WILD_RIFT ? "selected" : ""}
                onClick={() => changeGame(GAMES.WILD_RIFT)}
              >
                {t("header.WILD_RIFT")}
              </li>
            </ul>
          )}
        </div>

        <nav id="navbar" className="navbar">
          <ul>
            {isNavItemsViewed && (
              <>
                <li>
                  <Link
                    to={`/tournaments/${selectedGame}/on-going/list`}
                    className={`nav-link ${
                      pathName === `/tournaments/${selectedGame}/on-going/list`
                        ? "active"
                        : ""
                    }`}
                  >
                    {t("header.ONGOING")}
                  </Link>
                </li>
                {/*
                  <li>
                    <Link
                      to={`/tournaments/${selectedGame}/rfp`}
                      className={`nav-link ${
                        pathName.toLowerCase() ===
                        `/tournaments/${selectedGame}/rfp`.toLowerCase()
                          ? "active"
                          : ""
                      }`}
                    >
                      {t("header.RFP")}
                    </Link>
                  </li>
                */}

                <li>
                  <Link
                    to={`/faq`}
                    className={`nav-link ${
                      pathName === `/faq` ? "active" : ""
                    }`}
                  >
                    {t("header.FAQ")}
                  </Link>
                </li>
                {isAuthorized ? (
                  <>
                    <li>
                      <Link
                        to={`/profile/tournaments`}
                        className={`nav-link profile-nav-link ${
                          pathName === `/profile/tournaments` ? "active" : ""
                        }`}
                      >
                        {t("header.profile")}
                      </Link>
                    </li>

                    <li className="dropdown profile-dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        href="/"
                      >
                        <i className="bx bx-chevron-down dropdown-icon"></i>
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <div className="dropdown-item" onClick={() => logout()}>
                          {t("header.SIGN_OUT")}
                        </div>
                      </div>
                    </li>
                  </>
                ) : (
                  !isAuthPages && (
                    <li>
                      <Link to={`/login`} className="nav-link">
                        {t("header.SIGN_IN")}
                      </Link>
                    </li>
                  )
                )}

                <li>
                  <button
                    type="button"
                    className="btn btn-primary custom-button ms-3"
                    onClick={() => tournamentApplicationButtonClick()}
                  >
                    {t("header.TOURNAMENT_APPLICATION")}
                  </button>
                </li>
              </>
            )}
            {isLangViewed && (
              <li>
                <ul className="language-ul">
                  <li className="language">
                    <Link to={`/en${loc}`}>EN</Link>
                    {lang === "en" && (
                      <img
                        className="select-games-arrow"
                        src="assets/icons/selectedLanguageArrow.svg"
                        alt="select language arrow"
                      />
                    )}
                  </li>
                  <li className="language">
                    <Link to={`/ar${loc}`}>AR</Link>
                    {lang === "ar" && (
                      <img
                        className="select-games-arrow rotate-y-180"
                        src="assets/icons/selectedLanguageArrow.svg"
                        alt="select language arrow"
                      />
                    )}
                  </li>
                </ul>
              </li>
            )}
          </ul>
          {isNavItemsViewed && <i className="bi bi-list mobile-nav-toggle"></i>}
        </nav>
      </div>
      <LoginRegisterModal
        isOpened={isLoginRegisterModalOpen}
        setIsOpened={setIsLoginRegisterModalOpen}
      />
    </header>
  );
}

export default NavMenu;
