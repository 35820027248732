import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";

import "../styles/StaticPage.scss";
import { Helmet } from "react-helmet";

function StaticPage() {
  const type = useLocation().pathname;
  const data = require("../staticContent.json");
  const all_static_data = data.all_static_data;

  const [static_data, setStaticData] = useState({
    content: "",
    subtitle: "",
    tags: "",
    title: "",
    url: "",
  });
  const [headingTitle, setHeadingTitle] = useState("");

  const getPageTitle = () => {
    const splitArr = headingTitle.split(" ");
    return splitArr
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const pos = all_static_data?.findIndex((data) => data.url === type);
    const staticData = all_static_data[pos];
    setStaticData(staticData);
  }, [all_static_data, type]);

  useEffect(() => {
    const staticPagesHeading = {
      "/terms-of-use": "terms of use",
      "/privacy-policy": "privacy policy",
    };

    setHeadingTitle(staticPagesHeading[type]);
  }, [type]);

  return (
    <div className="static-page">
      <Helmet>
        <title>{getPageTitle()}</title>
      </Helmet>

      <div className="bg">
        <div className="container">
          <h1>{headingTitle}</h1>
        </div>
      </div>

      {
        <div className="container">
          <div className="my-5 py-5 text">
            {ReactHtmlParser(static_data?.subtitle)}
          </div>
        </div>
      }
    </div>
  );
}

export default StaticPage;
