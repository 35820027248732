import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { formValidations } from "../../../utils/formValidations";
import Input from "../../../components/Input";

import * as Actions from "../../../store/actions";
import useRequireAuth from "../../../hooks/useRequireAuth";

import "../../../styles/CustomTournamentForms.scss";
import { format } from "date-fns";
import { COUNTRIES_EXCEPT_MENA } from "../../../utils/app.constants";
import { Helmet } from "react-helmet";
import useIsStepCanOpen from "../../../hooks/useIsStepCanOpen";

function CreateCustomTournamentStep3() {
  const initialValues = {
    company_registration_number: "",
    tournament_address: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ defaultValues: { ...initialValues } });

  useRequireAuth();

  const { t } = useTranslation("ProjectTranslation");
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const games = useSelector(({ common }) => common.games);
  const tournament = useSelector(({ tournaments }) => tournaments.selected);
  const custom_tournaments_enums = useSelector(
    ({ tournaments }) => tournaments.custom_tournaments_enums
  );
  const countries = useSelector((state) => state.countries.list);

  useIsStepCanOpen(3, tournament);

  const onSubmit = (data) => {
    setIsLoading(true);

    const action = isUpdatePage() ? updateStep2 : createStep2;

    dispatch(action({ ...data, id }))
      .then(() => {
        const gameName = games.filter(
          (game) => game.id === tournament.game_name
        )[0].code;

        history.push(`/tournaments/${gameName}/thank-you/3`);
        reset({ ...initialValues });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createStep2 = (data) => {
    return Actions.registerCustomTournamentStep3(data);
  };

  const updateStep2 = (data) => {
    return Actions.updateCustomTournamentStep3(data);
  };

  const isUpdatePage = () => {
    return history.location.pathname.includes("update-custom-tournament");
  };

  const getPrizeType = useCallback(
    (type) => {
      return custom_tournaments_enums?.prize_type?.find(
        (prize_type) => type === prize_type.id
      )?.name;
    },
    [custom_tournaments_enums]
  );

  const getTournamentGameName = useCallback(
    (game_name) => {
      return games?.find((game) => game_name === game.id)?.name;
    },
    [games]
  );

  const getTournamentType = useCallback(
    (tournament_tier) => {
      return custom_tournaments_enums?.tier?.find(
        (tier) => tier.id === tournament_tier
      )?.name;
    },
    [custom_tournaments_enums]
  );

  const getCountryNameUsingCode = useCallback(
    (code) => {
      const country = countries?.filter((country) => country.code === code)[0];
      return country?.nameEn;
    },
    [countries]
  );

  const getOtherCountryNameUsingCode = useCallback((code) => {
    const country = COUNTRIES_EXCEPT_MENA?.filter(
      (country) => country.code === code
    )[0];
    return country.name;
  }, []);

  const getTournamentCountriesNames = useCallback(
    (tournament) => {
      const { focus_countries, focus_countries_other } = tournament;

      let countriesNames = [];

      focus_countries?.forEach((country, index) => {
        countriesNames.push(getCountryNameUsingCode(country));
      });

      focus_countries_other?.forEach((country, index) => {
        countriesNames.push(getOtherCountryNameUsingCode(country));
      });

      return countriesNames.join(", ");
    },
    [getCountryNameUsingCode, getOtherCountryNameUsingCode]
  );

  useEffect(() => {
    dispatch(Actions.getCustomTournamentById(id)).then((tournament) => {
      reset({
        ...tournament,
        start_date: format(new Date(tournament.start_date), "dd/MM/yyyy"),
        end_date: format(new Date(tournament.end_date), "dd/MM/yyyy"),
      });
    });
  }, [reset, dispatch, id]);

  useEffect(() => {
    setValue("prize_type", getPrizeType(tournament.prize_type));
    setValue("tier", getTournamentType(tournament.tier));
    setValue("game_name", getTournamentGameName(tournament.game_name));
    setValue("countries_names", getTournamentCountriesNames(tournament));
  }, [
    tournament,
    setValue,
    getTournamentCountriesNames,
    getPrizeType,
    getTournamentType,
    getTournamentGameName,
  ]);

  return (
    <div className="custom-tournament-create-pages">
      <Helmet>
        <title>Legal Information</title>
      </Helmet>

      <div className="header">
        <div className="container text-container">
          <h1>{t("custom_tournament_form_step_3.heading")}</h1>
        </div>
      </div>

      <div className="formContainer">
        <div className="container">
          <h2 className="heading">
            {t("custom_tournament_form_step_3.form_heading")}
          </h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12 mt-5 pt-5 mb-3">
                <h3 className="subheading">
                  {t("custom_tournament_form_step_3.organiser_details")}
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t(
                    "custom_tournament_form_step_3.form.organiser_company_name"
                  )}
                  labelClass="bold"
                  {...register("company_legal_name")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t(
                    "custom_tournament_form_step_3.form.company_address"
                  )}
                  labelClass="bold"
                  {...register("company_address")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={`${t(
                    "custom_tournament_form_step_3.form.company_registration_number"
                  )} *`}
                  labelClass="bold"
                  placeholder={t(
                    "custom_tournament_form_step_3.form.company_registration_number"
                  )}
                  {...register("company_registration_number", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(3),
                    maxLength: formValidations.maxLength(255),
                  })}
                  errors={errors.company_registration_number?.message}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t("custom_tournament_form_step_3.form.contact_email")}
                  labelClass="bold"
                  {...register("contact_email")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-5 pt-5 mb-3">
                <h3 className="subheading">
                  {t("custom_tournament_form_step_3.tournament_details")}
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t(
                    "custom_tournament_form_step_3.form.tournament_name"
                  )}
                  labelClass="bold"
                  {...register("tournament_english_name")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={`${t(
                    "custom_tournament_form_step_3.form.tournament_address"
                  )} *`}
                  labelClass="bold"
                  placeholder={t(
                    "custom_tournament_form_step_3.form.tournament_address"
                  )}
                  {...register("tournament_address", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(3),
                    maxLength: formValidations.maxLength(255),
                  })}
                  errors={errors.tournament_address?.message}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t("custom_tournament_form_step_3.form.start_date")}
                  labelClass="bold"
                  {...register("start_date")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={`${t("custom_tournament_form_step_3.form.end_date")}`}
                  labelClass="bold"
                  {...register("end_date")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t(
                    "custom_tournament_form_step_3.form.tournament_territory"
                  )}
                  labelClass="bold"
                  {...register("countries_names")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t(
                    "custom_tournament_form_step_3.form.broadcast_channel_URL"
                  )}
                  labelClass="bold"
                  {...register("broadcast_platforms_url", {
                    maxLength: formValidations.maxLength(255),
                  })}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-5 pt-5 mb-3">
                <h3 className="subheading">
                  {t("custom_tournament_form_step_3.tournament_prizes")}
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t("custom_tournament_form_step_3.form.prize_type")}
                  labelClass="bold"
                  {...register("prize_type")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={`${t(
                    "custom_tournament_form_step_3.form.prize_value"
                  )}`}
                  labelClass="bold"
                  {...register("prize_value")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t(
                    "custom_tournament_form_step_3.form.tournament_game"
                  )}
                  labelClass="bold"
                  {...register("game_name")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t(
                    "custom_tournament_form_step_3.form.tournament_type"
                  )}
                  labelClass="bold"
                  {...register("tier")}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-9 col-12 mt-4 offset-lg-3">
                <button
                  type="submit"
                  className="btn btn-primary custom-button full-width"
                  disabled={isLoading}
                  onClick={() => {
                    window.gtag("event", "submit-step-3", {
                      event_category: "Button",
                      event_label: "submit-step-3",
                    });
                  }}
                >
                  {t("form.submit")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateCustomTournamentStep3;
