import { DateObject } from "react-multi-date-picker";
import { axiosService as API } from "../../services/axiosInterceptors";
import {
  createFormForCustomTournamentStep1,
  createFormForCustomTournamentStep2,
  createFormForCustomTournamentStep3,
} from "../../utils/helpers/tournaments";

import { TournamentsActions } from "./types";
import { getSecurityToken } from "../../utils/getTokenFromCookie";

export const fetchCustomTournamentEnums = () => {
  return async (dispatch, getState) => {
    await API.get("/api/common/enums")
      .then((response) => {
        const result = response.data;
        dispatch(saveCustomTournamentEnums(result));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const fetchTournamentFormatCont = () => {
  return async (dispatch, getState) => {
    await API.get("/api/common/formats")
      .then((response) => {
        const result = response.data;
        dispatch(saveTournamentFormatCont(result));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const fetchTournamentReservedBroadcastDays = (gameId) => {
  return async (dispatch, getState) => {
    await API.get(`/api/common/getUnavailableDays/${gameId}`)
      .then((response) => {
        const dates = response.data.map((date) =>
          new DateObject(date).format("DD/MM/YYYY")
        );
        dispatch(saveTournamentBroadcastDays(dates));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const fetchTournamentSupportOptions = () => {
  return async (dispatch, getState) => {
    await API.get("/api/supportOptions/en")
      .then((response) => {
        const result = response.data;
        dispatch(saveTournamentSupportOptions(result));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const registerCustomTournamentStep1 = (values) => {
  return async (dispatch, getState) => {
    const formData = createFormForCustomTournamentStep1(values);

    return await API.post(`/api/custom-tournament/v2/step1`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getSecurityToken(),
      },
    });
  };
};

export const registerCustomTournamentStep2 = (values) => {
  return async (dispatch, getState) => {
    const formData = createFormForCustomTournamentStep2(values);

    return await API.post(`/api/custom-tournament/v2/step2`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getSecurityToken(),
      },
    });
  };
};

export const registerCustomTournamentStep3 = (values) => {
  return async (dispatch, getState) => {
    const formData = createFormForCustomTournamentStep3(values);

    return await API.post(`/api/custom-tournament/v2/step3`, formData, {
      headers: {
        Authorization: getSecurityToken(),
      },
    });
  };
};

export const updateCustomTournamentStep1 = (values) => {
  return async (dispatch, getState) => {
    const formData = createFormForCustomTournamentStep1(values);

    return await API.put(`/api/custom-tournament/v2/step1`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getSecurityToken(),
      },
    });
  };
};

export const updateCustomTournamentStep2 = (values) => {
  return async (dispatch, getState) => {
    const formData = createFormForCustomTournamentStep2(values);

    return await API.put(`/api/custom-tournament/v2/step2`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getSecurityToken(),
      },
    });
  };
};

export const updateCustomTournamentStep3 = (values) => {
  return async (dispatch, getState) => {
    const formData = createFormForCustomTournamentStep3(values);

    return await API.put(`/api/custom-tournament/v2/step3`, formData, {
      headers: {
        Authorization: getSecurityToken(),
      },
    });
  };
};

export const deleteTournamentFile = (data) => {
  return async (dispatch, getState) => {
    return await API.delete(`/api/custom-tournament/v2/deleteFile`, {
      data,
      headers: {
        Authorization: getSecurityToken(),
      },
    });
  };
};

export const getCustomTournamentById = (tournamentId) => {
  return async (dispatch, getState) => {
    let result = null;

    dispatch(saveSelectedTournament({}));
    dispatch(loadTournaments());
    return API.get(`/api/custom-tournament/v2/${tournamentId}`, {
      headers: {
        Authorization: getSecurityToken(),
      },
    })
      .then((response) => {
        result = response.data;
        dispatch(saveSelectedTournament(result));
        return result;
      })
      .catch((err) => {
        console.log("error: ", err);
        dispatch(readyTournaments());
        return null;
      });
  };
};

export const fetchCustomTournamentDetails = (id) => {
  return async (dispatch, getState) => {
    await API.get(`/api/custom-tournament/v2/history/${id}`, {
      headers: {
        Authorization: getSecurityToken(),
      },
    })
      .then((response) => {
        dispatch(saveCustomTournamentDetails(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const fetchCustomTournaments = (
  game = 1,
  page = 0,
  itemsPerPage = 15,
  region = "all",
  vrc = "all"
) => {
  return async (dispatch, getState) => {
    dispatch(loadOnGoingTournaments());

    let URL = `/api/custom-tournament/v2/all/page/${page}/size/${itemsPerPage}`;

    if (region !== "all") {
      URL += `/regions/${region}`;
    }
    if (vrc !== "all") {
      URL += `/vrc/${vrc}`;
    }

    URL += `?gameId=${game}`;

    return API.get(URL)
      .then((response) => {
        dispatch(
          saveOnGoingTournaments({
            tournaments: response.data.tournaments,
            pages: Math.ceil(response.data.total / itemsPerPage),
            page: page,
          })
        );
      })
      .catch((error) => {
        dispatch(readyOnGoingTournaments());
        console.log(error);
      });
  };
};

export const fetchCustomTournamentsCalendar = (
  game = 1,
  page = 0,
  itemsPerPage = 15,
  region = "all",
  vrc = "all",
  month,
  year
) => {
  return async (dispatch, getState) => {
    dispatch(loadOnGoingTournaments());

    let URL = `/api/custom-tournament/v2/all/list/page/${page}/size/${itemsPerPage}`;

    if (region !== "all") {
      URL += `/regions/${region}`;
    }
    if (vrc !== "all") {
      URL += `/vrc/${vrc}`;
    }

    URL += `/month/${month}/year/${year}`;
    URL += `?gameId=${game}`;

    return API.get(URL)
      .then((response) => {
        dispatch(
          saveOnGoingTournamentsCalendar({
            tournaments: response.data.tournaments,
            pages: Math.ceil(response.data.total / itemsPerPage),
            page: page,
          })
        );
      })
      .catch((error) => {
        dispatch(readyOnGoingTournaments());
        console.log(error);
      });
  };
};

export const downloadContract = (id) => {
  return async (dispatch, getState) => {
    let URL = `/api/contract/${id}`;

    return API.get(URL)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const registerTournament = (formValues) => {
  return async (dispatch, getState) => {
    let result = null;
    await API.post("/admin/api/tournament/create", formValues, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
        result = null;
      });
    return result;
  };
};

export const getListTournaments = (params) => {
  return async (dispatch, getState) => {
    let result = null;
    const { page, limit } = params;
    dispatch(loadTournaments());
    await API.get(`/api/tournament/list/featured/${page}/${limit}`)
      .then((response) => {
        result = response.data;
        dispatch(saveTournaments(result));
      })
      .catch((err) => {
        console.log("error: ", err);
        dispatch(readyTournaments());
      });
  };
};

export const getListFeaturedTournaments = () => {
  return async (dispatch, getState) => {
    dispatch(loadTournaments());
    await API.get("/api/tournament/list/featured/0/10")
      .then((response) => {
        dispatch(saveFeaturedTournaments(response.data));
      })
      .catch((err) => {
        console.log("error: ", err);
        dispatch(readyTournaments());
      });
  };
};

export const getTournamentById = (tournamentId) => {
  return async (dispatch, getState) => {
    let result = null;
    dispatch(loadTournaments());
    return API.get("/api/tournament/" + tournamentId)
      .then((response) => {
        result = response.data;
        dispatch(saveSelectedTournament(result));
        return result;
      })
      .catch((err) => {
        console.log("error: ", err);
        dispatch(readyTournaments());
        return null;
      });
  };
};

export const registerApplicant = (formValues) => {
  return async (dispatch, getState) => {
    let result = null;
    await API.post("/api/tournament/applicant/register", formValues, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
        result = null;
      });
    return result;
  };
};

export const updateApplicant = (formValues) => {
  return async (dispatch, getState) => {
    let result = null;
    await API.post("/api/tournament/applicant/update", formValues, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
        result = null;
      });
    return result;
  };
};

export const getFilterTournamentWithCountry = (countryNumber) => {
  return async (dispatch, getState) => {
    let result;
    await API.get(`/api/tournament/search/country/${countryNumber}`)
      .then((response) => {
        result = response.data;
        dispatch(saveTournaments(result));
      })
      .catch((error) => {
        console.log(error);
        result = null;
      });
    console.log(result);
    return result;
  };
};

export const getFilterTournamentWithRegion = (regionNumber) => {
  return async (dispatch, getState) => {
    let result;
    await API.get(`/api/tournament/search/region/${regionNumber}`)
      .then((response) => {
        result = response.data;
        dispatch(saveTournaments(result));
      })
      .catch((error) => {
        console.log(error);
        result = null;
      });
    console.log(result);
    return result;
  };
};

export const loadTournaments = () => ({
  type: TournamentsActions.TOURNAMENTS_LOAD,
});

export const readyTournaments = () => ({
  type: TournamentsActions.TOURNAMENTS_READY,
});

export const saveTournaments = (data) => ({
  type: TournamentsActions.TOURNAMENTS_SAVE,
  payload: data,
});

export const saveFeaturedTournaments = (data) => ({
  type: TournamentsActions.TOURNAMENTS_FEATURED_SAVE,
  payload: data,
});

export const saveSelectedTournament = (data) => ({
  type: TournamentsActions.TOURNAMENTS_SELECTED_SAVE,
  payload: data,
});

export const saveCustomTournamentEnums = (data) => ({
  type: TournamentsActions.SAVE_CUSTOM_TOURNAMENT_ENUMS,
  payload: data,
});

export const saveTournamentFormatCont = (data) => ({
  type: TournamentsActions.SAVE_TOURNAMENT_FORMAT_CONT,
  payload: data,
});

export const saveTournamentBroadcastDays = (data) => ({
  type: TournamentsActions.SAVE_TOURNAMENT_BROADCAST_DAYS,
  payload: data,
});

export const saveTournamentSupportOptions = (data) => ({
  type: TournamentsActions.SAVE_TOURNAMENT_SUPPORT_OPTIONS,
  payload: data,
});

export const saveCustomTournamentDetails = (data) => ({
  type: TournamentsActions.SAVE_CUSTOM_TOURNAMENT_DETAILS,
  payload: data,
});

export const loadOnGoingTournaments = () => ({
  type: TournamentsActions.ONGOING_TOURNAMENTS_LOAD,
});

export const readyOnGoingTournaments = () => ({
  type: TournamentsActions.ONGOING_TOURNAMENTS_READY,
});

export const saveOnGoingTournaments = (data) => ({
  type: TournamentsActions.SAVE_ONGOING_TOURNAMENTS,
  payload: data,
});

export const saveOnGoingTournamentsCalendar = (data) => ({
  type: TournamentsActions.SAVE_ONGOING_TOURNAMENTS_CALENDAR,
  payload: data,
});
