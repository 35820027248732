import { DateObject } from "react-multi-date-picker";
import { getBooleanValue } from "./helpers";

export const createFormForCustomTournamentStep1 = (values) => {
  const formData = new FormData();

  if (values.id) {
    formData.append("id", values.id);
  }
  formData.append("focus_countries", values.focus_countries);
  formData.append(
    "focus_countries_other",
    values.focus_countries_other.map((country) => country.value)
  );

  formData.append("game_name", values.game_name);
  formData.append("tournament_english_name", values.tournament_english_name);
  formData.append("tournament_arabic_name", values.tournament_arabic_name);
  formData.append("organizer_website_url", values.organizer_website_url);
  formData.append("format", values.format);

  formData.append("format_type", values.format_type);
  formData.append("format_type_other", values.format_type_other);

  formData.append("duration", values.duration);
  formData.append(
    "announce_date",
    new DateObject(values.announce_date).format("YYYY/MM/DD")
  );
  formData.append(
    "start_date",
    new DateObject(values.start_date).format("YYYY/MM/DD")
  );
  formData.append(
    "end_date",
    new DateObject(values.end_date).format("YYYY/MM/DD")
  );
  formData.append("broadcast", getBooleanValue(values.broadcast));
  formData.append("tier", values.tier);

  formData.append("prize_type", values.prize_type);
  formData.append("prize_value", values.prize_value);

  formData.append("chargeable", getBooleanValue(values.chargeable));
  formData.append("commercial", getBooleanValue(values.commercial));
  formData.append("sponsors", values.sponsors);
  formData.append("additional_info", values.additional_info);

  formData.append("additional_files", values.additional_files);
  values.selected_additional_files.forEach((file) => {
    formData.append("additional_file", file);
  });

  formData.append("support_options", values.support_options.join("#"));

  if (getBooleanValue(values.broadcast)) {
    formData.append(
      "broadcast_days",
      values.broadcast_days.map((date) =>
        new DateObject(date).format("YYYY/MM/DD")
      )
    );

    formData.append("broadcast_platforms", values.broadcast_platforms);
    formData.append(
      "broadcast_platforms_other",
      values.broadcast_platforms_other
    );

    formData.append("broadcast_platforms_url", values.broadcast_platforms_url);

    formData.append("broadcast_languages", values.broadcast_languages);
    formData.append(
      "broadcast_languages_other",
      values.broadcast_languages_other
    );

    formData.append("tv_broadcast", getBooleanValue(values.tv_broadcast));
  }

  formData.append("vrc", getBooleanValue(values.vrc));
  formData.append("regions", values.regions);

  return formData;
};

export const createFormForCustomTournamentStep2 = (values) => {
  const formData = new FormData();
  formData.append("id", values.id);
  formData.append("assets_link", values.assets_link);
  formData.append("pdf_presentation", values.pdf_presentation);
  values.selected_pdf_presentation.forEach((file) => {
    formData.append("pdf_presentation_file", file);
  });

  formData.append("tournament_keyart", values.tournament_keyart);
  formData.append("tournament_keyart_file", values.tournament_keyart_file[0]);

  return formData;
};

export const createFormForCustomTournamentStep3 = (values) => {
  const formData = new FormData();
  formData.append("id", values.id);
  formData.append(
    "company_registration_number",
    values.company_registration_number
  );
  formData.append("tournament_address", values.tournament_address);

  return formData;
};
