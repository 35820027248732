import React, { useCallback, useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import PlayerHome from "./pages/PlayerHome";
import LandingPage from "./pages/newPages/LandingPage";
import LandingPageMultiGames from "./pages/newPages/LandingPageMultiGames";
import PlayersLandingPageMultiGames from "./pages/newPages/PlayersLandingPageMultiGames";
import RegisterPage from "./pages/newPages/Register";
import LoginPage from "./pages/newPages/Login";
import ForgotPassword from "./pages/newPages/ForgotPassword";
import ResetPassword from "./pages/newPages/ResetPassword";
import Profile from "./pages/newPages/Profile/index";
import TournamentHistory from "./pages/newPages/Profile/TournamentHistory";
import CreateCustomTournamentStep1 from "./pages/newPages/CustomTournamentForms/Step-1";
import CreateCustomTournamentStep2 from "./pages/newPages/CustomTournamentForms/Step-2";
import CreateCustomTournamentStep3 from "./pages/newPages/CustomTournamentForms/Step-3";
// import MainPart from "./pages/Main";
import Error404 from "./pages/Error404";
import Footer from "./components/Footer";
import NavMenu from "./components/NavMenu";
import Faq from "./pages/Faq";
import ThankYou from "./pages/ThankYou";
import OnGoingTournaments from "./pages/OnGoingTournaments/index";
import StaticPage from "./pages/StaticPage";
import i18n from "./i18n";
import SuccessAlert from "./components/SuccessAlert";
import ErrorAlert from "./components/ErrorAlert";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import HomePage from "./pages/Home/index";
import PagesLayout from "./layouts/default";
import ScrollToTop from "./utils/ScrollToTop";
import OurSupport from "./pages/OurSupport";
import BroadcastTerms from "./pages/BroadcastTerms";

function AppRoute({ component: Component, path }) {
  return (
    <Route
      path={path}
      render={(props) => (
        <PagesLayout>
          <Component {...props} />
        </PagesLayout>
      )}
    />
  );
}

function App() {
  const lang = useLocation().pathname.slice(1, 3);
  const pathName = useLocation().pathname;

  const [dir, setDir] = useState("ltr");
  const [scrollTop, setScrollTop] = useState(0);
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(false);

  const setWebsiteDirection = useCallback(() => {
    const websiteDir = lang === "ar" ? "rtl" : "ltr";
    setDir(websiteDir);
  }, [lang]);

  const setI18nLang = useCallback(() => {
    const i18nDir = lang === "ar" ? "rtl" : "en";
    i18n.dir(i18nDir);
  }, [lang]);

  useEffect(() => {
    setWebsiteDirection();
    setI18nLang();
  }, [lang, setWebsiteDirection, setI18nLang]);

  const isPageWithoutTransparentHeader = useCallback(() => {
    return (
      pathName === "/login" ||
      pathName === "/forgot-password" ||
      pathName === "/reset-password"
    );
  }, [pathName]);

  useEffect(() => {
    if (scrollTop > 100 || isPageWithoutTransparentHeader()) {
      setIsHeaderTransparent(true);
    } else {
      setIsHeaderTransparent(false);
    }
  }, [scrollTop, isPageWithoutTransparentHeader]);

  useEffect(() => {
    const listener = document.addEventListener("scroll", (e) => {
      setScrollTop(document.scrollingElement.scrollTop);
    });

    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, []);

  return (
    <div dir={dir}>
      <Home>
        <ScrollToTop />
        <SuccessAlert />
        <ErrorAlert />
        <NavMenu isHeaderTransparent={isHeaderTransparent} />
        <Switch>
          {/*
            <Route path="/en" component={MainPart} />
            <Route path="/ar" component={MainPart} />
          */}

          {/*our urls */}
          <Route path="/register" component={RegisterPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />

          <AppRoute
            path="/profile/tournaments/:id"
            component={TournamentHistory}
          />
          <AppRoute path="/profile/tournaments" component={Profile} />
          <AppRoute path="/profile/edit" component={Profile} />

          <AppRoute path="/faq" component={Faq} />
          <AppRoute path="/our-support" component={OurSupport} />
          <AppRoute path="/broadcast-terms" component={BroadcastTerms} />

          <AppRoute
            path={`/tournaments/create-custom-tournament/:id/step-2`}
            component={CreateCustomTournamentStep2}
          />
          <AppRoute
            path={`/tournaments/create-custom-tournament/:id/step-3`}
            component={CreateCustomTournamentStep3}
          />
          <AppRoute
            exact
            path={`/tournaments/create-custom-tournament`}
            component={CreateCustomTournamentStep1}
          />

          <AppRoute
            path={`/tournaments/update-custom-tournament/:id/step-1`}
            component={CreateCustomTournamentStep1}
          />

          <AppRoute
            path={`/tournaments/update-custom-tournament/:id/step-2`}
            component={CreateCustomTournamentStep2}
          />

          <AppRoute
            path={`/tournaments/update-custom-tournament/:id/step-3`}
            component={CreateCustomTournamentStep3}
          />

          <AppRoute
            path={`/tournaments/:game/thank-you/:step`}
            component={ThankYou}
          />

          <AppRoute
            path={`/tournaments/:game/on-going/list`}
            component={OnGoingTournaments}
          />
          <AppRoute
            path={`/tournaments/:game/on-going/calendar`}
            component={OnGoingTournaments}
          />

          <AppRoute
            path={`/en/ongoing/:game/player/on-going/list`}
            component={OnGoingTournaments}
          />
          <AppRoute
            path={`/ar/ongoing/:game/player/on-going/list`}
            component={OnGoingTournaments}
          />
          <AppRoute
            path={`/en/ongoing/:game/player/on-going/calendar`}
            component={OnGoingTournaments}
          />
          <AppRoute
            path={`/ar/ongoing/:game/player/on-going/calendar`}
            component={OnGoingTournaments}
          />

          <AppRoute exact path={`/tournaments/:game`} component={HomePage} />
          <AppRoute
            exact
            path="/tournaments"
            component={LandingPageMultiGames}
          />

          <AppRoute
            path="/en/user-game-select"
            component={PlayersLandingPageMultiGames}
          />
          <AppRoute
            path="/ar/user-game-select"
            component={PlayersLandingPageMultiGames}
          />

          <Route path="/en/player/:game" component={PlayerHome} />
          <Route path="/ar/player/:game" component={PlayerHome} />

          <Route path="/terms-of-use" component={StaticPage} />
          <Route path="/privacy-policy" component={StaticPage} />

          <Route path="/error404" component={Error404} />

          <AppRoute path="/" component={LandingPage} />
          <Redirect to={"/"} />
        </Switch>
        <Footer />
      </Home>
    </div>
  );
}

export default App;
