import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { formValidations } from "../../utils/formValidations";
import Input from "../../components/Input";
import * as Actions from "../../store/actions";

import "../../styles/ForgotPassword.scss";
import { Helmet } from "react-helmet";

function ForgotPasswordPage() {
  const initialValues = {
    email: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { ...initialValues } });

  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(Actions.ForgotPassword(data.email)).then(() => {
      reset({ ...initialValues });
      history.push("/login");
    });
  };

  const { t } = useTranslation("ProjectTranslation");

  return (
    <div className="forgot-password-page">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>

      <div className="formContainer">
        <div className="container">
          <h1 className="heading">{t("forgot_password.forgot_password")}</h1>
          <p className="subHeading">{t("forgot_password.subheader")}</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-8 col-12">
                <Input
                  hiddenLabel
                  type="email"
                  label={t("form.email")}
                  placeholder={t("form.email")}
                  defaultValue=""
                  {...register("email", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(3),
                    pattern: formValidations.validateEmail(),
                  })}
                  errors={errors.email?.message}
                  inputCols={12}
                />
              </div>
            </div>

            <div className="row mt-4 pt-3">
              <button
                type="submit"
                className="btn btn-primary custom-button mt-5"
              >
                {t("form.submit")}
              </button>
            </div>

            <div className="row">
              <Link className="back-to-sign-in" to={`/login`}>
                {t("form.back_to_sign_in")}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
