const locale = {
  header: {
    ONGOING: "ACTIVE TOURNAMENTS",
    RFP: "RIOT TOURNAMENTS",
    FAQ: "FAQ",
    SIGN_IN: "SIGN IN",
    SIGN_OUT: "SIGN OUT",
    profile: "MY PROFILE",
    TOURNAMENT_APPLICATION: "SUBMIT APPLICATION",
    VALORANT: "VALORANT",
    LOL: "LEAGUE OF LEGENDS",
    WILD_RIFT: "WILD RIFT",
    LOR: "LOR",
    TFT: "TFT",
  },
  footer: {
    copy_rights: "2023 Riot Games, All Rights Reserved.",
    termsOfUse: "Terms of Use",
    privacyPolicy: "Privacy Policy",
    have_questions: "Do you have questions?",
    broadcast_terms: "Broadcast Terms",
  },
  register: {
    header: "CREATE A TOD ACCOUNT",
    subheading:
      "By creating a TOD account, you will gain to our tournament on-demand platform where you can submit applications to host events and benefit from a wide range of support services.",
    organizer_information: "organizer information",
  },
  form: {
    username: "Username",
    password: "Password",
    passwordPlaceholder: "Password",
    contactPerson: "Contact Person",
    contactPersonPlaceholder: "Contact Person Name",
    companyName: "Company Name",
    address: "Address",
    addressPlaceholder: "Company Address",
    email: "Email Address",
    emailPlaceholder: "E.g. example@email.com",
    website: "Website",
    websitePlaceholder: "Company Website",
    register: "Register",
    createAccount: "CREATE ACCOUNT",
    phoneLabel: "country and phone number",
    sign_in: "Sign In",
    forgotten_your_password: "Forgotten your password?",
    submit: "Submit",
    back_to_sign_in: "Back to Sign In",
    confirm_password: "Confirm Password",
    tournament_english_name: "TOURNAMENT NAME IN ENGLISH",
    tournament_english_name_placeholder:
      "Enter the name of the tournament in English",
    tournament_arabic_name: "TOURNAMENT NAME IN ARABIC",
    tournament_arabic_name_placeholder:
      "Enter the name of the tournament in Arabic",
    organizer_website_url: "WEBSITE",
    organizer_website_url_placeholder:
      "Tournament organizer's official webiste and/ or social media channels",
    tournament_format: "tournament format",
    tournament_type: "Tournament Type",
    tournament_type_sub: "Where is your tournament going to be held?",
    duration_label: "Duration",
    duration_label_sub: "How long is your tournament going to last?",
    announcement_date: "Announcement Date",
    announcement_date_note: "When is your tournament going to be announced?",
    start_date: "Start Date",
    start_date_note: "When will your tournament start?",
    end_date: "End Date",
    end_date_note: "When will your tournament end?",
    broadcast: "Broadcast",
    broadcast_sub:
      "Will your tournament be broadcasted on any streaming platforms?",
    broadcast_days: "Broadcast Days",
    broadcast_days_sub:
      "When is your tournament going to be broadcasted? Please select the dates in the calendar.",
    tournament_size: "Tournament Size",
    tournament_size_sub: "How many teams are participating in the tournament?",
    prize_type: "Prize Type",
    participants_fees: "Participant Fees",
    participants_fees_sub:
      "Will there be participation fees charged for players to join your event?",
    commercialisation: "Commercialisation",
    commercialisation_sub:
      "Will brands and sponsors be visible in the tournament broadcast layouts, marketing campaigns, etc.?",
    visible_sponsors: "Visible sponsors",
    your_answer: "Your answer",
    organisation_plan: "Organisation Plan & Additional Info",
    organisation_plan_sub:
      "If you have any additional information you would like to share with us, please provide it here. Type “N/A” if you don’t have any.",
    additional_files: "additional files",
    tv_broadcast: "TV Broadcast",
    tv_broadcast_sub:
      "Will your tournament be broadcasted on any television channels?",
    prize_value: "Prize Value (USD)",
    special_approval: "Special Approval",
    broadcast_platforms: "STREAMING PLATFORM OF THE TOURNAMENT",
    broadcast_platforms_sub:
      'Which streaming platform(s) will your tournament be broadcasted on? If "Other," please specify.',
    broadcast_platforms_url: "Broadcast Channel URL",
    streaming_platform: "Streaming platform",
    broadcast_languages: "Broadcast Language(s)",
    broadcast_languages_sub:
      'What language(s) will be used for the tournament broadcast? If "Other," please specify.',
    language: "Language",
    update: "Update",
    edit: "Edit",
    save: "Save",
    new_password: "New Password",
    confirm_new_password: "Confirm new Password",
    please_specify: "Please specify",
    please_wait_a_moment: "Please wait a moment...",
  },
  landingPage: {
    for_the_players: "I'M A PLAYER",
    ongoing_tournaments: "I WANT TO FIND AND REGISTER FOR TOURNAMENTS",
    for_the_tournament_organizers: "I'M A TOURNAMENT ORGANIZER",
    apply_for_the_tournament_license:
      "I WANT TO GET MY TOURNAMENTS LICENSED BY RIOT GAMES",
    browse_tournaments: "browse tournaments",
    apply_here: "APPLY HERE",
  },
  landingPageMultiGames: {
    tournament_on_demand_1: "BECOME THE NEXT \n",
    tournament_on_demand_2: "RIOT GAMES",
    tournament_on_demand_3: "PARTNER!",
    tournament_on_demand_text:
      "Welcome to TOD, your on-demand tournament website. Here, we can help you secure licensing from Riot Games and attract more players. To kick off your journey, please select the game you'd like to host a tournament for from the options below.",
    select_games: "Select Game",
    ongoing_tournaments: "ongoing tournaments",
  },
  PlayerLandingPageMultiGames: {
    tournament_on_demand: "BROWSE RIOT GAMES TOURNAMENTS IN THE MENA REGION",
    tournament_on_demand_text:
      "Welcome to TOD! Here, you can navigate through different categories of tournaments held in the MENA region and register with just a few clicks. So, what are you waiting for? Select your favorite game and get ready to unleash your competitive skills!",
  },
  login: {
    sign_in: "Sign In",
    or: "or",
    register: "Register",
  },
  forgot_password: {
    forgot_password: "Forgot Password",
    subheader:
      "Enter your email, and we’ll send you a link to reset your password.",
  },
  reset_password: {
    enter_new_password: "Enter New Password",
  },
  thank_you: {
    gg_wp: "GGWP!",
    subHeading_1: "Thank you for submitting your application!",
    text_1:
      "Your application has been received and our team will be reviewing it shortly. Once approved, you will be able to proceed with the second step of your application. So, keep an eye on your email inbox!",
    subHeading_2: "Thank you for submitting your broadcast and social assets!",
    text_2:
      "Once your assets have been approved, you will move on to the final step of the application process. We will be in touch with you shortly regarding the status of your application. Good luck!",
    subHeading_3: "Thank you for submitting the tournament license agreement!",
    text_3:
      "Your tournament license application has been successfully submitted and we will be reviewing it shortly. Congratulations on making it to the final step!",
    home_page: "Back to Home Page",
    thank_you: "Thank You!",
  },
  registerPopup: {
    register: "SIGN IN OR\nCREATE AN ACCOUNT",
    register_text:
      "Already have an account? Sign in now to resume your gaming journey with us. Don't have an account yet? No problem! It only takes a few minutes to create one and start exploring all the tournaments our platform has to offer.",
    register_btn: "CREATE ACCOUNT",
    sign_in_btn: "sign in",
  },
  custom_tournament_form_step_1: {
    tournament_application: "YOUR TOURNAMENT APPLICATION",
    subheading:
      "Your journey to become a Riot Games partner starts here! Fill in all the necessary fields and submit your application for our review.",
    tournament_information: "Tournament Information",
    mena_information_part_1:
      "Riot Games MENA currently offers single-country or multi-country tournament licenses across the following three regions:",
    select_the_game: "SELECT THE GAME",
    regions_label: "FOCUS COUNTRY",
    regions_label_sub:
      "What country or region are you targeting? If your tournament includes all MENA countries, select MENA. Otherwise, please manually select the specific countries or regions you are targeting.",
    tournament_format_note:
      'Please select the format of your tournament. If not listed, please select "Other."',
    sponsors_note:
      "Please list all brands, products or companies that will be visible in the tournament.",
    additional_files_note:
      "*Please attach a presentation (in PDF format) that describes the format of your tournament, including the number of teams, any professional teams participating, the highest team tier being targeted (semi-pro, amateur), and the structure of the tournament that will dictate the outcome (round-robin, Swiss seeding, single-elimination, etc.). Also, please list all brands, products, or companies that will be visible during the tournament.",
    riot_games_support: "Riot Games Support",
    riot_games_support_note:
      "We’re here to help you organize an exceptional tournament.\nYou may select up to 3 publishing support services.\nFor more info, please check out ",
    our_support_page: "Our Support page",
    broadcast_information: "broadcast information",
    broadcast_information_note:
      "Prohibited Sponsors and Advertisers\nTournament Organizer shall not place, display or post advertisements which (a) contain any material which would reasonably be seen as unlawful, libelous, obscene, indecent, threatening, intimidating, harassing, or otherwise objectionable, or harmful to the goodwill of the Game of Valorant, or any Riot entity, or their respective products and/or (b) accept sponsorships from or feature any of those companies listed below:\n1. Any video game or video game developer or publisher;\n2. Prescription or non-“over-the-counter” drugs;\n3. Firearms, handguns, ammunition, or peripherals;\n4. Pornography or pornographic products;\n5. Tobacco, tobacco products, or paraphernalia;\n6. Alcohol products, or other intoxicants the sale or use of which is regulated by law;\n7. Sellers or marketplaces of virtual items known to be counterfeit or illegal sellers thereof, or who are otherwise in breach of the Valorant Game Terms of Use;\n8. Businesses engaged in gambling, wagering, bookmaking, or sports or esports betting, including fantasy sports or esports;\n9. Political campaigns;\n10. Charities that endorse particular religious or political positions or are not reputable (by way of example, Red Cross, Stand-Up to Cancer and other mainstream charities shall be considered reputable);\n11. Cryptocurrencies or any unregulated financial instruments or markets;\n12. Other categories as may be provided by Riot.",
    broadcast_platforms_url_note: `Enter URLs separated by a comma ","`,
    prize_value_note:
      "Prizes greater than USD 10,000 require special approval from Riot Games.",
  },
  custom_tournament_form_step_2: {
    heading: "Broadcast And Social Assets",
    form_heading: "STEP 2: SUBMIT YOUR PROMOTIONAL ASSETS",
    form_note:
      "In order for our team to grant final approval, you need to provide us with the required assets for broadcast and social media.",
    form: {
      assets_link: "LINK TO THE ASSETS",
      link: "Link",
      pdf_presentation: "PDF PRESENTATION(S)",
      pdf_presentation_note:
        "*Make sure your presentation contains all the creatives and is attached in PDF format.",
      tournament_keyart: "KEY ART OF THE TOURNAMENT",
      tournament_keyart_note:
        "*Once approved, this image will appear on the website and must have a resolution of 1920x1080 pixels at 720dpi.",
      submit_your_assets: "SUBMIT",
    },
  },
  custom_tournament_form_step_3: {
    heading: "Tournament License Agreement",
    form_heading: "YOU'RE ONE STEP AWAY FROM SUBMITTING YOUR APPLICATION!",
    organiser_details: "Organiser Details",
    tournament_details: "Tournament Details",
    tournament_prizes: "Tournament Prizes",

    form: {
      organiser_company_name: "Organiser Company Name",
      company_address: "Company Address",
      company_registration_number: "Company Registration Number",
      contact_email: "Contact Email Address",
      tournament_name: "Tournament Name",
      tournament_address: "Tournament Address",
      start_date: "Start Date",
      end_date: "End Date",
      tournament_territory: "Tournament Territory",
      broadcast_channel_URL: "Broadcast Channel URL",
      prize_type: "Prize Type",
      prize_value: "Prize Value",
      tournament_game: "Tournament Game",
      tournament_type: "Tournament Type",
    },
  },
  profile_tournaments: {
    heading: "My Profile",
    page_heading: "My Tournament Applications",
    edit_information: "Edit Information",
    name: "Name",
    step: "Step",
    latest_update: "Latest Update",
    status: "Status",
    custom_tournaments: "Custom Tournaments",
    edit_submission: "Edit Submission",
    download_assets: "Download Assets",
    download_contract: "Download Contract",
    no_current_tournaments: "No Current Tournaments",
    back_to_home: "Back to Home Page",
  },
  edit_profile_data: {
    subheader: "edit your information",
    form: {
      currentPassword: "Current Password",
      newPassword: "New Password",
      userName: "Username",
      email_address: "Email Address",
      email_address_placeholder: "E.g. example@email.com",
      update_password: "Update Password",
    },
  },
  on_going_tournaments: {
    list: "List",
    calendar: "Calendar",
    ongoing_tournaments: "ACTIVE TOURNAMENTS",
    subheading:
      "Check out the calendar of the active tournaments held by our licensed partners.",
    region: "Region",
    VRC: "VRC",
    arabianLeague: "Arabian League",
    all: "All",
  },
  yes_no: {
    all: "All",
    yes: "Yes",
    no: "No",
  },
  tournament_card: {
    days_left_for_register: "days left for register",
    visit_registration_website: "REGISTER HERE",
    tournament_started: "Registration Closed",
  },
  calendar: {
    heading: "CALENDAR",
    duration: "Duration",
    broadcast_days: "Broadcast Dates",
  },
  playerHome: {
    banner: {
      heading: "BROWSE AND REGISTER FOR TOURNAMENTS",
      subheading:
        "Whether you're a seasoned pro or just starting out, you'll find the perfect tournament for you. Discover and join a variety of Riot-licensed tournaments in the MENA region and put your skills to the test in the competitive scene!",
      ongoing_tournaments: "Ongoing Tournaments in MENA",
    },
    ongoing_tournaments: "ONGOING TOURNAMENTS",
    view_all: "View All",
  },
};

export default locale;
