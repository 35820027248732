import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import "../styles/ThankYou.scss";
import { Helmet } from "react-helmet";

function ThankYou() {
  const { t } = useTranslation("ProjectTranslation");
  const history = useHistory();
  const { game } = useParams();
  const { step } = useParams();

  const openHomePage = () => {
    console.log("game", game);
    history.push(`/tournaments/${game}`);
  };

  return (
    <section className={`thank-you-page ${game}`}>
      <Helmet>
        <title>Thank You</title>
      </Helmet>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-12">
            <hr />

            <div className="d-flex flex-column justify-content-center align-items-center">
              <h1 className="heading">{t("thank_you.thank_you")}</h1>
              <p className="subHeading">{t(`thank_you.subHeading_${step}`)}</p>
              <p className="text">{t(`thank_you.text_${step}`)}</p>
            </div>

            <hr />
          </div>
        </div>

        <div className="row justify-content-center">
          <button
            type="button"
            className="btn btn-primary custom-button home-page-button"
            onClick={() => openHomePage()}
          >
            {t("thank_you.home_page")}
          </button>
        </div>
      </div>
    </section>
  );
}

export default ThankYou;
