import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "../styles/Tooltip.scss";

function CustomTooltip({
  content,
  placement = "right",
  children,
  tooltipClasses = "",
}) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip className={tooltipClasses}>{content}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
}
export default CustomTooltip;
