import React, { forwardRef } from "react";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/high-res.css";
import "../styles/input.scss";

const Input = forwardRef(
  (
    {
      type = "text",
      placeholder = "",
      name,
      label,
      errors,
      onChange,
      onBlur,

      topLabel = false,
      labelCols = 3,
      inputCols = 9,
      hiddenLabel = false,

      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={`input-form-group phone-input-form-group form-group row ${
          topLabel ? "top-label-container" : ""
        }`}
      >
        {!hiddenLabel && (
          <label
            htmlFor={name}
            className={`col-12 col-form-label mt-0 ${
              topLabel ? "top-label col-lg-12" : `col-lg-${labelCols}`
            }`}
          >
            {label}
          </label>
        )}
        <div
          className={`col-lg-${inputCols} col-12 ${
            hiddenLabel ? "col-sm-12" : `col-lg-${inputCols}`
          }`}
        >
          <PhoneInput
            ref={ref}
            inputProps={{
              id: name,
              name: name,
              ref: ref,
            }}
            country={"ae"}
            className={`${errors ? "applicant-phone-error" : ""}`}
            inputClass="applicant-phone"
            placeholder={placeholder}
            countryCodeEditable={false}
            onChange={onChange}
            onBlur={onBlur}
            aria-invalid={errors ? "true" : "false"}
            enableSearch={true}
            {...rest}
          />

          {errors && <p className="input-error">* {errors}</p>}
        </div>
      </div>
    );
  }
);

export default Input;
