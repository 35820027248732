import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import * as Actions from "../store/actions";

import "../styles/Alerts.scss";

function SuccessAlert() {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const isError = useSelector(({ common }) => common.isError);
  const errorMessage = useSelector(({ common }) => common.errorMessage);

  const closeErrorAlert = () => {
    dispatch(Actions.saveErrorMessage({ isError: false, errorMessage: "" }));
  };

  useEffect(() => {
    setShow(isError);
  }, [isError]);

  return (
    <>
      {show && (
        <div className="custom-alert toast-container position-fixed start-50 translate-middle-x">
          <div className="toast error show w-100">
            <div className="toast-body d-flex flex-nowrap justify-content-between align-items-center">
              <p className="message mb-0 me-3 w-100">{errorMessage}</p>
              <i
                className="bi bi-x-lg close-icon"
                onClick={closeErrorAlert}
              ></i>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SuccessAlert;
