import { useEffect } from "react";
import { useHistory, useParams } from "react-router";

export default function useIsStepCanOpen(pageStep, tournament) {
  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    if (!id) return;

    if (
      tournament.step === 3 &&
      (tournament.status === 3 || tournament.status === 4)
    ) {
      history.replace(`/profile/tournaments/${tournament.id}`);
    } else if (pageStep && tournament?.step && pageStep !== tournament?.step) {
      history.replace(
        `/tournaments/update-custom-tournament/${tournament.id}/step-${tournament.step}`
      );
    }
  }, [pageStep, tournament, history, id]);

  return true;
}
