import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import valorant from "../../assets/img/player-valorant-avatar.png";
import valorantLogo from "../../assets/img/valorant-logo.png";
import lol from "../../assets/img/player-league-of-legends-avatar.png";
import lolLogo from "../../assets/img/league-of-legends-logo.png";
import wildRift from "../../assets/img/player-wild-rift-avatar.png";
import wildRiftLogo from "../../assets/img/wild-rift-logo.png";
import arrowRight from "../../assets/icons/multiGamesSelectGamesArrowRight.png";

import { GAMES } from "../../utils/app.constants";

import "../../styles/PlayersLandingPageMultiGames.scss";
import i18n from "../../i18n";
import { Helmet } from "react-helmet";

function PlayersLandingPageMultiGames() {
  const { t } = useTranslation("ProjectTranslation");
  const history = useHistory();

  const selectGame = (game) => {
    const lang = i18n.language;
    history.push(`/${lang}/player/${game}`);
  };

  return (
    <div className="player-multi-games-page">
      <Helmet>
        <title>Players - Game Select</title>
      </Helmet>

      <div className="bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9">
              <h1>{t("PlayerLandingPageMultiGames.tournament_on_demand")}</h1>
            </div>
            <div className="col-12">
              <div className="row justify-content-between">
                <p className="col-8">
                  {t("PlayerLandingPageMultiGames.tournament_on_demand_text")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="games-section">
          <div className="container ">
            <div className="row justify-content-center">
              <div
                className="col-12 col-md-9 col-lg-6 col-xxl-4"
                onClick={() => selectGame(GAMES.VALORANT)}
              >
                <div className="game valorant d-flex justify-content-between">
                  <div className="game-logo d-flex justify-content-between">
                    <img
                      src={valorantLogo}
                      alt="valorant-logo"
                      className="logo"
                    />
                    <img className="arrow" src={arrowRight} alt="arrow-right" />
                  </div>
                  <img className="avatar" src={valorant} alt="lol" />
                </div>
              </div>
              <div
                className="col-12 col-md-9 col-lg-6 col-xxl-4 mt-2 mt-lg-0"
                onClick={() => selectGame(GAMES.LOL)}
              >
                <div className="game lol">
                  <div className="game-logo lol-game-logo d-flex justify-content-between">
                    <img src={lolLogo} alt="lol-logo" className="logo" />
                    <img className="arrow" src={arrowRight} alt="arrow-right" />
                  </div>
                  <img className="avatar lol-avatar" src={lol} alt="lol" />
                </div>
              </div>
              <div
                className="col-12 col-md-9 col-lg-6 col-xxl-4 mt-2 mt-xxl-0"
                onClick={() => selectGame(GAMES.WILD_RIFT)}
              >
                <div className="game wild-rift">
                  <div className="game-logo d-flex justify-content-between">
                    <img
                      src={wildRiftLogo}
                      alt="wild-rift-logo"
                      className="logo"
                    />
                    <img className="arrow" src={arrowRight} alt="arrow-right" />
                  </div>
                  <img className="avatar" src={wildRift} alt="lol" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayersLandingPageMultiGames;
