import { DateObject } from "react-multi-date-picker";

const required = (length) => {
  return "Required";
};

const minLength = (length) => {
  return {
    value: length,
    message: `Minimum length should be ${length}`,
  };
};

const maxLength = (length) => {
  return {
    value: length,
    message: `text exceeds ${length} characters limit`,
  };
};

const address = (length) => {
  return {
    value: length,
    message: `Your address must contain at least ${length} characters.`,
  };
};

const minValue = (value) => {
  return {
    value: value,
    message: `Value should be more than or equal ${value}`,
  };
};

const maxValue = (value) => {
  return {
    value: value,
    message: `Value should be less than or equal ${value}`,
  };
};

const validateLetters = () => {
  return {
    value: /^[A-Za-z]+$/i,
    message: "Should contain only letters",
  };
};

const validateLettersWithSpaces = () => {
  return {
    value: /^[A-Za-z ]+$/i,
    message: "Should contain only letters",
  };
};

const validateLettersWithSpacesAndNumbers = () => {
  return {
    value: /^[A-Za-z0-9 ]+$/i,
    message: "Should contain only letters and numbers",
  };
};

const validateOnlyArabicLettersWithSpaces = () => {
  return {
    value: /^[\u0600-\u06FF\s ]+$/i,
    message: "Should contain only Arabic letters",
  };
};

const validateOnlyArabicLettersWithSpacesAndNumbers = () => {
  return {
    value: /^[\u0600-\u06FF\s0-9 ]+$/i,
    message: "Should contain only Arabic letters and numbers",
  };
};

const validateNumbers = (length) => {
  return {
    value: /^[0-9]+$/i,
    message: "Should contain only numbers",
  };
};

const validateNumberMinValue = (value, number) =>
  value >= number || `The lowest number is ${number}`;

const validateLettersAndNumbersAndSpaces = () => {
  return {
    value: /^[A-Za-z0-9 ]+$/i,
    message: "Should contain only letters and numbers",
  };
};

const validatePassword = () => {
  return {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{6,15}$/,
    message:
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
  };
};

const validateConfirmPassword = (password, confirmPassword) => {
  return confirmPassword === password || "Passwords do not match";
};

const validateEmail = () => {
  return {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Please enter a valid email",
  };
};

const validateURL = () => {
  return {
    value:
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    message: "Please enter a valid URL",
  };
};

const validateStartDate = (announce_date, start_date, end_date) => {
  return (
    (new DateObject(announce_date, "DD/MM/YYYY").valueOf() <
      new DateObject(start_date, "DD/MM/YYYY").valueOf() &&
      new DateObject(start_date, "DD/MM/YYYY").valueOf() <
        new DateObject(end_date, "DD/MM/YYYY").valueOf()) ||
    "Start Date should be after Announce Date and before End Date"
  );
};

const maxArrayLength = (array, length) =>
  array.length <= length || `You can select only ${length} different options`;

const supportOptionsValidation = (options, length) =>
  options.length <= length ||
  `You can select up to ${length} different services only.`;

export const formValidations = {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  validateLetters,
  validateLettersWithSpaces,
  validateLettersWithSpacesAndNumbers,
  validateOnlyArabicLettersWithSpaces,
  validateOnlyArabicLettersWithSpacesAndNumbers,
  validateNumbers,
  validateNumberMinValue,
  validateLettersAndNumbersAndSpaces,
  validatePassword,
  validateConfirmPassword,
  validateEmail,
  validateURL,
  validateStartDate,
  maxArrayLength,
  supportOptionsValidation,
  address,
};
