import { MeActions } from "../actions/types";

const initialState = {
  loading: false,
  featured: {},
  custom_tournaments: [],
  custom_tournaments_total_pages: 0,
  user: {
    userName: "",
    contactPerson: "",
    companyName: "",
    phone: "",
    address: "",
    email: "",
    website: "",
  },
  userDataLoading: false,
  isFetchingStartedBefore: false,
};

const tournamentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MeActions.USER_DATA_LOAD: {
      return {
        ...state,
        userDataLoading: true,
        isFetchingStartedBefore: true,
      };
    }
    case MeActions.USER_DATA_READY: {
      return {
        ...state,
        userDataLoading: false,
      };
    }
    case MeActions.USER_DATA_SAVE: {
      return {
        ...state,
        user: action.payload,
        userDataLoading: false,
      };
    }
    case MeActions.OWNER_TOURNAMENTS_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case MeActions.OWNER_TOURNAMENTS_READY: {
      return {
        ...state,
        loading: false,
      };
    }
    case MeActions.OWNER_TOURNAMENTS_CUSTOM_SAVE: {
      return {
        ...state,
        loading: false,
        custom_tournaments: action.payload.data,
        custom_tournaments_total_pages: action.payload.pages_count,
      };
    }
    case MeActions.OWNER_TOURNAMENTS_FEATURED_SAVE: {
      return {
        ...state,
        loading: false,
        featured: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default tournamentsReducer;
