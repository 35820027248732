import { MapActions } from "../actions/types";

const initialState = {
  loading: false,
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case MapActions.MAP_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case MapActions.MAP_READY: {
      return {
        ...state,
        loading: false,
      };
    }
    case MapActions.MAP_MARKER_DATA_SAVE: {
      return {
        ...state,
        loading: false,
        marker_datas: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default mapReducer;
