import { axiosService as API } from "../../services/axiosInterceptors";
import { CountryActions } from "./types";

export const fetchCountries = () => {
  return async (dispatch, getState) => {
    dispatch(loadCountries);
    API.get("/api/common/countries")
      .then((response) => {
        dispatch(saveCountries(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(readyCountries());
      });
  };
};

export const fetchCityInCountry = (selectedCountry) => {
  return async (dispatch, getState) => {
    dispatch(loadCountries);
    return API.get("/api/common/countries/city/" + selectedCountry)
      .then((response) => {
        dispatch(saveCitiesInCountry(response.data));
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(readyCountries());
        return null;
      });
  };
};

export const loadCountries = () => ({
  type: CountryActions.COUNTRY_LOAD,
});

export const readyCountries = () => ({
  type: CountryActions.COUNTRY_READY,
});

export const saveCountries = (list) => ({
  type: CountryActions.COUNTRY_SAVE,
  payload: list,
});

export const saveSelectedCountry = (data) => ({
  type: CountryActions.COUNTRY_SELECTED_SAVE,
  payload: data,
});

export const saveCitiesInCountry = (list) => ({
  type: CountryActions.COUNTRY_CITY_SAVE,
  payload: list,
});
