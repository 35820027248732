import { TournamentsActions } from "../actions/types";

const initialState = {
  loading: false,
  featured: {},
  list: {},
  selected: {},
  custom_tournaments_enums: {
    formate: [],
    duration: [],
    tier: [],
    prize_type: [],
    broadcast_platforms: [],
    broadcast_languages: [],
  },
  format_cont: [],
  reserved_broadcast_days: [],
  support_options: [],
  tournament_details: {
    id: "",
    tournament_english_name: "",
    tournament_arabic_name: "",
    details: [],
  },
  onGoingTournaments: [],
  onGoingTournamentsTotalPages: 0,
  onGoingTournamentsCalendar: [],
  onGoingTournamentsCalendarTotalPages: 0,
  onGoingTournamentsLoading: false,
};

const tournamentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TournamentsActions.TOURNAMENTS_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case TournamentsActions.TOURNAMENTS_READY: {
      return {
        ...state,
        loading: false,
      };
    }
    case TournamentsActions.TOURNAMENTS_SAVE: {
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    }
    case TournamentsActions.TOURNAMENTS_FEATURED_SAVE: {
      return {
        ...state,
        loading: false,
        featured: action.payload,
      };
    }
    case TournamentsActions.TOURNAMENTS_SELECTED_SAVE: {
      return {
        ...state,
        loading: false,
        selected: action.payload,
      };
    }
    case TournamentsActions.SAVE_CUSTOM_TOURNAMENT_ENUMS: {
      return {
        ...state,
        custom_tournaments_enums: action.payload,
      };
    }
    case TournamentsActions.SAVE_TOURNAMENT_FORMAT_CONT: {
      return {
        ...state,
        format_cont: action.payload,
      };
    }
    case TournamentsActions.SAVE_TOURNAMENT_BROADCAST_DAYS: {
      return {
        ...state,
        reserved_broadcast_days: action.payload,
      };
    }
    case TournamentsActions.SAVE_TOURNAMENT_SUPPORT_OPTIONS: {
      return {
        ...state,
        support_options: action.payload,
      };
    }
    case TournamentsActions.SAVE_CUSTOM_TOURNAMENT_DETAILS: {
      return {
        ...state,
        tournament_details: action.payload,
      };
    }
    case TournamentsActions.ONGOING_TOURNAMENTS_LOAD: {
      return {
        ...state,
        onGoingTournamentsLoading: true,
      };
    }
    case TournamentsActions.ONGOING_TOURNAMENTS_READY: {
      return {
        ...state,
        onGoingTournamentsLoading: false,
      };
    }
    case TournamentsActions.SAVE_ONGOING_TOURNAMENTS: {
      return {
        ...state,
        onGoingTournaments:
          action.payload.page === 0
            ? action.payload.tournaments
            : [...state.onGoingTournaments, ...action.payload.tournaments],
        onGoingTournamentsTotalPages: action.payload.pages,
        onGoingTournamentsLoading: false,
      };
    }
    case TournamentsActions.SAVE_ONGOING_TOURNAMENTS_CALENDAR: {
      return {
        ...state,
        onGoingTournamentsCalendar:
          action.payload.page === 0
            ? action.payload.tournaments
            : [
                ...state.onGoingTournamentsCalendar,
                ...action.payload.tournaments,
              ],
        onGoingTournamentsCalendarTotalPages: action.payload.pages,
        onGoingTournamentsLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default tournamentsReducer;
