import React, { useEffect, useState } from "react";

import { GAMES } from "../../utils/app.constants";
import HIWLol1 from "../../assets/img/HIW-lol-1.webp";
import HIWLol2 from "../../assets/img/HIW-lol-2.webp";
import HIWLol3 from "../../assets/img/HIW-lol-3.webp";
import HIWVal1 from "../../assets/img/HIW-val-1.webp";
import HIWVal2 from "../../assets/img/HIW-val-2.webp";
import HIWVal3 from "../../assets/img/HIW-val-3.webp";
import HIWWr1 from "../../assets/img/HIW-wr-1.webp";
import HIWWr2 from "../../assets/img/HIW-wr-2.webp";
import HIWWr3 from "../../assets/img/HIW-wr-3.webp";

import "../../styles/Home/HowItWorks.scss";

function HowItWorks({ game }) {
  const [selectedImages, setSelectedImages] = useState([]);
  const cardsText = [
    {
      title: "Apply for a\ntournament license",
      description:
        "Follow a simple three-step process to automatically generate your Riot Games license agreement. Provide us with your tournament information, broadcast and social assets, then sign the contract.",
    },
    {
      title: "Become\nOur partner",
      description:
        "Upon signing the contract, you will automatically become a partner of Riot Games, granting you access to a wide range of publishing support services provided by us.",
    },
    {
      title: "Get\nFeatured!",
      description:
        "Your tournament will be displayed on the TOD platform tournament calendar, allowing players to stay updated with the latest tournaments and potentially register and participate in them.",
    },
  ];

  useEffect(() => {
    const lolImages = [HIWLol1, HIWLol2, HIWLol3];
    const valImages = [HIWVal1, HIWVal2, HIWVal3];
    const wrImages = [HIWWr1, HIWWr2, HIWWr3];

    if (game === GAMES.LOL) {
      setSelectedImages(lolImages);
    } else if (game === GAMES.VALORANT) {
      setSelectedImages(valImages);
    } else if (game === GAMES.WILD_RIFT) {
      setSelectedImages(wrImages);
    }
  }, [game]);
  return (
    <div className="container">
      <h2 className="mb-0 section-title">How it works?</h2>

      <div className="row">
        <div className="col-12 subtitle-container">
          <p className="subheading">
            Securing a Riot Games tournament license is mandatory to obtain the
            rights to feature our IPs in your events. Alongside access to
            publishing support services, having a Riot Games license ensures
            that your tournament gains visibility on our TOD platform, enabling
            players to discover upcoming tournaments.
          </p>
        </div>

        <div className="row">
          {selectedImages.map((image, index) => (
            <div
              className="col-12 col-xl-4 col-lg-6 col-md-12 col-12 mt-2 mt-5 mt-xl-0"
              key={image}
            >
              <div className={`HIW-card ${game} d-flex justify-content-end`}>
                <div className="HIW-index">{index + 1}</div>
                <img
                  className={`avatar avatar-${index + 1}`}
                  src={image}
                  alt={`how it works ${index + 1}`}
                />

                <div className="HIW-card-text-container">
                  <div className="HIW-card-text">
                    <p className="HIW-card-title">{cardsText[index].title}</p>
                    <p className="HIW-card-description">
                      {cardsText[index].description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
