import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { DateObject } from "react-multi-date-picker";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

import { formValidations } from "../../../utils/formValidations";
import Input from "../../../components/Input";
import AcceptTermsAndConditionsCheckbox from "../../../components/AcceptTermsAndConditionsCheckbox";
import Checkbox from "../../../components/Checkbox";
import Textarea from "../../../components/Textarea";
import FilesPicker from "../../../components/FilesPicker";
import FocusCountries from "../../../components/CustomTournamentForms/Step1/FocusCountries";
import DatePicker from "../../../components/DatePicker";

import {
  COUNTRIES_EXCEPT_MENA,
  TOTAL_FILES_SIZE_ERROR_MESSAGE,
  YES_NO,
} from "../../../utils/app.constants";
import { isOther, isTotalPDFsSizeValid } from "../../../utils/helpers/helpers";
import * as Actions from "../../../store/actions";

import useRequireAuth from "../../../hooks/useRequireAuth";

import "../../../styles/CustomTournamentForms.scss";
import { Helmet } from "react-helmet";
import useIsStepCanOpen from "../../../hooks/useIsStepCanOpen";

function CreateCustomTournamentStep1() {
  const [countriesInRegion, setCountriesInRegion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useRequireAuth();

  const addDays = (date, days = 1, format = "YYYY/MM/DD") => {
    const dateObject = new DateObject(date);
    dateObject.add(days, "days");

    return dateObject.format(format);
  };

  const initialValues = {
    focus_countries: [],
    focus_countries_other: [],
    game_name: "1",
    tournament_english_name: "",
    tournament_arabic_name: "",
    organizer_website_url: "",
    format: [],
    format_type: "1",
    format_type_other: "",
    duration: "1",
    announce_date: "",
    start_date: "",
    end_date: "",
    broadcast: YES_NO.Yes.toString(),
    broadcast_days: [],
    tier: "1",
    prize_type: "1",
    prize_value: "",
    chargeable: YES_NO.No.toString(),
    commercial: YES_NO.Yes.toString(),
    sponsors: "",
    additional_info: "",
    additional_files: [],
    selected_additional_files: [],
    support_options: [],
    broadcast_platforms: [],
    broadcast_platforms_other: "",
    broadcast_platforms_url: "",
    broadcast_languages: [],
    broadcast_languages_other: "",
    tv_broadcast: YES_NO.No.toString(),
    vrc: false,
    regions: [],
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({ defaultValues: { ...initialValues } });

  let { id } = useParams();

  const { t } = useTranslation("ProjectTranslation");

  const history = useHistory();
  const dispatch = useDispatch();

  const regions = useSelector((state) => state.regions.list);
  const countries = useSelector((state) => state.countries.list);
  const games = useSelector(({ common }) => common.games);
  const custom_tournaments_enums = useSelector(
    ({ tournaments }) => tournaments.custom_tournaments_enums
  );
  const tournament_type = useSelector(
    ({ tournaments }) => tournaments.format_cont
  );
  const data = require("../../../staticContent.json");
  const tournament_support_options = data.tournament_support_options;
  const reserved_broadcast_days = useSelector(
    ({ tournaments }) => tournaments.reserved_broadcast_days
  );
  const tournament = useSelector(({ tournaments }) => tournaments.selected);
  let available_broadcast_days = reserved_broadcast_days.filter(
    (day) =>
      !tournament?.broadcast_days
        ?.map((tournamentDay) => {
          const date = new Date(tournamentDay);
          const formattedDay = ("0" + date.getDate()).slice(-2);
          const formattedMonth = ("0" + (date.getMonth() + 1)).slice(-2);
          const formattedYear = date.getFullYear();
          return `${formattedDay}/${formattedMonth}/${formattedYear}`;
        })
        .includes(day)
  );

  useIsStepCanOpen(1, tournament);

  const focusCountries = watch("focus_countries");
  const focusCountriesOther = watch("focus_countries_other");
  const selectedGame = watch("game_name");
  const selectedFormatType = watch("format_type");
  const selectedDuration = watch("duration");
  const selectedBroadcast = watch("broadcast");
  const selectedTier = watch("tier");
  const selectedPrizeType = watch("prize_type");
  const selectedChargeable = watch("chargeable");
  const selectedCommercial = watch("commercial");
  const additional_files = watch("additional_files");
  const selected_additional_files = watch("selected_additional_files");
  const selectedBroadcastPlatforms = watch("broadcast_platforms");
  const selectedBroadcastLanguages = watch("broadcast_languages");
  const selectedTvBroadcast = watch("tv_broadcast");
  const announce_date = watch("announce_date");
  const start_date = watch("start_date");
  const end_date = watch("end_date");

  const tournamentRegions = () => {
    const regionsList = focusCountries.map((country) => {
      return countries.filter((item) => item.code === country)[0].regionId;
    });

    const uniqueRegions = [...new Set(regionsList)];

    if (focusCountriesOther.length) {
      const OtherRegion = regions.filter((item) => isOther(item.nameEn))[0].id;
      uniqueRegions.push(OtherRegion);
    }

    setValue("regions", uniqueRegions);

    return uniqueRegions;
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    const usedAction = id ? updateTournament : createTournament;

    const tournamentNewRegions = tournamentRegions();

    const newData = { ...data };

    const format_type_other_id = tournament_type?.filter((format) =>
      isOther(format.name)
    )[0]?.id;

    const broadcast_platform_other_id =
      custom_tournaments_enums?.broadcast_platforms?.filter((platform) =>
        isOther(platform.name)
      )[0]?.id;
    const broadcast_languages_other_id =
      custom_tournaments_enums?.broadcast_languages?.filter((language) =>
        isOther(language.name)
      )[0]?.id;

    if (newData?.format_type !== format_type_other_id.toString()) {
      newData.format_type_other = "";
    }

    if (
      !newData?.broadcast_platforms?.includes(
        broadcast_platform_other_id.toString()
      )
    ) {
      newData.broadcast_platforms_other = "";
    }

    if (
      !newData?.broadcast_languages?.includes(
        broadcast_languages_other_id.toString()
      )
    ) {
      newData.broadcast_languages_other = "";
    }

    usedAction({ ...newData, regions: tournamentNewRegions })
      .then(() => {
        const gameName = games.filter(
          (game) => game.id.toString() === selectedGame
        );

        history.push(`/tournaments/${gameName[0].code}/thank-you/1`);
        reset({ ...initialValues });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createTournament = (data) => {
    return dispatch(Actions.registerCustomTournamentStep1(data));
  };

  const updateTournament = (data) => {
    return dispatch(Actions.updateCustomTournamentStep1({ ...data, id }));
  };

  const addFile = async (file) => {
    if (isTotalPDFsSizeValid([...selected_additional_files, file])) {
      await setValue("selected_additional_files", [
        ...selected_additional_files,
        file,
      ]);

      if (isSubmitted) {
        await trigger("selected_additional_files");
      }
    } else {
      dispatch(
        Actions.saveErrorMessage({
          errorMessage: TOTAL_FILES_SIZE_ERROR_MESSAGE,
          isError: true,
        })
      );
    }
  };

  const removeFile = async (index) => {
    await setValue(
      "selected_additional_files",
      selected_additional_files.filter((file, fileIndex) => fileIndex !== index)
    );
    if (isSubmitted) {
      await trigger("selected_additional_files");
    }
  };

  const removeFileLink = async (index) => {
    deleteTournamentFile("additional_files", additional_files[index]);

    await setValue(
      "additional_files",
      additional_files.filter((file, fileIndex) => fileIndex !== index)
    );
    if (isSubmitted) {
      await trigger("additional_files");
    }
  };

  const deleteTournamentFile = (fieldName, url) => {
    dispatch(
      Actions.deleteTournamentFile({
        tournamentId: id,
        fieldName: fieldName,
        url: url,
      })
    );
  };

  const combineCountriesInRegion = useCallback((regions, countries) => {
    const combinedData = regions?.map((region) => ({
      regionId: region.id,
      regionName: region.nameEn,
      countries: countries?.filter((country) => country.regionId === region.id),
    }));

    setCountriesInRegion(combinedData);
  }, []);

  useEffect(() => {
    combineCountriesInRegion(regions, countries);
  }, [combineCountriesInRegion, regions, countries]);

  useEffect(() => {
    dispatch(Actions.fetchTournamentReservedBroadcastDays(selectedGame));
    setValue("broadcast_days", []);
  }, [dispatch, selectedGame]);

  useEffect(() => {
    if (id) {
      dispatch(Actions.getCustomTournamentById(id)).then((response) => {
        reset({
          ...response,
          focus_countries_other: response.focus_countries_other.map((code) => {
            const selectedCountry = COUNTRIES_EXCEPT_MENA.find(
              (country) => country.code === code
            );

            return {
              value: selectedCountry.code,
              label: selectedCountry.name,
            };
          }),
          game_name: response.game_name.toString(),
          announce_date: new DateObject(response.announce_date, "DD/MM/YYYY"),
          broadcast: response.broadcast.toString(),
          chargeable: response.chargeable.toString(),
          commercial: response.commercial.toString(),
          start_date: new DateObject(response.start_date, "DD/MM/YYYY"),
          end_date: new DateObject(response.end_date, "DD/MM/YYYY"),
          tier: response.tier.toString(),
          prize_type: response.prize_type.toString(),
          broadcast_days: response.broadcast_days.map(
            (day) => new DateObject(day, "DD/MM/YYYY")
          ),
          tv_broadcast: response.tv_broadcast.toString(),
          selected_additional_files: [],
        });
      });
    } else {
      dispatch(Actions.saveSelectedTournament({}));
    }
  }, [reset, dispatch, id]);

  return (
    <div className="custom-tournament-create-pages">
      <Helmet>
        <title>Tournament Information</title>
      </Helmet>

      <div className="header">
        <div className="container text-container">
          <h1>{t("custom_tournament_form_step_1.tournament_application")}</h1>
          <p className="subtext">
            {t("custom_tournament_form_step_1.subheading")}
          </p>
        </div>
      </div>

      <div className="formContainer">
        <div className="container">
          <h2 className="heading">
            {t("custom_tournament_form_step_1.tournament_information")}
          </h2>

          <div className="row">
            <div className="col-lg-3 col-12 col-form-label field-label"></div>
            <div className="col-lg-5 col-12 mt-2">
              <p className="broadcast_information_note mt-0 mb-4 pre-line-space">
                {t("custom_tournament_form_step_1.broadcast_information_note")}
              </p>
              <p className="regions-countries">
                {t("custom_tournament_form_step_1.mena_information_part_1")}
                <br />
                {countriesInRegion?.map(
                  (region) =>
                    !isOther(region.regionName) &&
                    `- ${region.regionName}: ${region.countries
                      ?.map((country) => country.nameEn)
                      .join(", ")}\n`
                )}
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FocusCountries
              register={register}
              handleSubmit={handleSubmit}
              control={control}
              errors={errors}
              reset={reset}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              isSubmitted={isSubmitted}
              countriesInRegion={countriesInRegion}
              isOther={isOther}
            />

            <div className="row">
              <p className="col-lg-3 col-12 col-form-label field-label">
                {t("custom_tournament_form_step_1.select_the_game")}
              </p>
              <div className="col-lg-9 col-12 mt-2">
                <div className="form-field-container">
                  {games?.map((game) => (
                    <div
                      className="form-check form-check-inline custom-radio-button"
                      key={`game_${game.id}`}
                    >
                      <Controller
                        name="game_name"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            checked={selectedGame === game.id.toString()}
                            value={game.id}
                            id={`game_${game.id}`}
                          />
                        )}
                      />

                      <label
                        className="form-check-label"
                        htmlFor={`game_${game.id}`}
                      >
                        {game.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <p className="col-lg-3 col-12 mt-2 col-form-label field-label">
                {t("form.tournament_english_name")}
              </p>
              <div className="col-lg-3 col-12">
                <Input
                  hiddenLabel
                  placeholder={t("form.tournament_english_name_placeholder")}
                  defaultValue=""
                  {...register("tournament_english_name", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(6),
                    maxLength: formValidations.maxLength(255),
                    pattern:
                      formValidations.validateLettersWithSpacesAndNumbers(),
                  })}
                  errors={errors.tournament_english_name?.message}
                  inputCols={12}
                />
              </div>

              <p className="col-lg-3 col-12 mt-2 col-form-label field-label">
                {t("form.tournament_arabic_name")}
              </p>
              <div className="col-lg-3 col-12">
                <Input
                  hiddenLabel
                  placeholder={t("form.tournament_arabic_name_placeholder")}
                  defaultValue=""
                  {...register("tournament_arabic_name", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(6),
                    maxLength: formValidations.maxLength(255),
                    pattern:
                      formValidations.validateOnlyArabicLettersWithSpacesAndNumbers(),
                  })}
                  errors={errors.tournament_arabic_name?.message}
                  inputCols={12}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <Input
                  label={t("form.organizer_website_url")}
                  labelClass="bold"
                  placeholder={t("form.organizer_website_url_placeholder")}
                  defaultValue=""
                  {...register("organizer_website_url", {
                    required: formValidations.required(),
                    pattern: formValidations.validateURL(),
                    maxLength: formValidations.maxLength(255),
                  })}
                  errors={errors.organizer_website_url?.message}
                />
              </div>
            </div>

            <div className="row mt-2 mt-lg-1">
              <div className="col-lg-3 col-12">
                <p className="col-form-label field-label mt-0">
                  {t("form.tournament_format")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("custom_tournament_form_step_1.tournament_format_note")}
                </p>
              </div>
              <div className="col-lg-9 col-12">
                <div className="form-field-container ps-0">
                  {custom_tournaments_enums?.format?.map((format) => (
                    <div
                      className="form-check form-check-inline custom-radio-button"
                      key={`format_${format.id}`}
                    >
                      <Checkbox
                        {...register("format", {
                          required: formValidations.required(),
                        })}
                        label={format.name}
                        value={format.id}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {errors?.format && (
                <p className="input-error col-12 offset-lg-3">
                  * {errors?.format?.message}
                </p>
              )}
            </div>

            <div className="row">
              <div className="col-lg-3 col-12 ">
                <p className="col-form-label field-label pb-0">
                  {t("form.tournament_type")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("form.tournament_type_sub")}
                </p>
              </div>
              <div className="col-lg-9 col-12 mt-2">
                <div className="form-field-container d-flex">
                  <div>
                    {tournament_type?.map(
                      (format) =>
                        !isOther(format.name) && (
                          <div
                            className="form-check custom-radio-button"
                            key={`format_${format.id}`}
                          >
                            <div>
                              <Controller
                                name="format_type"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    className="form-check-input"
                                    type="radio"
                                    checked={
                                      selectedFormatType ===
                                      format.id.toString()
                                    }
                                    value={format.id}
                                    id={`format_type_${format.id}`}
                                  />
                                )}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`format_type_${format.id}`}
                              >
                                {format.name}
                              </label>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                  <div className="ms-5">
                    {tournament_type?.map(
                      (format) =>
                        isOther(format.name) && (
                          <div
                            className="form-check custom-radio-button"
                            key={`tournament_type_${format.id}`}
                          >
                            <div>
                              <Controller
                                name="format_type"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    className="form-check-input"
                                    type="radio"
                                    checked={
                                      selectedFormatType ===
                                      format.id.toString()
                                    }
                                    value={format.id}
                                    id={`tournament_type_${format.id}`}
                                  />
                                )}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`tournament_type_${format.id}`}
                              >
                                {format.name}
                              </label>
                              {selectedFormatType === format.id.toString() && (
                                <Input
                                  containerClass="other-input-container"
                                  hiddenLabel
                                  placeholder={t("form.please_specify")}
                                  {...register("format_type_other", {
                                    required: formValidations.required(),
                                    maxLength: formValidations.maxLength(255),
                                    pattern:
                                      formValidations.validateLettersAndNumbersAndSpaces(),
                                  })}
                                  errors={errors.format_type_other?.message}
                                  inputCols={12}
                                />
                              )}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-12 mb-2">
                <p className="col-form-label field-label pb-0 mt-0">
                  {t("form.duration_label")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("form.duration_label_sub")}
                </p>
              </div>

              <div className="col-lg-9 col-12 mt-2">
                <div className="form-field-container">
                  {custom_tournaments_enums?.duration?.map((duration) => (
                    <div
                      className="form-check form-check-inline custom-radio-button"
                      key={`duration_${duration.id}`}
                    >
                      <Controller
                        name="duration"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            checked={
                              selectedDuration === duration.id.toString()
                            }
                            value={duration.id}
                            id={`duration_${duration.id}`}
                          />
                        )}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`duration_${duration.id}`}
                      >
                        {duration.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-3 col-12">
                <p className="col-form-label field-label mt-0 py-0">
                  {t("form.announcement_date")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("form.announcement_date_note")}
                </p>
              </div>

              <div className="col-lg-2 col-12">
                <DatePicker
                  control={control}
                  rules={{ required: formValidations.required() }}
                  minDate={addDays(new Date(), 1)}
                  name="announce_date"
                  hiddenLabel
                />
              </div>

              <div className="col-lg col-12">
                <p className="col-form-label field-label mt-0 py-0">
                  {t("form.start_date")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("form.start_date_note")}
                </p>
              </div>

              <div className="col-lg-2 col-12">
                <DatePicker
                  control={control}
                  rules={{
                    required: formValidations.required(),
                    validate: (value) =>
                      formValidations.validateStartDate(
                        announce_date,
                        value,
                        end_date
                      ),
                  }}
                  minDate={addDays(announce_date, 1)}
                  name="start_date"
                  hiddenLabel
                />
              </div>

              <div className="col-lg col-12">
                <p className="col-form-label field-label mt-0 py-0">
                  {t("form.end_date")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("form.end_date_note")}
                </p>
              </div>

              <div className="col-lg-2 col-12">
                <DatePicker
                  control={control}
                  rules={{ required: formValidations.required() }}
                  minDate={addDays(start_date, 1)}
                  maxDate={addDays(start_date, 56)}
                  name="end_date"
                  hiddenLabel
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-3 col-12 ">
                <p className="col-form-label field-label pb-0 mt-0">
                  {t("form.broadcast")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("form.broadcast_sub")}
                </p>
              </div>
              <div className="col-lg-9 col-12 mt-2 mt-lg-0">
                <div className="form-field-container">
                  {Object.keys(YES_NO)?.map((option) => (
                    <div
                      className="form-check form-check-inline custom-radio-button"
                      key={`broadcast_${option}`}
                    >
                      <Controller
                        name="broadcast"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            checked={
                              selectedBroadcast === YES_NO[option].toString()
                            }
                            id={`broadcast_${option}`}
                            value={YES_NO[option]}
                          />
                        )}
                      />

                      <label
                        className="form-check-label"
                        htmlFor={`broadcast_${option}`}
                      >
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {selectedBroadcast === YES_NO.Yes.toString() && (
              <div className="row mt-2 mt-lg-4 mt-xl-1">
                <div className="col-lg-3 col-12">
                  <p className="col-form-label field-label mt-0 py-0">
                    {t("form.broadcast_days")}
                  </p>
                  <p className="field-label field-sub-label mb-0">
                    {t("form.broadcast_days_sub")}
                  </p>
                </div>

                <div className="col-lg-9 col-12">
                  <DatePicker
                    control={control}
                    rules={{ required: formValidations.required() }}
                    placeholder={t("form.broadcast_days")}
                    multiple
                    minDate={addDays(start_date, 0)}
                    maxDate={addDays(end_date, 0)}
                    name="broadcast_days"
                    disabledDates={available_broadcast_days}
                    hiddenLabel
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-3 col-12">
                <p className="col-form-label field-label mt-2 py-0">
                  {t("form.tournament_size")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("form.tournament_size_sub")}
                </p>
              </div>

              <div className="col-lg-9 col-12 mt-2">
                <div className="form-field-container">
                  {custom_tournaments_enums?.tier?.map((tier) => (
                    <div
                      className="form-check form-check-inline custom-radio-button"
                      key={`tier_${tier.id}`}
                    >
                      <Controller
                        name="tier"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            checked={selectedTier === tier.id.toString()}
                            value={tier.id}
                            id={`tier_${tier.id}`}
                          />
                        )}
                      />
                      <label
                        className="form-check-label pre-line-space bold"
                        htmlFor={`tier_${tier.id}`}
                      >
                        {tier.name}
                        <span className="label-description">
                          {`\n${tier.description}`}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row">
              <p className="col-lg-3 col-12 col-form-label field-label">
                {t("form.prize_type")}
              </p>
              <div className="col-lg-9 col-12 mt-2">
                <div className="form-field-container">
                  {custom_tournaments_enums?.prize_type?.map((prize_type) => (
                    <div
                      className="form-check form-check-inline custom-radio-button"
                      key={`prize_type_${prize_type.id}`}
                    >
                      <Controller
                        name="prize_type"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            checked={
                              selectedPrizeType === prize_type.id.toString()
                            }
                            value={prize_type.id}
                            id={`prize_type_${prize_type.id}`}
                          />
                        )}
                      />
                      <label
                        className="form-check-label pre-line-space bold"
                        htmlFor={`prize_type_${prize_type.id}`}
                      >
                        {prize_type.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-2">
                <Input
                  label={`${t("form.prize_value")}`}
                  labelClass="bold"
                  placeholder={t("form.prize_value")}
                  {...register("prize_value", {
                    required: formValidations.required(),
                    min: formValidations.minValue(0),
                    max: formValidations.maxValue(Number.MAX_SAFE_INTEGER),
                  })}
                  type="number"
                  errors={errors.prize_value?.message}
                />
              </div>

              <p className="col-lg-9 col-12 offset-lg-3 field-note mt-1 mb-0">
                {t("custom_tournament_form_step_1.prize_value_note")}
              </p>
            </div>

            <div className="row">
              <div className="col-lg-3 col-12 mb-2">
                <p className="col-form-label field-label pb-0 mt-0">
                  {t("form.participants_fees")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("form.participants_fees_sub")}
                </p>
              </div>

              <div className="col-lg-9 col-12 mt-2">
                <div className="form-field-container">
                  {Object.keys(YES_NO)?.map((option) => (
                    <div
                      className="form-check form-check-inline custom-radio-button"
                      key={`chargeable_${option}`}
                    >
                      <Controller
                        name="chargeable"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            checked={
                              selectedChargeable === YES_NO[option].toString()
                            }
                            id={`chargeable_${option}`}
                            value={YES_NO[option]}
                          />
                        )}
                      />

                      <label
                        className="form-check-label"
                        htmlFor={`chargeable_${option}`}
                      >
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-12">
                <p className="col-form-label field-label mt-0 py-0">
                  {t("form.commercialisation")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("form.commercialisation_sub")}
                </p>
              </div>
              <div className="col-lg-9 col-12 mt-2 mt-lg-0">
                <div className="form-field-container">
                  {Object.keys(YES_NO)?.map((option) => (
                    <div
                      className="form-check form-check-inline custom-radio-button"
                      key={`commercial_${option}`}
                    >
                      <Controller
                        name="commercial"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-check-input"
                            type="radio"
                            checked={
                              selectedCommercial === YES_NO[option].toString()
                            }
                            id={`commercial_${option}`}
                            value={YES_NO[option]}
                          />
                        )}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`commercial_${option}`}
                      >
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <Input
                  label={t("form.visible_sponsors")}
                  labelClass="bold"
                  placeholder={t("form.your_answer")}
                  {...register("sponsors", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(3),
                    maxLength: formValidations.maxLength(255),
                  })}
                  errors={errors.sponsors?.message}
                />
              </div>
              <p className="col-lg-9 col-12 offset-lg-3 field-note mt-1 mb-0">
                {t("custom_tournament_form_step_1.sponsors_note")}
              </p>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3 col-12">
                <p className="col-form-label field-label mt-2 py-0">
                  {t("form.organisation_plan")}
                </p>
                <p className="field-label field-sub-label mb-0">
                  {t("form.organisation_plan_sub")}
                </p>
              </div>

              <div className="col-lg-9 col-12">
                <Textarea
                  placeholder={t("form.your_answer")}
                  {...register("additional_info", {
                    required: formValidations.required(),
                    minLength: formValidations.minLength(3),
                    maxLength: formValidations.maxLength(255),
                  })}
                  errors={errors.additional_info?.message}
                  hiddenLabel
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <FilesPicker
                  label={t("form.additional_files")}
                  labelClass="bold"
                  links={additional_files}
                  addFile={addFile}
                  removeFile={removeFile}
                  removeLink={removeFileLink}
                  files={selected_additional_files}
                  {...register("selected_additional_files", {
                    validate: () =>
                      additional_files?.length > 0 ||
                      selected_additional_files?.length > 0 ||
                      "Required",
                  })}
                  errors={errors.selected_additional_files?.message}
                />
                <p className="col-lg-9 col-12 offset-lg-3 field-note mt-1 mb-0">
                  {t("custom_tournament_form_step_1.additional_files_note")}
                </p>
              </div>
            </div>

            <div className="row">
              <h2 className="heading mt-5 col-12">
                {t("custom_tournament_form_step_1.riot_games_support")}
              </h2>

              <p className="col-lg-9 col-12 offset-lg-3 broadcast_information_note mt-0 mb-4 pre-line-space">
                {t("custom_tournament_form_step_1.riot_games_support_note")}
                <Link
                  to={`/our-support`}
                  target="_blank"
                  rel="noreferrer"
                  className="our-support-page-word"
                >
                  {t("custom_tournament_form_step_1.our_support_page")}
                </Link>
              </p>

              <div className="row mt-2 mt-lg-1">
                <p className="col-lg-3 col-12 col-form-label field-label"></p>
                <div className="col-lg-9 col-12">
                  <div className="form-field-container ps-0 row">
                    {tournament_support_options?.map((option) => (
                      <div
                        className="form-check custom-radio-button col-lg-6 col-12"
                        key={`support_options_${option.title}`}
                      >
                        <Checkbox
                          {...register("support_options", {
                            validate: (value) =>
                              formValidations.supportOptionsValidation(
                                value,
                                3
                              ),
                          })}
                          label={option.title}
                          value={option.title}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {errors?.support_options && (
                  <p className="input-error col-12 offset-lg-3">
                    * {errors?.support_options?.message}
                  </p>
                )}
              </div>
            </div>

            {selectedBroadcast === YES_NO.Yes.toString() && (
              <>
                <div className="row">
                  <h2 className="heading mt-5 col-12">
                    {t("custom_tournament_form_step_1.broadcast_information")}
                  </h2>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-12">
                    <p className="col-form-label field-label mt-2 py-0">
                      {t("form.broadcast_platforms")}
                    </p>
                    <p className="field-label field-sub-label mb-0">
                      {t("form.broadcast_platforms_sub")}
                    </p>
                  </div>

                  <div className="col-lg-9 col-12 mt-2">
                    <div className="form-field-container d-flex">
                      <div>
                        {custom_tournaments_enums?.broadcast_platforms?.map(
                          (platform) =>
                            !isOther(platform.name) && (
                              <div
                                className="form-check custom-radio-button ps-0"
                                key={`broadcast_platforms_${platform.id}`}
                              >
                                <div>
                                  <Checkbox
                                    {...register("broadcast_platforms", {
                                      required: formValidations.required(),
                                      validate: (value) =>
                                        value.length ||
                                        formValidations.required(),
                                    })}
                                    label={platform.name}
                                    value={platform.id}
                                  />
                                </div>
                              </div>
                            )
                        )}
                      </div>

                      <div className="ms-5">
                        {custom_tournaments_enums?.broadcast_platforms?.map(
                          (platform) =>
                            isOther(platform.name) && (
                              <div
                                className="form-check custom-radio-button ps-0"
                                key={`broadcast_platforms_${platform.id}`}
                              >
                                <div>
                                  <Checkbox
                                    {...register("broadcast_platforms", {
                                      required: formValidations.required(),
                                      validate: (value) =>
                                        value.length ||
                                        formValidations.required(),
                                    })}
                                    label={platform.name}
                                    value={platform.id}
                                  />
                                  {selectedBroadcastPlatforms.includes(
                                    platform.id.toString()
                                  ) && (
                                    <Input
                                      containerClass="other-input-container"
                                      hiddenLabel
                                      placeholder={t("form.please_specify")}
                                      {...register(
                                        "broadcast_platforms_other",
                                        {
                                          required: formValidations.required(),
                                          maxLength:
                                            formValidations.maxLength(255),
                                          pattern:
                                            formValidations.validateLettersAndNumbersAndSpaces(),
                                        }
                                      )}
                                      errors={
                                        errors.broadcast_platforms_other
                                          ?.message
                                      }
                                      inputCols={12}
                                    />
                                  )}
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  {errors?.broadcast_platforms && (
                    <p className="input-error col-12 offset-lg-3">
                      * {errors?.broadcast_platforms?.message}
                    </p>
                  )}
                </div>

                <div className="row mt-2">
                  <div className="col-12">
                    <Input
                      label={t("form.broadcast_platforms_url")}
                      labelClass="bold"
                      placeholder={t("form.broadcast_platforms_url")}
                      {...register("broadcast_platforms_url", {
                        required: formValidations.required(),
                        minLength: formValidations.minLength(3),
                        maxLength: formValidations.maxLength(255),
                      })}
                      errors={errors.broadcast_platforms_url?.message}
                    />
                  </div>
                  <p className="col-lg-9 col-12 offset-lg-3 field-note mt-1 mb-0">
                    {t(
                      "custom_tournament_form_step_1.broadcast_platforms_url_note"
                    )}
                  </p>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-3 col-12">
                    <p className="col-form-label field-label mt-2 py-0">
                      {t("form.broadcast_languages")}
                    </p>
                    <p className="field-label field-sub-label mb-0">
                      {t("form.broadcast_languages_sub")}
                    </p>
                  </div>

                  <div className="col-lg-9 col-12 mt-2">
                    <div className="form-field-container d-flex">
                      <div>
                        {custom_tournaments_enums?.broadcast_languages?.map(
                          (language) =>
                            !isOther(language.name) && (
                              <div
                                className="form-check custom-radio-button ps-0"
                                key={`broadcast_languages_${language.id}`}
                              >
                                <div>
                                  <Checkbox
                                    {...register("broadcast_languages", {
                                      required: formValidations.required(),
                                    })}
                                    label={language.name}
                                    value={language.id}
                                  />
                                </div>
                              </div>
                            )
                        )}
                      </div>

                      <div className="ms-5">
                        {custom_tournaments_enums?.broadcast_languages?.map(
                          (language) =>
                            isOther(language.name) && (
                              <div
                                className="form-check custom-radio-button ps-0"
                                key={`broadcast_languages_${language.id}`}
                              >
                                <div>
                                  <Checkbox
                                    {...register("broadcast_languages", {
                                      required: formValidations.required(),
                                    })}
                                    label={language.name}
                                    value={language.id}
                                  />
                                  {selectedBroadcastLanguages.includes(
                                    language.id.toString()
                                  ) && (
                                    <Input
                                      containerClass="other-input-container"
                                      hiddenLabel
                                      placeholder={t("form.please_specify")}
                                      {...register(
                                        "broadcast_languages_other",
                                        {
                                          required: formValidations.required(),
                                          maxLength:
                                            formValidations.maxLength(255),
                                          pattern:
                                            formValidations.validateLettersAndNumbersAndSpaces(),
                                        }
                                      )}
                                      errors={
                                        errors.broadcast_languages_other
                                          ?.message
                                      }
                                      inputCols={12}
                                    />
                                  )}
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  {errors?.broadcast_languages && (
                    <p className="input-error col-12 offset-lg-3">
                      * {errors?.broadcast_languages?.message}
                    </p>
                  )}
                </div>

                <div className="row mt-2">
                  <div className="col-lg-3 col-12 ">
                    <p className="col-form-label field-label pb-0 mt-0">
                      {t("form.tv_broadcast")}
                    </p>
                    <p className="field-label field-sub-label mb-0">
                      {t("form.tv_broadcast_sub")}
                    </p>
                  </div>

                  <div className="col-lg-9 col-12 mt-2 mt-lg-0">
                    <div className="form-field-container">
                      {Object.keys(YES_NO)?.map((option) => (
                        <div
                          className="form-check form-check-inline custom-radio-button"
                          key={`tv_broadcast_${option}`}
                        >
                          <Controller
                            name="tv_broadcast"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                className="form-check-input"
                                type="radio"
                                checked={
                                  selectedTvBroadcast ===
                                  YES_NO[option].toString()
                                }
                                id={`tv_broadcast_${option}`}
                                value={YES_NO[option]}
                              />
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`tv_broadcast_${option}`}
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="row">
              <div className="col-lg-9 col-12 mt-5 offset-lg-3">
                <Controller
                  name="registerAcceptanceText"
                  control={control}
                  rules={{ required: formValidations.required() }}
                  render={({ field }) => (
                    <AcceptTermsAndConditionsCheckbox
                      {...field}
                      errors={errors.registerAcceptanceText?.message}
                    />
                  )}
                />
              </div>

              <div className="d-flex align-items-center gap col-lg-9 col-12 mt-4 offset-lg-3">
                <button
                  type="submit"
                  className="btn btn-primary custom-button full-width me-3"
                  disabled={isLoading}
                  onClick={() => {
                    window.gtag("event", "submit-step-1", {
                      event_category: "Button",
                      event_label: "submit-step-1",
                    });
                  }}
                >
                  {id ? t("form.update") : t("form.register")}
                </button>
                {isLoading ? (
                  <>
                    <div class="spinner-border text-danger" role="status" />
                    <p className="field-label field-sub-label mb-0 ms-3">
                      {t("form.please_wait_a_moment")}
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateCustomTournamentStep1;
