import React from "react";

import ReactPaginate from "react-paginate";

import "../styles/Pagination.scss";

function UserTournaments({ handlePageClick, pageCount }) {
  return (
    <nav
      aria-label="Page navigation comments"
      className="custom-pagination mt-4"
    >
      <ReactPaginate
        previousLabel="previous"
        nextLabel="next"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
      />
    </nav>
  );
}

export default UserTournaments;
