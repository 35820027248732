import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

import "../styles/checkbox.scss";

const Input = forwardRef(
  ({ name, defaultValue = "", errors, onChange, onBlur }, ref) => (
    <div
      className={
        "terms-checkbox checkbox-form-group form-check row align-items-center"
      }
    >
      <div className="col-12 check-extra">
        <div className="d-flex align-items-center">
          <input
            ref={ref}
            id={name}
            name={name}
            type="checkbox"
            className="form-check-input"
            defaultValue={defaultValue}
            aria-invalid={errors}
            onChange={onChange}
            onBlur={onBlur}
          />
          <label htmlFor={name} className="form-check-label col-form-label">
            By ticking this box, I acknowledge that I have read and agree to the{" "}
            <span>
              <Link to={`/terms-of-use`} target="_blank">
                terms
              </Link>
            </span>{" "}
            and the{" "}
            <span>
              <Link to={`/privacy-policy`} target="_blank">
                privacy policy
              </Link>
            </span>{" "}
            of TOD.
          </label>
        </div>
        {errors && (
          <p className="input-error" role="alert">
            * {errors}
          </p>
        )}
      </div>
    </div>
  )
);

export default Input;
