import { GAMES } from "../../utils/app.constants";
import { CommonActions } from "../actions/types";

const initialState = {
  loading: false,
  games: [],
  selectedGame: localStorage.getItem("selectedGame") || GAMES.VALORANT,
  onGoingCount: 0,
  isError: false,
  errorMessage: "",
  isSuccess: false,
  successMessage: "",
  bannerImages: {
    wr: "",
    lol: "",
    valorant: "",
  },
  page_path: "/",
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case CommonActions.SAVE_GAMES: {
      return {
        ...state,
        games: action.payload,
        loading: false,
      };
    }
    case CommonActions.SAVE_SELECTED_GAME: {
      return {
        ...state,
        selectedGame: action.payload,
      };
    }
    case CommonActions.SAVE_ON_GOING_TOURNAMENTS_NUMBER: {
      return {
        ...state,
        onGoingCount: action.payload,
      };
    }
    case CommonActions.SAVE_ERROR_MESSAGE: {
      return {
        ...state,
        isError: action.payload.isError,
        errorMessage: action.payload.errorMessage,
      };
    }
    case CommonActions.SAVE_SUCCESS_MESSAGE: {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
        successMessage: action.payload.successMessage,
      };
    }
    case CommonActions.SAVE_BANNER_IMAGES: {
      return {
        ...state,
        bannerImages: action.payload,
      };
    }
    case CommonActions.SAVE_PAGE_LOCATION: {
      return {
        ...state,
        page_path: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default commonReducer;
